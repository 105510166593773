import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import config from "../Common_Files/Config";
import Swal from "sweetalert2";

const ExportSupplierWiseProducts = ({urlLink, pdtStatus, supplier_id, setIsDownloadClicked }) => {

  const accessToken = localStorage.getItem('accessToken');
  const exportActiveProduct = async () => {
    const formData = new FormData();
    formData.set('supplier_id', supplier_id);
    const apiActivePdtUrl = `${config.api_url}/${urlLink}`; 
    try {
      const response = await fetch(apiActivePdtUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch Products');
      }
      const data = await response.json();
      let exportData = [];

      if( data.length > 0 )
      {
      if (pdtStatus === 'Supplier') {
        exportData = data.map(product => ({
          "Code": product.code || '',
          "Product Description": product.product_desc || '',
          "Pack": product.pack || '',
          "Size": product.size || '',
          "Price": product.price || '',
          "Cost": product.cost || '',
          "Gross Weight": product.gross_weight || '',
          "Net Weight": product.net_weight || '',
          "Category": product.category || '',
          "Unit Barcode": product.unit_barcode || '',
          "Case Barcode": product.case_barcode || '',
          "Layer Quantity": product.layer_qty || '',
          "Quantity": product.quantity || '',
          "VAT Name": product.vat_name || '',
          "Status": product.status === 1 ? "Active" : "Inactive",  // Conditional check for status
        }));        
      }
      
      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Product List');
      const now = new Date();

      // Get UK time (Europe/London timezone)
      const options = {
        timeZone: 'Europe/London',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // Use 24-hour format
      };
      
      const ukTime = new Intl.DateTimeFormat('en-GB', options).format(now);
      const [date, time] = ukTime.split(', ');
      const formattedDate = date.split('/').reverse().join('-');  
      const formattedTime = time.replace(/:/g, '-'); 
      const filename = `${pdtStatus}-Wise-Product-list-${formattedDate}-${formattedTime}.xlsx`; 
      XLSX.writeFile(wb, filename);
      setIsDownloadClicked(false);
    }
    else{
      Swal.fire({
        title: "Error!",
        text: "No Data Found",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      setIsDownloadClicked(false);
    }

    } catch (error) {
      console.error('Error exporting Supplier Product:', error);
    }
  };



  useEffect(()=>{
    exportActiveProduct();
  },[]);
  return (
    <></>
  );
};

export default ExportSupplierWiseProducts;
