import React, { useState, useEffect } from 'react';

function ResizableTable() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Function to toggle the popup visibility
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  // Setup the keyboard event listener using useEffect
  useEffect(() => {
    // Define the keyboard shortcut - for example, Ctrl+T
    const handleKeyPress = (event) => {
      // Log the key press for debugging
      console.log('Key pressed:', event.key);  // Log key presses to help with debugging

      // Check if Ctrl + T was pressed
      if (event.ctrlKey && event.key === 't') {
        event.preventDefault();  // Prevent the default browser action for Ctrl+T (new tab)
        togglePopup();
      }
    };

    // Add the keydown event listener to detect the shortcut
    window.addEventListener('keydown', handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []); // Empty dependency array ensures this runs once when the component mounts

  return (
    <div>
      <h1>React Keyboard Shortcut for Popup</h1>
      <p>Press <strong>Ctrl + T</strong> to toggle the popup.</p>

      {/* Popup */}
      {isPopupOpen && (
        <div style={popupStyle}>
          <div>
            <h2>Popup is open!</h2>
            <button onClick={togglePopup}>Close Popup</button>
          </div>
        </div>
      )}
    </div>
  );
}

const popupStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  padding: '20px',
  color: 'white',
  borderRadius: '8px',
  zIndex: 1000,
};

export default ResizableTable;
