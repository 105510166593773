import jsPDF from 'jspdf';
import 'jspdf-autotable';
import config from '../Common_Files/Config';
import axios from 'axios';

const generateUniqueFilename = (baseName, extension) => {
  const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
  return `${baseName}_${timestamp}.${extension}`;
};

const generatePurchaseInvoicePDF = async (id) => {
  const accessToken = localStorage.getItem('accessToken');

  const formData = new FormData();
  formData.set('id', id);
  const apiUrl = `${config.api_url}/ws-get-purchase-history`;

  try {
    const response = await axios.post(apiUrl, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      },
    });

    const data = response.data; // No need for await here
    // delivery_type
    let totalVatAmount = 0;
    let totalAmount = 0;
    let grossWeight = 0;
    let cbm = 0;
    let netWeight = 0;

    const doc = new jsPDF('landscape'); // Set to landscape orientation
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Margins
    const margin = 10;
    let y = margin;

    // Header sections (unchanged)
    const headerSections1 = [
      [
        { text: 'Star Pacific', size: 13, bold: true },
        { text: 'Abenglen Industrial Estate,', size: 8, bold: false },
        { text: 'Betam Road, Hayes', size: 8, bold: false },
        { text: 'Middlesex, London UB3 1AT', size: 8, bold: false },
        { text: 'United Kingdom', size: 8, bold: false },
      ],
      [
        { text: 'Co. Reg. No.: 7298396', size: 8, bold: true },
        { text: 'VAT Reg. No.: GB994533184', size: 8, bold: false },
        { text: 'Tel: 44-208 329 3800', size: 8, bold: false },
      ],
      [{ text: '', size: 8, bold: false }],
      [{ text: '', size: 8, bold: false }],
    ];

    const headerSections2 = [
      [
        { text: `Supplier Address: ${data.headerSection2.address_line1}`, size: 8, bold: false },
        { text: `${data.headerSection2.address_line2}`, size: 8, bold: false },
        { text: '', size: 8, bold: false },
        { text: `${data.headerSection2.whcity}`, size: 8, bold: false },
        { text: `${data.headerSection2.whpostcode}`, size: 8, bold: false },
        { text: `${data.headerSection2.whcountry}`, size: 8, bold: false },
      ],
      [
        { text: `VAT Reg. No :: ${data.headerSection2.vat_id}`, size: 8, bold: false },
        { text: `Rep: ${data.headerSection2.contact_person_name}`, size: 8, bold: false },
        { text: `Account No: ${data.headerSection2.bank_account_number}`, size: 8, bold: false },
      ],
      [
        { text: `PO No: ${data.headerSection2.reference_no}`, size: 8, bold: false },
        { text: `Date: ${data.headerSection2.arrival_date}`, size: 8, bold: false },
        { text: `Job Ref: ${data.headerSection2.job_ref}`, size: 8, bold: false },
      ],
      [
        { text: `Currency : USD`, size: 8, bold: false },
        { text: `Date of Arrival: ${data.headerSection2.arrival_date}`, size: 8, bold: false },
        { text: `SO#: `, size: 8, bold: false },
      ],
    ];

    const headerSections3 = [
      [
        { text: `Delivery Address: ${data.headerSection2.name}`, size: 8, bold: false },
        { text: `${data.headerSection2.address_line1}`, size: 8, bold: false },
        { text: '', size: 8, bold: false },
        { text: `${data.headerSection2.whcity}`, size: 8, bold: false },
        { text: `${data.headerSection2.whpostcode}`, size: 8, bold: false },
        { text: `${data.headerSection2.whcountry}`, size: 8, bold: false },
      ],
      [{ text: '', size: 8, bold: true }],
      [{ text: '', size: 8, bold: true }],
      [{ text: '', size: 8, bold: true }],
    ];


    const headerSectionsForCollection = [
      [
        { text: `Collection Address: `, size: 8, bold: false },
        { text: `${data.headerSection2.collection_address_line1}`, size: 8, bold: false },
        { text: `${data.headerSection2.collection_address_line2}`, size: 8, bold: false },
        { text: '', size: 8, bold: false },
        { text: `${data.headerSection2.collection_city}`, size: 8, bold: false },
        { text: `${data.headerSection2.collection_post_code}`, size: 8, bold: false },
        { text: `${data.headerSection2.collection_country}`, size: 8, bold: false },
      ],
      [{ text: '', size: 8, bold: true }],
      [{ text: '', size: 8, bold: true }],
      [{ text: '', size: 8, bold: true }],
    ];


    const headerSectionsForCustomer = [
      [
        { text: `Customer Address: ${data.headerSection2.name ?? ''}`, size: 8, bold: false },
        { text: `${data.headerSection2.customer_delivery_address_line1 ?? ''}`, size: 8, bold: false },
        { text: `${data.headerSection2.customer_delivery_address_line2 ?? ''}`, size: 8, bold: false },
        { text: '', size: 8, bold: false },
        { text: `${data.headerSection2.customer_delivery_city ?? ''}`, size: 8, bold: false },
        { text: `${data.headerSection2.customer_delivery_state ?? ''}`, size: 8, bold: false },
        { text: `${data.headerSection2.customer_delivery_country ?? ''}`, size: 8, bold: false },
        { text: `${data.headerSection2.customer_delivery_postcode ?? ''}`, size: 8, bold: false },
      ],
      [{ text: '', size: 8, bold: true }],
      [{ text: '', size: 8, bold: true }],
      [{ text: '', size: 8, bold: true }],
    ];
   

    const sectionWidth = (pageWidth - margin * 2) / 4; // Adjust for margins

    // Render header sections
    const renderSections = (sections) => {
      sections.forEach((section, index) => {
        const colStartX = margin + (index % 4) * sectionWidth;

        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, colStartX, y + i * 4); // Reduced space between lines
        });

        if (index % 4 === 3) {
          y += 12; // Reduced spacing after every 4 sections
        }
      });
      y += 15; // Add a little space after the last header section
    };

    renderSections(headerSections1);
    renderSections(headerSections2);
    console.log(data.headerSection2.is_wharehouse_or_customer);
    console.log(data.headerSection2.delivery_type);

    if(data.headerSection2.is_wharehouse_or_customer === 'warehouse' && data.headerSection2.delivery_type === 'delivery')
    {
      console.log( '1111111');
      renderSections(headerSections3);
    }
    
    if(data.headerSection2.is_wharehouse_or_customer === 'customer' && data.headerSection2.delivery_type === 'delivery') 
    {
      console.log( '222222');
      renderSections(headerSectionsForCustomer);
    }  
    
    if(data.headerSection2.delivery_type === 'collection')
    {
      console.log( '333333');
      renderSections(headerSectionsForCollection);
    }

    // Render table
    const columns = [
      { title: 'Line No', dataKey: 'line_no', size: 8, bold: false },
      { title: 'SKU Code', dataKey: 'sku_code', size: 8, bold: false },
      { title: 'Product Description', dataKey: 'pdt_desc', size: 8, bold: false },
      { title: 'Pack', dataKey: 'pack', size: 8, bold: false },
      { title: 'Size', dataKey: 'size', size: 8, bold: false },
      { title: 'Unit Barcode', dataKey: 'unit_brcode', size: 8, bold: false },
      { title: 'Case Qty', dataKey: 'case_qty', size: 8, bold: false },
      { title: 'Unit Price', dataKey: 'unit_price', size: 8, bold: false },
      { title: 'Amount', dataKey: 'amount', size: 8, bold: false },
      { title: 'Vat Rate (%)', dataKey: 'vat_rate', size: 8, bold: false },
      { title: 'Vat Amount', dataKey: 'vat_amount', size: 8, bold: false },
      { title: 'Total Amount', dataKey: 'total', size: 8, bold: false },
    ];

    const tableBody = data.products.map((item, index) => {
      const netPrice = item.quantity * item.cost; // Total for the row
      const vatAmount = netPrice * (item.rate / 100); // VAT amount
      const totalPrice = netPrice + vatAmount;

      // Accumulate totals
      totalVatAmount += vatAmount;
      totalAmount += totalPrice;
      grossWeight += parseFloat(item.gross_weight) || 0;
      cbm += parseFloat(item.cbm) || 0; 
      netWeight += parseFloat(item.net_weight) || 0;

      return [
        index + 1, 
        item.code, 
        item.product_desc,
        item.pack,
        item.size,
        item.unit_barcode, // Change this if the field name in the API is different
        item.quantity,
        item.cost, 
        netPrice, // Total for the row
        item.rate,
        vatAmount, // VAT amount
        totalPrice,
      ];
    });

    // Add an empty row with totals
    tableBody.push([
      '', '', '', '', '', '', '', '', '', 
      `Total:`, 
      totalVatAmount.toFixed(2), 
      totalAmount.toFixed(2),
    ]);

    // Render the table
    doc.autoTable({
      startY: y,
      head: [columns.map(col => col.title)],
      body: tableBody,
      theme: 'grid',
      headStyles: {
        fillColor: '#f8f9fa',
        textColor: '#404040',
        size: '13px',
      },
      margin: { top: 10 },
    });

    y = doc.autoTable.previous.finalY + 12; // Y position after table

    const addFooterSection = (footerSection) => {
      footerSection.forEach((section, index) => {
        const colStartX = margin + (index % 4) * sectionWidth;
        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, colStartX, y + i * 5);
        });

        if (index % 4 === 3) {
          y += 20; // Move down after every 4 sections
        }
      });
      y += 20; // Add space after each footer section
    };

    const addFooterWithSpaceCheck = (footerSection) => {
      const remainingSpace = pageHeight - y - margin; // Remaining space on the page
      const requiredSpace = footerSection.length * 5 + 20; // Space needed

      if (remainingSpace < requiredSpace) {
        doc.addPage(); // Add a new page if not enough space
        y = margin; // Reset Y position for new page
      }

      addFooterSection(footerSection);
    };

    // Define your footer sections here
    const footerSections1 = [
      [
        { text: `Notes: Delivered on 15/08; N5267 `, size: 8, bold: false },
        { text: `Ref: VBG 5KG; Transportify`, size: 8, bold: false },
      ],
      [{ text: '', size: 8, bold: false }],
      [
        { text: `Gross Weight: ${grossWeight} KGS `, size: 8, bold: false },
        { text: `CBM: ${cbm} M3`, size: 8, bold: false },
        { text: `Net Weight: ${netWeight} KGS`, size: 8, bold: false },
      ]
    ];

    const footerSections3 = [
      [
        { text: 'Subject to Star Pacific terms and conditions of sale, a copy of which is available on request', size: 8, bold: false },
        { text: 'Star Pacific (No. 7298396)', size: 8, bold: false },
        { text: 'Reservation of Title. The property in and the title to all goods sold by Star Pacific shall not pass to the buyer until all monies due and owing have been paid in full', size: 8, bold: false },
      ]
    ];

    // Center footerSections3
    const centerFooter = (footerSection) => {
      footerSection.forEach((section, index) => {
        const centerX = (pageWidth - margin * 2) / 2; // Center position

        section.forEach((item, i) => {
          doc.setFontSize(item.size);
          doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
          doc.text(item.text, centerX, y + i * 5, { align: 'center' }); // Center align text
        });

        y += 15; // Add space after each footer section
      });
    };

    // Add footer sections with space checks
    addFooterWithSpaceCheck(footerSections1);
    centerFooter(footerSections3); // Center the third footer

    const uniqueFilename = generateUniqueFilename('DeliveryNote', 'pdf');

    // Save the document with the unique filename
    doc.save(uniqueFilename);

  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};

export default generatePurchaseInvoicePDF;
