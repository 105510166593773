import React, { useState, useEffect, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import generatePDF from './Proforma';
import generateDeliveryNotePDF from './DeliveryNote';
import axios from 'axios';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import ReactTooltip from 'react-tooltip';
import { DateRangePicker } from 'rsuite';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Select from 'react-select';
import Swal from 'sweetalert2';

import ViewCustomer from "../Master/ViewCustomer";
import { deleteOrder, createInvoiceClick, isSalesDeleteClick } from '../../services/CurrentSalesOrderService';
import { fetchCustomerData } from '../../services/NewSalesService';



const CurrentSalesOrder = () => {
  const [reportrange, setReportrange] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [customerData, setCustomerData] = useState([]);
  const [orderId, setOrderId] = useState(0);
  const [newSales, setNewSales] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [notes, setNotes] = useState(200);
  const [grossTotal, setGrossTotal] = useState(0);
  const [options, setOptions] = useState([]);
  const [createInvoiceData, setCreateInvoiceData] = useState([]);
  const [selectedBatchId, setSelectedBatchId] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [productBatch, setProductBatch] = useState([]);
  const elementRef = useRef(null);
  const accessToken = localStorage.getItem('accessToken');
  const createInvoiceModalRef = useRef(null);
  const isSalesDeleteModalRef = useRef(null);
  const changeCustomerModalRef = useRef(null);
  const [salesInfoData, setSalesInfoData] = useState([]);
  const [groupedBatches, setGroupedBatches] = useState({}); // Initialize as empty object
  const [selectedBatchIds, setSelectedBatchIds] = useState({});
  const [searchSupplier, setSearchSupplier] = useState('');
  //const apiUrl = `${config.api_url}/get-sales-list`;


  const apiUrl = `${config.api_url}/get-sales-listNew`;
  const handleGeneratePDF = (e, id) => {
    generatePDF(id);
  }

  const handlegenerateDeliveryNotePDF = (e, id) => {
    generateDeliveryNotePDF(id);
  }

  const handleBatchChange = (event, batchCode) => {
    const selectedValue = event.target.value;

    setSelectedBatchId(prevSelectedBatchIds => ({
      ...prevSelectedBatchIds,
      [batchCode]: selectedValue // Set the selected value for the specific batch code
    }));
  };

  const submitDeleteSO = async (id, notes) => {

    const formData = new FormData();
    formData.append('sales_id', id);
    formData.append('notes', notes);
    const apiUrl = `${config.api_url}/ws-update-sale-order-delete`;
    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
      });

      const data = await response.data;

      if (data.status = "success") {
        Swal.fire({
          title: "Success!",
          text: "Order Deleted Successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        })
        fetchData();
      }
    }
    catch (error) {
      console.error("Failed to delete order:", error);
    }

  }

  const submitCreateInvoice = async (id, notes, newSales, setNewSales) => {
    let status = 'Delivered';
    const formData = new FormData();
    formData.append('status', status);
    formData.append('order_id', id);
    formData.append('notes', notes);
    const apiUrl = `${config.api_url}/ws-update-sale-status`;
    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
      });

      if (response.data.status == 'success') {
        var updatedSales = newSales.map(item =>
          item.ids == id ? { ...item, status: 'Delivered' } : item
        );
      }

      // window.location.reload();
      setNewSales(updatedSales);


      if (createInvoiceModalRef.current) {
        const bsModal = window.bootstrap.Modal.getInstance(createInvoiceModalRef.current);
        if (bsModal) bsModal.hide();
      }
    }
    catch (error) {
      console.error("Failed to update status:", error);
    }

  }

  const convertToSO = async (id, notes, newSales, setNewSales) => {
    let status = 'SO';
    const formData = new FormData();
    formData.append('status', status);
    formData.append('order_id', id);
    formData.append('notes', notes);
    const apiUrl = `${config.api_url}/ws-update-sale-status`;
    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
      });

      if (response.data.status == 'success') {
        var updatedSales = newSales.map(item =>
          item.ids == id ? { ...item, status: 'SO' } : item
        );
      }

      // window.location.reload();
      setNewSales(updatedSales);

      if (createInvoiceModalRef.current) {
        const bsModal = window.bootstrap.Modal.getInstance(createInvoiceModalRef.current);
        if (bsModal) bsModal.hide();
      }
    }
    catch (error) {
      console.error("Failed to update status:", error);
    }

  }

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('searchValue', searchValue);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);

    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      let listData = response.data.data;

      let total = listData.reduce((acc, item) => {
        return acc + parseFloat(item.order_amount);
      }, 0);

      setGrossTotal(total);
      setNewSales(response.data.data);
    } catch (error) {
      console.error('Error fetching new sales data:', error);
    }
  };

  const handleBatchAssignementSubmit = async (e) => {
    e.preventDefault();
    if (selectedBatchId) {
      const values = Object.values(selectedBatchId);
      const formData = new FormData();
      formData.append('batchId', values);
      formData.append('orderId', orderId);
      try {
        const response = await fetch(`${config.api_url}/ws-batch-allocation`, {
          method: 'POST',
          body: formData,
        });

        const data = await response.json();

        // console.log(data);

        if (data.success) {
          Swal.fire({
            title: "Success!",
            text: "Allocation Done Successfully.",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          })
          fetchData();
        }

      } catch (error) {
        console.error('Error:', error);
      }
    } else {

      Swal.fire({
        title: "Failed!",
        text: "Please select a batch before submitting.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };
  

  useEffect(() => {
    if (!accessToken) {
      window.location.href = '/login';
      return;
    }
    fetchCustomerData(setCustomerData);
    fetchData();
  }, [fromDate, toDate, searchValue, setNewSales]);

  useEffect(() => {
    const table = $('#neworderTable').DataTable({
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: true,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },
      data: newSales,
      columns: [

        { data: 'order_date' },
        { data: 'delivery_date' },
        {
          data: 'reference_no',
          render: (data, type, row) => {
            return `<a href="/NewSalesOrderEdit/${row.reference_no}">${row.reference_no}</a>`;
          },
        },
        {
          data: 'company_name',
          render: (data) => (`<p ref=${elementRef} data-tip={showTooltip ? data : ''} className="cut-text" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>${data}</p>`
          ),
        },
        { data: 'order_amount' },
        { data: 'previous_due' },
        { data: 'previous_due' },
        { data: 'previous_due' },
        { data: 'status' },

        {
          data: null,
          render: (data, type, row) => (
            `<div className="filter verti-popdot">
          <a className="icon" href="#" data-bs-toggle="dropdown" aria-expanded="false"> Action <i className="bi bi-three-dots-vertical"></i> </a>
          <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
          <li>
          <button onClick={handleGeneratePDF}>Generate PDF</button>
          <a className="applybtn" onClick={handleGeneratePDF}>Download PF</a>
          </li>
          <li><a className="applybtn update-customer" href="#" data-id=${row.ids} data-bs-toggle="modal" data-bs-target="#download-pf">Email PF</a></li>
          <li><a className="applybtn update-customer" href="#" data-id=${row.ids} data-bs-toggle="modal" data-bs-target="#email-pf">Convert to S.O</a></li>
          <li><a className="applybtn update-customer" href="#" data-id=${row.ids} data-bs-toggle="modal" data-bs-target="#convert-inv">Delete Order</a></li>
          <li><a className="deductionbtn reject-btn" href="#" data-id=${row.ids} data-bs-toggle="modal" data-bs-target="#createinvoive">Create Invoice</a></li>
          <li><a className="applybtn update-customer" href="#" data-id=${row.ids} data-bs-toggle="modal" data-bs-target="#download-inv">Download Delivery Note</a></li>
          <li>
            <a className="applybtn update-customer" href="#" data-id=${row.ids} data-bs-toggle="modal" data-bs-target="#create-po">Create a P.O</a>
          </li>
          </ul>
          </div>`
          ),
        },
        { data: 'Checkbox' },
      ],
    });

    return () => {
      table.destroy();
    };
  }, [newSales]);

  useEffect(() => {
    const handleMouseEnter = () => {
      if (elementRef.current && elementRef.current.offsetWidth < elementRef.current.scrollWidth) {
        setShowTooltip(true);
      } else {
        setShowTooltip(false);
      }
    };

    const element = elementRef.current;
    if (element) {
      element.addEventListener('mouseenter', handleMouseEnter);
      return () => {
        element.removeEventListener('mouseenter', handleMouseEnter);
      };
    }
  }, [elementRef]);

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setFromDate(format(start, 'yyyy-MM-dd'));
      setToDate(format(end, 'yyyy-MM-dd'));
      setReportrange(`${format(start, 'MM/dd/yyyy')} - ${format(end, 'MM/dd/yyyy')}`);
    }
  };

  const handleChangeCustomer = (id) => {
    setOrderId(id)
  }

  const handleChangeBatchInfo = async (id) => {
    try {
      setOrderId(id)
      const formData = new FormData();
      formData.set('sale_id', id);

      const response = await axios.post(
        `${config.api_url}/ws-batch-info-from-sales-id`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const data = response.data;

      setSelectedBatchId('');
      setGroupedBatches('');

      setProductBatch(data.batch);


    } catch (error) {
      console.error("Error fetching product batch data:", error);
    }
  };

  const ChangeCustomerSubmit = async () => {
    const form = document.getElementById('customerChangeForm');
    const formData = new FormData(form);
    formData.append('order_id', orderId);
    // let customerID = formData.get('customer_id');

    try {
      const response = await axios.post(`${config.api_url}/ws-change-customer`, formData, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        }
      });

      const result = response.data;
      if (response.data.success) {
        Swal.fire({
          title: "Success!",
          text: "Customer updated successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        })
        fetchData();
      }

      if (changeCustomerModalRef.current) {
        const bsModal = window.bootstrap.Modal.getInstance(changeCustomerModalRef.current);
        if (bsModal) bsModal.hide();
      }
    } catch (error) {
      console.error("Error updating customer:", error);
    }
  }

  // Group batches by product_id
  /*const groupedBatches = productBatch.reduce((acc, item) => {
    if (!acc[item.product_id]) {
      acc[item.product_id] = [];
    }
    acc[item.product_id].push(item);
    return acc;
  }, {});*/

  useEffect(() => {

    const groupedBatches = productBatch.reduce((acc, item) => {
      if (!acc[item.code]) {
        acc[item.code] = [];
      }
      acc[item.code].push(item);
      return acc;
    }, {});

    setGroupedBatches(groupedBatches);
  }, [productBatch]);

  return (
    <div>
      <div className="modal fade" id="createinvoive" tabIndex="-1" ref={createInvoiceModalRef}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Invoice</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="add-product-popup">
                {createInvoiceData.map((invoice, index) => (
                  <form key={index}>
                    <div className="row mb-3 padd-l-13px">
                      <label htmlFor="desc" className="col-sm-3 col-form-label">Order ID</label>
                      <div className="col-sm-9">{invoice.id}</div>
                    </div>
                    <div className="row mb-3 padd-l-13px">
                      <label htmlFor="desc" className="col-sm-3 col-form-label">Customer Name</label>
                      <div className="col-sm-9">{invoice.customer_name}</div>
                    </div>
                    <div className="purchaseftr">
                      <div className="row padd-l-13px">
                        <div className="col-6 col-lg-4 col-sm-4">Gross Value : {invoice.gross_value}</div>
                        <div className="col-6 col-lg-4 col-sm-4">Gross Profit : {invoice.gross_profit}</div>
                        <div className="col-6 col-lg-4 col-sm-4">Gross Profit % : {invoice.gross_profit_percent} %</div>
                      </div>
                    </div>
                    <div className="row mb-3 padd-l-13px">
                      <label htmlFor="desc" className="col-sm-3 col-form-label">Notes</label>
                      <div className="col-sm-9">
                        <textarea className="form-control" name="create_invoice_notes" id="create_invoice_notes" onChange={(e) => setNotes(e.target.value)}></textarea>
                      </div>
                    </div>
                    <hr />
                    <div className="form-group">
                      <a href="#" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => submitCreateInvoice(invoice.id, notes, newSales, setNewSales)} className="popup-innerbtn-common">Create Invoice</a>
                    </div>
                  </form>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="isSalesDeleteMdl" tabIndex="-1" ref={createInvoiceModalRef}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Are you sure to delete this S.O</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="add-product-popup">
                {salesInfoData.map((sales, index) => (
                  <form key={index}>

                    <div className="row mb-3 padd-l-13px">
                      <label htmlFor="desc" className="col-sm-3 col-form-label">Enter Resion</label>
                      <div className="col-sm-9">


                        <textarea
                          className="form-control"
                          name="deleteso_notes"
                          id="deleteso_notes"
                          onChange={(e) => setNotes(e.target.value)}
                          value={notes} // Make sure to control the value with state
                        >
                          {sales.note}
                        </textarea>
                      </div>
                    </div>
                    <hr />
                    <div className="form-group">
                      <a href="#" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => submitDeleteSO(sales.id, notes)} className="popup-innerbtn-common">Delete S.O</a>
                    </div>
                  </form>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="change_customer" tabIndex="-1" ref={changeCustomerModalRef}>
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Change Customer</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="add-product-popup">
                <form id="customerChangeForm">
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="desc" className="col-sm-4 col-form-label">Select Customer</label>
                    <div className="col-sm-6">
                      <Select
                        isClearable
                        name="customer_id"
                        options={customerData}
                        placeholder="Select Customer"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="form-group">
                    <a href="#" className="popup-innerbtn-common" onClick={ChangeCustomerSubmit}>Update Customer</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="partial-popup" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">All Batches</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {Object.entries(groupedBatches).map(([batchCode, batches]) => (
                <div key={batchCode} className="partialbox">
                  <h5 className="modal-title">{batches[0]?.product_desc} ({batchCode})</h5>
                  <table className="table table-bordered dataTable">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Stock Qty</th>
                        <th>Sold Qty</th>
                        <th>Bal Qty</th>
                        <th>Alloc</th>
                        <th>UnAlloc</th>
                        <th>PO No.</th>
                        <th>Net Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {batches.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <input
                              type="radio"
                              id={`batch_${item.id}`}
                              name={`select_batch_${item.product_id}`}
                              value={item.id}
                              onChange={(e) => handleBatchChange(e, batchCode)}
                            />
                            <label htmlFor={`batch_${item.id}`}>Select Batch</label>
                          </td>

                          <td>{item.qty}</td>
                          <td>{item.sale_qty}</td>
                          <td>{item.qty - item.sale_qty}</td>
                          <td>{item.allocation}</td>
                          <td>{item.qty - item.allocation}</td>
                          <td>
                            {item.reference_no}
                          </td>
                          <td>{item.cost_price * item.qty}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
              <a data-bs-dismiss="modal" aria-label="Close" className="popup-innerbtn-common" onClick={handleBatchAssignementSubmit} style={{ marginTop: '10px' }}>Submit</a>
            </div>
          </div>
        </div>
      </div>

      <Header />
         <ViewCustomer />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#"><i className="fa fa-home"></i></a> &gt;
              <a href="dashboard.html">Dashboard</a> &gt;
              <span className="last-crumb">Current Sales Order</span>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="dashboard">
                <div className="dashform">
                  <div className="topsearch-area">
                    <div className="row">
                      <div className="col-12 col-lg-6 col-sm-6">
                        <div className="row">
                          <label
                            htmlFor="inputText"
                            className="col-sm-4 col-form-label"
                          >
                            Search OrderID/Customer
                          </label>
                          <div className="col-sm-6">
                          <input
                        type="text"
                        className="search-area"
                        placeholder="Search"
                        autoComplete="off"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-4 col-sm-4">
                        <div className="row">
                          
                          <div className="col-sm-8">
                          <DateRangePicker
                        value={[fromDate ? new Date(fromDate) : undefined, toDate ? new Date(toDate) : undefined]}
                        onChange={handleDateChange}
                        format="MM/dd/yyyy"
                      />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="verical-scrl-wrapper current-so-table" id="style-3">
                  <table className="table table-bordered dataTable" id="neworderTable1">
                    <thead>
                      <tr>
                        <th>Order Date</th>
                        <th>Exp. Del.</th>
                        <th>Order ID</th>
                        <th>Customers(ID)</th>
                        <th>Order Amount</th>
                        <th>Previous Dues</th>
                        <th>Total Dues</th>
                        <th>P.O</th>
                        <th>Status</th>
                        <th>Action</th>
                        <th>Transport</th>
                      </tr>
                    </thead>
                    <tbody>
                      {newSales.map((item, index) => (
                        <tr key={index}>
                          <td>{item.order_date}</td>
                          <td>{item.delivery_date}</td>
                          <td>
                              <a href={`/NewSalesOrderEdit/${item.ids}`}>{item.reference_no}</a>
                          </td>
                          <td>
                            <a
                              className="viewbtn"
                              href="#"
                              data-id={item.cid}
                              data-bs-toggle="modal"
                              data-bs-target="#view_customer"
                              title="View Customer"
                            >
                              {item.company_name}
                            </a>
                          </td>
                          <td style={{ textAlign: 'right' }}>{item.order_amount}</td>
                          <td>{item.previous_due}</td>
                          <td>{item.total_due}</td>
                          <td>
                            {item.bstatus !== "Mapped" ? (
                              item.status === "PF" ? (
                                <span>{item.bstatus}</span>
                              ) : (
                                <a
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#partial-popup"
                                  onClick={() => handleChangeBatchInfo(item.ids)}
                                >
                                  {item.bstatus}
                                </a>
                              )
                            ) : (
                              <span>{item.bstatus}</span>
                            )}
                          </td>
                          <td>{item.status}</td>
                          <td>
                            <div className="btn-group">
                              <button
                                type="button"
                                className="action-btn-dd dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Action
                              </button>
                              <ul className="dropdown-menu dropdown-menu-arrow">
                                {item.status === 'PF' && (
                                  <>
                                    <li>
                                      <a
                                        className="applybtn update-customer"
                                        onClick={(e) => handleGeneratePDF(e, item.ids)}
                                      >
                                        Download PF
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="applybtn update-customer"
                                        href="#"
                                        data-id={item.ids}
                                        data-bs-toggle="modal"
                                        data-bs-target="#change-customer"
                                      >
                                        Email PF
                                      </a>
                                    </li>
                                    <li>
                                      <a className="applybtn update-customer" href="#" onClick={(e)=>{convertToSO(item.ids, 'NA' ,newSales, setNewSales)}} >
                                        Convert to S.O
                                      </a>
                                    </li>
                                  </>
                                )}
                                {item.status === 'SO' && (
                                  <>
                                    {
                                      item.bstatus == "Mapped" ? (
                                        <li>
                                          <a
                                            className="deductionbtn reject-btn"
                                            href="#"
                                            onClick={() => createInvoiceClick(item.ids, newSales, setCreateInvoiceData, createInvoiceModalRef)}
                                            data-bs-toggle="modal"
                                            data-bs-target="#createinvoive"
                                          >
                                            Create Invoice
                                          </a>
                                        </li>
                                      ) : ''
                                    }
                                    <li>
                                      <a
                                        className="applybtn update-customer"
                                        onClick={(e) => handlegenerateDeliveryNotePDF(e, item.ids)}
                                      >
                                        Download Delivery Note
                                      </a>
                                    </li>
                                    {(item.bstatus === "Unmapped" || item.bstatus === "Partial") && (
                                      <li>
                                        <a className="applybtn update-customer" href={`/CreatePO/${item.ids}`}>Create a P.O</a>
                                      </li>
                                    )}

                                    <li>
                                      <Link to={`/salesCollectionNote/${item.ids}`}>Collection Note</Link>
                                    </li>
                                  </>
                                )}
                                <li>

                                  <a
                                    className="deductionbtn reject-btn"
                                    href="#"
                                    onClick={() => isSalesDeleteClick(item.ids, newSales, setNotes, setSalesInfoData)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#isSalesDeleteMdl"
                                  >
                                    Delete Order
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                          <td>
                            <label className="checkbox">
                              <input type="checkbox" />
                              <span className="default"></span>
                            </label>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="purchaseftr">
                  <div className="row">
                    <div className="col-6 col-lg-3 col-sm-3">Gross Total : {grossTotal.toFixed(2)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
        <Footer />
      </div>
    </div>

  );
};

export default CurrentSalesOrder;
