import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Header from "../Common_Files/Header";
import Footer from "../Common_Files/Footer";
import config from "../Common_Files/Config";
import ViewProduct from "../Master/ViewProduct";
import SupplierSearch from "../Purchase/SupplierSearch";
import ProductBatch from "./ProductBatch";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Select from "react-select";
import { format } from "date-fns";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useNavigate } from "react-router-dom";
import ProductList from "./ProductListPurchaseModel";

//fetchSupplierData
import {
  fetchProductData,
  fetchSupplierData,
  fetchWarehouseDeliveryData,
  fetchCustomerDeliveryData,
  changeWarehouseAddress,
  changeCustomerAddress,
  calculateValue,
  acceptNumber,
  acceptDecimalNumber,
  getAddressById,
  edithandleChange1,
} from "../../services/NewPurchaseOrderService";

const EditNewPurchaseOrder = () => {
  const [supplierData, setSupplierData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [product, setProduct] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [discTableRows, setDiscTableRows] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [warehouseDeliveryAddress, setWarehouseDeliveryAddress] = useState([]);
  const [customerDeliveryAddress, setCustomerDeliveryAddress] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState([]);
  const [customerAddress, setCustomerAddress] = useState([]);
  const [productBatch, setProductBatch] = useState([]);
  const [stdGoods, setStdGoods] = useState(0);
  const [vatAmt, setVatAmt] = useState(0);
  const [zeroGoods, setZeroGoods] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [netStdGoods, setNetStdGoods] = useState(0);
  const [netVatAmt, setNetVatAmt] = useState(0);
  const [netZeroGoods, setNetZeroGoods] = useState(0);
  const [netGrandTotal, setNetGrandTotal] = useState(0);
  const [productId, setProductId] = useState(0);
  const navigateToGRNList = useNavigate();
  const discModal = useRef(null);
  const [onso, setonso] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [productInputValue, setProductInputValue] = useState("");
  const [picker, setSelectedProduct] = useState("");
  const { id } = useParams();
  const [grnItems, setItems] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const [purchaseData, setPurchaseData] = useState([]);
  const [preSupplierData, setPreSupplierData] = useState();
  const [preDeliverData, setPreDeliverData] = useState();
  const [precustomerDeliverData, setPrecustomerDeliverData] = useState();
  const [collectionAddress, setCollectionAddress] = useState();
  const accessToken = localStorage.getItem("accessToken");
  const productBatchRef = useRef(null);
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [isDelivery, setIsDelivery] = useState("delivery");
  const [modalData, setModalData] = useState(null);
  const productListRef = useRef(null);

  const handleOpenPastPurchaseModal = (data) => {
    setModalData(data);
    if (productBatchRef.current) {
      productBatchRef.current();
    }
  };

  const handleDeliveryandCollectionAddress = (event) => {
    setDeliveryAddress({
      name: "",
      code: "",
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      country: "",
      postcode: "",
    });
    setCustomerAddress({
      name: "",
      code: "",
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      country: "",
      postcode: "",
    });

    setCollectionAddress({
      name: "",
      code: "",
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      country: "",
      postcode: "",
    });

    setIsDelivery(event.target.value);
  };

  const handleWarehouseandCustomerAddress = (event) => {
    setIsDelivery(event.target.value);
    setDeliveryAddress([]);
    setCustomerAddress([]);
  };

  useEffect(() => {
    $("#dsTable tbody").off("click", ".applybtn");
    $("#dsTable tbody").on("click", ".applybtn", function () {
      $("#freestock").empty();
      $("#stock").empty();
      $("#on_so").empty();
      $("#allocation").empty();
      $("#onpf").empty();
      $("#onpo").empty();
      const product_id = $(this).data("id");
      const formData = new FormData();
      formData.append("id", product_id);
      document.getElementById("viewProductForm").reset();
      const fetchProductData = async () => {
        const apiUrl = `${config.api_url}/ws-get-products-by-id`;
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            body: formData,
          });

          const data = await response.json();
          $("#code").val(data.products[0].code);
          $("#packValue").val(data.products[0].pack);
          $("#vat").val(data.products[0].vat);
          $("#weight").val(data.products[0].gross_weight);
          $("#supplier").val(data.products[0].supplier_name);
          $("#description").val(data.products[0].product_desc);
          $("#sizeValue").val(data.products[0].size);
          $("#barcode").val(data.products[0].unit_barcode);
          $("#view_case_barcode").val(data.products[0].case_barcode);
          $("#pallet_qty").val(data.products[0].pallet_qty);
          $("#case_price").val(data.products[0].cost);
          $("#view_sell_price").val(data.products[0].price);
          $("#product_status").html(
            data.products[0].status != 0
              ? 'Status : <span style="margin-left: 5px;" class="badge bg-success">Active</span>'
              : 'Status : <span style="margin-left: 5px;" class="badge bg-secondary"> Inactive</span>'
          );
          $("#viewcbm").val(data.products[0].cbm);
          $("#brandname").val(data.products[0].brand);
          $("#categooryName").val(data.products[0].category);
          $("#editnetWeight").val(data.products[0].net_weight);
          $("#editgrossWeight").val(data.products[0].gross_weight);
          $("#freestock").append(data.freestock == null ? 0 : data.freestock);
          $("#stock").append(parseInt(data.products[0].quantity));
          $("#on_so").append(data.onso == null ? 0 : data.onso);
          $("#allocation").append(
            data.allocation == null ? 0 : data.allocation
          );
          $("#onpf").append(data.pf == null ? 0 : data.pf);
          $("#onpo").append(data.on_purchase == null ? 0 : data.on_purchase);
        } catch (error) {
          console.error("Error fetching product data:", error);
        }
      };
      fetchProductData();
    });

    return () => {
      $("#dsTable tbody").off("click", ".applybtn");
    };
  }, []);

  const fetchGRNProducts = async (id) => {
    $("#purchase_id").val(id);
    const apiUrl = `${config.api_url}/ws-purchase-item-by-id`;
    let formData = new FormData();
    formData.append("purchase_id", id);

    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      // const bedsLeds = 0;
      // const costToServe = 0;
      // const offInvoice = 0;
      // const retro = 0;
      // const sugroRetro = 0;
      // const promptPayment = 0;
      // const desc = 0;

      const purchase_item = response.data.purchase_item;
      const initializedGRNItems = purchase_item.map((item) => ({
        ...item,
        // bedsLeds,
        // costToServe,
        // offInvoice,
        // retro,
        // sugroRetro,
        // promptPayment,
        //desc,
      }));

      setItems(initializedGRNItems);
      setBatchData(response.data.batch_info);
      const purchase = response.data.purchase;
      setPurchaseData(purchase);
      setPreSupplierData(response.data.purchase.supplier_id);
      // setPreDeliverData(response.data.purchase.delivery_id);
      // setPrecustomerDeliverData(response.data.purchase.delivery_id);

      if (purchase.delivery_type === "collection") {
        $("#caddressLine").val(purchase.collection_address_line1);
        $("#caddressLine2").val(purchase.collection_address_line2);
        $("#ccity").val(purchase.collection_city);
        // $('#state').val(purchase.state);
        $("#ccountry").val(purchase.collection_country);
        $("#cpostcode").val(purchase.collection_postcode);
        // $('#warehouse_id').val(purchase.warhouse_id);
        setPreDeliverData("");
        setPrecustomerDeliverData("");
        setIsDelivery("collection");
        setCollectionAddress({
          address_line1: purchase.collection_address_line1,
          address_line2: purchase.collection_address_line2,
          city: purchase.collection_city,
          country: purchase.collection_country,
          postcode: purchase.collection_postcode,
        });
      }

      if (
        purchase.is_wharehouse_or_customer === "warehouse" &&
        purchase.delivery_type === "delivery"
      ) {
        setPreDeliverData(response.data.purchase.delivery_id);
        setPrecustomerDeliverData();

        $("#warehouse_name").val(purchase.name);
        $("#wcode").val(purchase.code);
        $("#waddr1").val(purchase.address_line1);
        $("#waddr2").val(purchase.address_line2);
        $("#wcity").val(purchase.city);
        $("#wstate").val(purchase.state);
        $("#wcountry").val(purchase.country);
        $("#wpostcode").val(purchase.postcode);
        $("#warehouse_id").val(purchase.warehouse_id);
        setIsDelivery(purchase.is_wharehouse_or_customer);
      }

      if (
        purchase.is_wharehouse_or_customer === "customer" &&
        purchase.delivery_type === "delivery"
      ) {
        $("#caddr1").val(purchase.customer_delivery_address_line1);
        $("#caddr2").val(purchase.customer_delivery_address_line2);
        $("#ccity").val(purchase.customer_delivery_city);
        $("#cstate").val(purchase.customer_delivery_state);
        $("#ccountry").val(purchase.customer_delivery_country);
        $("#cpostcode").val(purchase.customer_delivery_postcode);
        // $('#warehouse_id').val(purchase.warehouse_id); // This line is commented out as per your original code

        setCustomerAddress({
          address_line1: purchase.customer_delivery_address_line1,
          address_line2: purchase.customer_delivery_address_line2,
          city: purchase.customer_delivery_city,
          state: purchase.customer_delivery_state,
          country: purchase.customer_delivery_country,
          postcode: purchase.customer_delivery_postcode,
        });
        setPreDeliverData("");
        setPrecustomerDeliverData(response.data.purchase.delivery_id);
        setIsDelivery(purchase.is_wharehouse_or_customer);
      }

      $("#arrival_date").val(purchase.arrival_date);
      $("#job_ref").val(purchase.job_ref);
      $("#notes").val(purchase.notes);
    } catch (error) {
      console.error("Error fetching warehouse data:", error);
      alert("An error occurred while fetching the data.");
    }
  };

  const calculateValue = (quantity, price) => {
    return quantity && price ? quantity * price : 0;
  };

  const handleInputChange = (e, id, field, setItems, grnItems, newlyAdded, order_qty) => {
    const value1 = acceptNumber(e.target.value);
    if (!newlyAdded) {
      const initializedItems = grnItems.map((item) => {
        if (item.id !== id) {
          return item;
        }

        return {
          ...item,
          received_qty: value1,
          order_qty: value1,
          value:
            field === "quantity"
              ? calculateValue(value1, item.cost)
              : calculateValue(item.quantity, value1),
          netp: parseFloat(item.cost) - parseFloat(item.desc || 0),
          netprice:
            field === "quantity"
              ? calculateValue(value1, item.cost)
              : calculateValue(item.quantity, value1),
          free_stock:
            (Number(item.sys_quantity) || 0) +
            (Number(item.free_stock) || 0) +
            Number(value1) -
            item.order_qty,
        };
      });

      setItems(initializedItems);
    }
  };

  const handleInputChange_cprice = (e, id, field, setItems, grnItems, newlyAdded, order_qty ) => {
    const value1 = acceptDecimalNumber(e.target.value); // Default to 0 if NaN
    if (!newlyAdded) 
      {
      const initializedItems = grnItems.map((item) => {
        if (item.id !== id) 
        {
          return item;
        }

        return {
          ...item,
          received_qty: order_qty,
          order_qty: order_qty,
          cost: value1,
          value:
            field === "quantity"
              ? calculateValue(order_qty, value1)
              : calculateValue(order_qty, value1),

          netp: value1 - item.desc,
          netprice:
            (field === "quantity"
              ? calculateValue(order_qty, value1)
              : calculateValue(order_qty, value1)) -
            (item.retro +
              item.promptPayment +
              item.costToServe +
              item.offInvoice +
              item.bedsLeds +
              item.sugroRetro),
          fstk:
            (Number(item.sys_quantity) || 0) +
            (Number(item.free_stock) || 0) +
            value1,
        };
      });

      setItems(initializedItems);
    }
  };

  const handleSubmit = async (e, grnItems, productId) => {
    e.preventDefault();
    const retro = parseFloat(formData.retro) || 0;
    const prompt_payment = parseFloat(formData.promptPayment) || 0;
    const cost_to_serve = parseFloat(formData.costToServe) || 0;
    const off_invoice = parseFloat(formData.offInvoice) || 0;
    const sugroRetro = parseFloat(formData.sugroRetro) || 0;
    const bedsLeds = parseFloat(formData.bedsLeds) || 0;
    let total =
      retro +
      prompt_payment +
      cost_to_serve +
      off_invoice +
      sugroRetro +
      bedsLeds;
    let a = 0;
    const initializedItems = grnItems.map((item) => {
      if (item.id !== productId || item.cost <= total) {
        return item;
      }

      a = 1;
      return {
        ...item,
        netp:
          item.cost -
          retro -
          prompt_payment -
          cost_to_serve -
          off_invoice -
          bedsLeds -
          sugroRetro,
        netprice:
          item.cost * item.order_qty -
          retro -
          prompt_payment -
          cost_to_serve -
          off_invoice -
          bedsLeds -
          sugroRetro,
        retro,
        promptPayment: prompt_payment,
        costToServe: cost_to_serve,
        offInvoice: off_invoice,
        bedsLeds,
        sugroRetro,
        desc: total,
      };
    });

    if (a === 0) {
      Swal.fire({
        title: "Error!",
        text: "Please enter a discount less than the value.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    } else {
      setItems(initializedItems);
    }

    // console.log('Initialized Items:', grnItems);

    // Reset form data
    setFormData({
      retro: "",
      promptPayment: "",
      costToServe: "",
      offInvoice: "",
      bedsLeds: "",
      sugroRetro: "",
    });

    const bsModal = window.bootstrap.Modal.getInstance(discModal.current);
    if (bsModal) bsModal.hide();
  };

  const [formData, setFormData] = useState({
    retro: "",
    promptPayment: "",
    costToServe: "",
    offInvoice: "",
    bedsLeds: "",
    sugroRetro: "",
  });

  const handleDiscount = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleShowPastPurchaseModal = (showModalHandler) => {
    showModalHandler();
  };

  useEffect(() => {
    fetchSupplierData(setSupplierData);
    fetchProductData(setProductData);
    fetchWarehouseDeliveryData(setWarehouseDeliveryAddress);
    fetchCustomerDeliveryData(setCustomerDeliveryAddress);
    fetchGRNProducts(id);
  }, []);

  useEffect(() => {
    const calculateTotals = () => {
      const totalVat = grnItems.reduce((sum, item) => {
        const itemVat =
          parseFloat(item.order_qty) *
            parseFloat(item.cost) *
            (parseFloat(item.vat_percentage) / 100) || 0;
        return sum + itemVat;
      }, 0);

      setVatAmt(totalVat);

      const newGrandTotal = grnItems.reduce(
        (total, row) =>
          total + parseFloat(row.order_qty) * parseFloat(row.cost) || 0,
        0
      );

      setGrandTotal(newGrandTotal + totalVat);

      const stdGoods = grnItems.reduce((total, row) => {
        if (parseFloat(row.vat) > 0) {
          return total + parseFloat(row.order_qty) * parseFloat(row.cost) || 0;
        }
        return total;
      }, 0);

      //  console.log(stdGoods);

      setStdGoods(stdGoods);

      const zeroGoods = grnItems.reduce((total, row) => {
        const vat = parseFloat(row.vat);
        const value = parseFloat(row.value);

        // const netprice = parseFloat(row.netprice);
        // console.log('vat_'+vat);
        // console.log('netprice_'+netprice);

        if (!isNaN(value) && vat === 0 && !isNaN(value)) {
          return total + value;
        }
        return total;
      }, 0);

      console.log("zeroGoods_" + zeroGoods);
      setZeroGoods(zeroGoods);

      const totalNetVat = grnItems.reduce((sum, item) => {
        const itemVat =
          (parseFloat(item.cost) - parseFloat(item.desc)) *
            parseFloat(item.order_qty) *
            (parseFloat(item.vat_percentage) / 100) || 0;
        return sum + itemVat;
      }, 0);
      setNetVatAmt(totalNetVat);

      const newNetGrandTotal = grnItems.reduce(
        (total, row) =>
          total +
            (parseFloat(row.cost) - parseFloat(row.desc)) *
              parseFloat(row.order_qty) || 0,
        0
      );
      setNetGrandTotal(newNetGrandTotal + totalNetVat);

      const netStdGoods = grnItems.reduce((total, row) => {
        const cost = parseFloat(row.cost) || 0; // Default to 0 if NaN
        const desc = parseFloat(row.desc) || 0; // Default to 0 if NaN
        const orderQty = parseFloat(row.order_qty) || 0; // Default to 0 if NaN
        const vat = parseFloat(row.vat) || 0; // Default to 0 if NaN

        if (vat > 0) {
          return total + (cost - desc) * orderQty;
        }
        return total;
      }, 0);

      setNetStdGoods(netStdGoods);

      const netZeroGoods = grnItems.reduce((total, row) => {
        const vat = parseFloat(row.vat);
        const cost = parseFloat(row.cost) || 0;
        const desc = parseFloat(row.desc) || 0;
        const order_qty = parseInt(row.order_qty) || 0;

        if (!isNaN(vat) && vat === 0) {
          return total + (cost - desc) * order_qty;
        }

        return total;
      }, 0);
      // alert(netZeroGoods);
      setNetZeroGoods(netZeroGoods);

      // console.log(tableRows);
    };

    calculateTotals();
    console.log(grnItems);
  }, [grnItems]);

  const handleProductChange = (inputValue) => {
    setSelectedProduct(inputValue);
  };

  const handleChange = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setProductInputValue(selectedOption);
      edithandleChange1(selectedOption.value, grnItems, setItems);
    } else {
      setProductInputValue(null);
      console.warn("Selected option is invalid or missing a value");
    }
  };

  const getProductBatchData = async (id) => {
    try {
      const formData = new FormData();
      formData.set("product_id", id);

      const response = await axios.post(
        `${config.api_url}/ws-batch-info-by-product-id`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data;
      setProductBatch(data.batch);
      handleOpenPastPurchaseModal(data.batch);
    } catch (error) {
      console.error("Error fetching product batch data:", error);
    }
  };

  const removeRow = (id, setItems) => {
    setItems((prevRows) => prevRows.filter((row) => row.id !== id));
  };

  const getonpo = async (id) => {
    try {
      const formData = new FormData();
      formData.set("product_id", id);
      const response = await axios.post(
        `${config.api_url}/ws-customer-wise-sales-order`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data;
      setonso(data.sales);
      //  handleOpenPastPurchaseModal(data.batch);
    } catch (error) {
      console.error("Error fetching product batch data:", error);
    }
  };

  const submitPurchaseOrderForm = async () => {
    const form = document.getElementById("newPurchaseForm");
    const formData = new FormData(form);
    formData.append("purchase_id", id);
    const supplierId = formData.get("supplier_id");
    const job_ref = formData.get("job_ref");

    // if (job_ref) {
    // } else {
    //   Swal.fire({
    //     title: "Error!",
    //     text: "Job Ref is Required",
    //     icon: "error",
    //     timer: 1500,
    //     showConfirmButton: false,
    //   });
    //   return;
    // }

    if (supplierId) {
    } else {
      Swal.fire({
        title: "Error!",
        text: "Supplier is Required",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }
    const hasValidProduct = grnItems.some((row) => {
      const quantity = parseFloat(row.order_qty);
      return !isNaN(quantity) && quantity > 0;
    });

    if (!grnItems.length || !hasValidProduct) {
      Swal.fire({
        title: "Error!",
        text: "Please select at least one product and ensure the quantity is greater than zero.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }
    grnItems.forEach((row, index) => {
      formData.append(`code[${index}]`, row.code);
      formData.append(`product_id[${index}]`, row.product_id);
      formData.append(`packsize[${index}]`, row.pack);
      formData.append(`productSize[${index}]`, row.size);
      formData.append(`qty[${index}]`, row.order_qty);
      formData.append(`sys_qty[${index}]`, row.sys_quantity);
      formData.append(`price[${index}]`, row.cost);
      formData.append(`value[${index}]`, row.value);
      formData.append(`Disc[${index}]`, row.desc || 0);
      formData.append(`NetP[${index}]`, row.netp || 0);
      formData.append(`vat[${index}]`, row.vat);
      formData.append(`vatpercentage[${index}]`, row.vat_percentage);
      formData.append(`currrentStock[${index}]`, row.curStock);
      formData.append(`onSO[${index}]`, row.onSO);
      formData.append(`fstk[${index}]`, row.fstk);
      formData.append(`retro[${index}]`, row.retro);
      formData.append(`promptPayment[${index}]`, row.promptPayment);
      formData.append(`costToServe[${index}]`, row.costToServe);
      formData.append(`offInvoice[${index}]`, row.offInvoice);
      formData.append(`bedsLeds[${index}]`, row.bedsLeds);
      formData.append(`sugroRetro[${index}]`, row.sugroRetro);
    });

    try {
      const response = await axios.post(
        `${config.api_url}/ws-update-purchases-order`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const result = response.data;
      Swal.fire({
        title: "Success!",
        text: "Order submitted successfully.",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
      navigateToGRNList("/GRN");
    } catch (error) {
      console.error("Error accepting order:", error);
    }
  };

  const handleDiscClick = (id) => {
    setProductId(id);
    let data = grnItems.find((item) => item.id === id);

    // console.log(data);

    if (
      data.bedsLeds ||
      data.costToServe ||
      data.offInvoice ||
      data.retro ||
      data.sugroRetro ||
      data.promptPayment
    ) {
      setFormData({
        retro: data.retro || 0,
        promptPayment: data.promptPayment || 0,
        costToServe: data.costToServe || 0,
        offInvoice: data.offInvoice || 0,
        bedsLeds: data.bedsLeds || 0,
        sugroRetro: data.sugroRetro || 0,
      });
    } else {
      setFormData({
        retro: "",
        promptPayment: "",
        costToServe: "",
        offInvoice: "",
        bedsLeds: "",
        sugroRetro: "",
      });
    }
  };

  const getProductList = async (id) => {
    try {
      const formData = new FormData();
      formData.set("product_id", id);

      const response = await axios.post(
        `${config.api_url}/ws-get-products`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data;
      setProductBatch(data.products);
      handleOpenProductListModal(data.products);
    } catch (error) {
      console.error("Error fetching product batch data:", error);
    }
  };

  const handleOpenProductListModal = (data) => {
    setModalData(data);
    if (productListRef.current) {
      productListRef.current();
    }
  };

  return (
    <div>
      <div className="modal fade" id="ON-PO" tabIndex="-1">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">(SKIO11) SKITTLES FRUITS</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {onso.map((order) => (
                <div className="partialbox">
                  <h3>
                    S.O: {order.reference_no}{" "}
                    <span>
                      {order.company_name} ({order.code})
                    </span>
                  </h3>
                  <table className="table table-bordered dataTable">
                    <thead>
                      <tr>
                        <th>Assign</th>
                        <th>Order Qty</th>
                        <th>On P.O</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            className=""
                            type="radio"
                            name="gridRadios"
                            value="option1"
                            defaultChecked
                          />
                        </td>
                        <td>{order.quantity}</td>
                        <td>{order.po_qty}</td>
                        <td>{order.po_qty - order.quantity}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="adnw-prct" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">&nbsp;</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="partialbox">
                <h3>Search (Code/Description/Unit Barcode)</h3>
                <table className="table table-bordered dataTable">
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Description</th>
                      <th>PK</th>
                      <th>Size</th>
                      <th>Unit Barcode</th>
                      <th>Stock</th>
                      <th>F.Stk</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <a href="#" target="blank">
                          SKI010
                        </a>
                      </td>
                      <td>SKITTLES CRAZY SOURS</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>
                    </tr>

                    <tr>
                      <td>
                        <a href="#" target="blank">
                          SKI010
                        </a>
                      </td>
                      <td>SKITTLES CRAZY SOURS</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>
                    </tr>
                    <tr>
                      <td>
                        <a href="#" target="blank">
                          SKI010
                        </a>
                      </td>
                      <td>SKITTLES CRAZY SOURS</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>
                    </tr>

                    <tr>
                      <td>
                        <a href="#" target="blank">
                          SKI010
                        </a>
                      </td>
                      <td>SKITTLES CRAZY SOURS</td>
                      <td>36</td>
                      <td>45GM</td>
                      <td>&nbsp;</td>
                      <td>324</td>
                      <td>176</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductBatch
        onViewPastPurchase={(showModal) => {
          productBatchRef.current = showModal;
        }}
        getProductBatchData={getProductBatchData}
        modalData={modalData}
      />

      <div className="modal fade" id="viw-disk" tabIndex="-1" ref={discModal}>
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title">&nbsp; Add Discount</h6>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="adddiscount-popup">
                <form
                  onSubmit={(e) => handleSubmit(e, grnItems, productId)}
                  id="addDiscountForm"
                >
                  <table className="table table-bordered dataTable">
                    <tbody>
                      <tr>
                        <td>Retro</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="retro"
                            value={formData.retro}
                            onChange={handleDiscount}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Prompt Payment</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="promptPayment"
                            value={formData.promptPayment}
                            onChange={handleDiscount}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Cost to Serve</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="costToServe"
                            value={formData.costToServe}
                            onChange={handleDiscount}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Off Invoice</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="offInvoice"
                            value={formData.offInvoice}
                            onChange={handleDiscount}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Beds & Leds</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="bedsLeds"
                            value={formData.bedsLeds}
                            onChange={handleDiscount}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Sugro Retro</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="sugroRetro"
                            value={formData.sugroRetro}
                            onChange={handleDiscount}
                            autoComplete="off"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <button
                    className="popup-innerbtn-common"
                    type="submit"
                    style={{ marginTop: "10px" }}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Header />
      <ViewProduct />

























      <ProductList
        onViewPastPurchase={(showModal) => {
          productListRef.current = showModal;
        }}
        getProductBatchData={getProductBatchData}
        modalData={modalData}
        setTableRows={setItems}
        tableRows={grnItems}
      />
















      <form id="newPurchaseForm">
        <div className="breadcrumbs">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-12 col-sm-12">
                {" "}
                <a href="#">
                  <i className="fa fa-home"></i>
                </a>{" "}
                &gt; <a href="dashboard">Dashboard</a> &gt;{" "}
                <span className="last-crumb">Edit Purchase Order</span>{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="main-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-12 col-sm-12">
                <div className="dashboard">
                  <div className="">
                    <div className="dashform">
                      <div className="topsearch-area">
                        <div className="row">
                          <div className="col-12 col-lg-3 col-sm-3">
                            <div className="row">
                              <label
                                htmlFor="inputText"
                                className="col-sm-4 col-form-label"
                              >
                                Supplier:
                              </label>
                              <div className="col-sm-8">
                                <select
                                  name="supplier_id"
                                  className="form-select"
                                  value={preSupplierData}
                                  onChange={(e) => {
                                    setPreSupplierData(e.target.value);
                                  }}
                                  required
                                >
                                  <option value="">Select Supplier</option>
                                  {supplierData.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-lg-3 col-sm-3">
                            <div className="row">
                              <label
                                htmlFor="inputText"
                                className="col-sm-4 col-form-label"
                              >
                                Product:
                              </label>
                              <div className="col-sm-8">
                                <buttion
                                  to="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    getProductList();
                                  }} className="create-catalogue-btn-left"
                                >
                                Add New Product
                              </buttion> 
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="verical-scrl-wrapper common-table purchase-no-tbl"
                      id="style-3"
                    >
                      <table
                        className="table table-bordered dataTable"
                        id="dsTable"
                      >
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Description</th>
                            <th>Pk</th>
                            <th>Size</th>
                            <th>Qty</th>
                            <th>CPrice</th>
                            <th>Value</th>
                            <th>Disc</th>
                            <th>NetP</th>
                            <th>VAT</th>
                            <th>CurStock</th>
                            <th>On S.O</th>
                            <th>FStk</th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {grnItems.length === 0 ? (
                            <tr style={{ textAlign: "center" }}>
                              <td colSpan={14}>No product added</td>
                            </tr>
                          ) : (
                            grnItems.map((row) => {
                              const net_price = parseFloat(row.cost) || 0;
                              const value = parseFloat(row.value) || 0;

                              // console.log('net_value'+value);
                              const retro = parseFloat(row.retro) || 0;
                              const promptPayment =
                                parseFloat(row.promptPayment) || 0;
                              const costToServe =
                                parseFloat(row.costToServe) || 0;
                              const offInvoice =
                                parseFloat(row.offInvoice) || 0;
                              const bedsLeds = parseFloat(row.bedsLeds) || 0;
                              const sugroRetro =
                                parseFloat(row.sugroRetro) || 0;
                              const vat = parseFloat(row.vat) || 0;
                              //    const totalCosts = promptPayment + costToServe + offInvoice + bedsLeds + sugroRetro + retro;
                              const totalCosts = parseFloat(row.desc) || 0;
                              const totalCost =
                                (parseFloat(row.cost) || 0) *
                                (parseFloat(row.order_qty) || 0); // Total cost of the items
                              const vatPercentage =
                                parseFloat(row.vat_percentage) || 0; // Ensure vat_percentage is a number
                              const vatAmount = (
                                (totalCost * vatPercentage) /
                                100
                              ).toFixed(2); // VAT amount based on total cost

                              const fstk_cal = parseInt(row.free_stock) || 0;
                              //console.log(fstk_cal);

                              return (
                                <tr key={row.id}>
                                  <td>
                                    <input
                                      type="hidden"
                                      name="product_id[]"
                                      value={row.product_id}
                                    />
                                    <Link
                                      to="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        getProductBatchData(row.product_id);
                                      }}
                                    >
                                      {row.code}
                                    </Link>
                                  </td>
                                  <td>
                                    <p className="cut-text">
                                      <a
                                        data-bs-toggle="modal"
                                        className="applybtn"
                                        data-id={row.product_id}
                                        data-bs-target="#viewproduct"
                                      >
                                        {row.product_desc}
                                      </a>
                                    </p>
                                  </td>
                                  <td>{row.pack}</td>
                                  <td>{row.size}</td>
                                  <td>
                                    <input
                                      type="text"
                                      name="qty[]"
                                      className="form-control qtyinputbox"
                                      value={row.order_qty}
                                      autoComplete="off"
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          row.id,
                                          "quantity",
                                          setItems,
                                          grnItems,
                                          row.newlyAdded,
                                          row.order_qty
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      name="cost[]"
                                      className="form-control qtyinputbox"
                                      value={row.cost}
                                      autoComplete="off"
                                      onChange={(e) =>
                                        handleInputChange_cprice(
                                          e,
                                          row.id,
                                          "cost",
                                          setItems,
                                          grnItems,
                                          row.newlyAdded,
                                          row.order_qty
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="hidden"
                                      name="value[]"
                                      className="form-control qtyinputbox"
                                      value={Number(row.value ?? 0).toFixed(2)}
                                      readOnly
                                    />
                                    {Number(row.value ?? 0).toFixed(2)}
                                  </td>
                                  <td>
                                    <input
                                      type="hidden"
                                      name="Disc[]"
                                      value={(totalCosts ?? 0).toFixed(2)}
                                    />
                                    <a
                                      href="#"
                                      data-id={row.id}
                                      onClick={() => handleDiscClick(row.id)}
                                      data-bs-toggle="modal"
                                      data-bs-target="#viw-disk"
                                    >
                                      {(totalCosts ?? 0).toFixed(2)}
                                    </a>
                                  </td>
                                  <td>
                                    <input
                                      type="hidden"
                                      name="NetP[]"
                                      value={parseFloat(row.netp) ?? 0}
                                    />
                                    {parseFloat(row.netp) ?? 0}
                                  </td>
                                  <td>
                                    <input
                                      type="hidden"
                                      name="vat[]"
                                      value={vatAmount ?? 0}
                                    />
                                    <input
                                      type="hidden"
                                      name="vatpercentage[]"
                                      value={row.vat_percentage ?? 0}
                                    />
                                    {vatAmount ?? 0}
                                  </td>
                                  <td>
                                    <input
                                      type="hidden"
                                      name="currrentStock[]"
                                      value={row.sys_quantity ?? 0}
                                    />
                                    {row.current_stock ?? 0}
                                  </td>
                                  <td>
                                    <input
                                      type="hidden"
                                      name="onSO[]"
                                      value="0"
                                    />
                                    <span>{row.on_so || 0}</span>
                                  </td>
                                  <td>
                                    <input
                                      type="hidden"
                                      name="fstk[]"
                                      value="0"
                                    />
                                    {fstk_cal ?? 0}
                                  </td>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={() =>
                                        removeRow(row.id, setItems)
                                      }
                                    >
                                      <i className="fa fa-trash"></i>
                                    </a>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="purchaseftr">
                      <div className="row">
                        <div className="col-6 col-lg-3 col-sm-3">
                          <p className="frtbx">
                            {" "}
                            Std Goods :{" "}
                            <span id="std_goods">{stdGoods.toFixed(2)}</span>
                          </p>
                        </div>
                        <div className="col-6 col-lg-3 col-sm-3">
                          <p className="frtbx">
                            {" "}
                            VAT Amount :{" "}
                            <span id="vat_amt">{vatAmt.toFixed(2)}</span>
                          </p>
                        </div>
                        <div className="col-6 col-lg-3 col-sm-3">
                          <p className="frtbx">
                            {" "}
                            Zero Goods :{" "}
                            <span id="zero_goods">{zeroGoods.toFixed(2)}</span>
                          </p>
                        </div>
                        <div className="col-6 col-lg-3 col-sm-3">
                          <p className="frtbx last">
                            {" "}
                            Gross Total :{" "}
                            <span id="grand_total">
                              {grandTotal.toFixed(2)}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="purchaseftr">
                      <div className="row">
                        <div className="col-6 col-lg-3 col-sm-3">
                          <p className="frtbx">
                            {" "}
                            Net Std Goods :{" "}
                            <span id="std_goods">{netStdGoods.toFixed(2)}</span>
                          </p>
                        </div>
                        <div className="col-6 col-lg-3 col-sm-3">
                          <p className="frtbx">
                            {" "}
                            VAT Amount :{" "}
                            <span id="vat_amt">{netVatAmt.toFixed(2)}</span>
                          </p>
                        </div>
                        <div className="col-6 col-lg-3 col-sm-3">
                          <p className="frtbx">
                            {" "}
                            Zero Goods :{" "}
                            <span id="zero_goods">
                              {netZeroGoods.toFixed(2)}
                            </span>
                          </p>
                        </div>
                        <div className="col-6 col-lg-3 col-sm-3">
                          <p className="frtbx last">
                            {" "}
                            Gross Total :{" "}
                            <span id="grand_total">
                              {netGrandTotal.toFixed(2)}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="purchaseform">
                      <div className="row mb-3 padd-l-13px">
                        <div className="col-6 col-lg-6 col-sm-6">
                          <div className="row mb-3">
                            <div className="col-3 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">
                                Date of Arrival
                              </label>
                            </div>

                            <div className="col-3 col-lg-3 col-sm-3">
                              <input
                                type="date"
                                className="form-control"
                                name="arrival_date"
                                id="arrival_date"
                              />
                            </div>

                            <div className="col-3 col-lg-3 col-sm-3">
                              <label
                                htmlFor="desc"
                                className="col-form-label padd-left-50"
                              >
                                Job Ref
                              </label>
                            </div>

                            <div className="col-3 col-lg-3 col-sm-3">
                              <input
                                type="text"
                                className="form-control"
                                name="job_ref"
                                id="job_ref"
                              />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-3 col-lg-3 col-sm-3">
                              <label className="col-form-label padd-left-50">
                                Delivery
                              </label>
                            </div>

                            <div className="col-3 col-lg-3 col-sm-3">
                              <input
                                type="radio"
                                name="delivery_type"
                                id="delivery_type"
                                value="delivery"
                                checked={
                                  isDelivery === "warehouse" ||
                                  isDelivery === "customer"
                                }
                                onChange={(e) =>
                                  handleDeliveryandCollectionAddress(e)
                                }
                                style={{ float: "left" }}
                              />
                            </div>

                            <div className="col-3 col-lg-3 col-sm-3">
                              <label
                                htmlFor="collection"
                                className="col-form-label padd-left-50"
                              >
                                Collection
                              </label>
                            </div>

                            <div className="col-3 col-lg-3 col-sm-3">
                              <input
                                type="radio"
                                name="delivery_type"
                                id="collection_type"
                                value="collection"
                                checked={isDelivery === "collection"}
                                onChange={(e) =>
                                  handleDeliveryandCollectionAddress(e)
                                }
                              />
                            </div>
                          </div>

                          {(isDelivery === "delivery" ||
                            isDelivery === "customer" ||
                            isDelivery === "warehouse") && (
                            <div className="row mb-3">
                              <div className="col-3 col-lg-3 col-sm-3">
                                <label
                                  htmlFor="warehouse"
                                  className="col-form-label padd-left-50"
                                >
                                  Warehouse Address
                                </label>
                              </div>
                              <div className="col-3 col-lg-3 col-sm-3">
                                <input
                                  type="radio"
                                  onChange={handleWarehouseandCustomerAddress}
                                  name="handleWarehouseandCustomerAddress"
                                  id="warehouse"
                                  checked={isDelivery === "warehouse"}
                                  value="warehouse"
                                />
                              </div>

                              <div className="col-3 col-lg-3 col-sm-3">
                                <label
                                  htmlFor="customer"
                                  className="col-form-label padd-left-50"
                                >
                                  Customer Address
                                </label>
                              </div>
                              <div className="col-3 col-lg-3 col-sm-3">
                                <input
                                  type="radio"
                                  onChange={handleWarehouseandCustomerAddress}
                                  name="handleWarehouseandCustomerAddress"
                                  id="customer"
                                  checked={isDelivery === "customer"}
                                  value="customer"
                                />
                              </div>
                            </div>
                          )}

                          <div className="row mb-3">
                            <div className="col-6 col-lg-3 col-sm-3">
                              <label htmlFor="desc" className="col-form-label">
                                Notes
                              </label>
                            </div>
                            <div className="col-6 col-lg-9 col-sm-9">
                              <textarea
                                className="form-control"
                                name="notes"
                                id="notes"
                                style={{ height: "200px" }}
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        {(isDelivery === "delivery" ||
                          isDelivery === "warehouse" ||
                          isDelivery === "customer") && (
                          <div className="col-6 col-lg-6 col-sm-6">
                            {(isDelivery === "warehouse" ||
                              isDelivery === "delivery") && (
                              <div className="row mb-3">
                                <div className="col-6 col-lg-3 col-sm-3">
                                  <label
                                    htmlFor="desc"
                                    className="col-form-label"
                                  >
                                    Delivery Address{" "}
                                    <span className="red">*</span>
                                  </label>
                                </div>

                                <div className="col-6 col-lg-9 col-sm-9">
                                  <select
                                    name="delivery_id"
                                    id="delivery_id"
                                    className="form-select orgselect"
                                    value={preDeliverData}
                                    onChange={(event) => {
                                      changeWarehouseAddress(
                                        event,
                                        setDeliveryAddress,
                                        warehouseDeliveryAddress
                                      );
                                      setPreDeliverData(event.target.value);
                                    }}
                                  >
                                    <option value="">Select Address</option>
                                    {warehouseDeliveryAddress.map(
                                      (item, index) => (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                            )}

                            {isDelivery === "customer" && (
                              <>
                                <div className="row mb-3">
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <label
                                      htmlFor="desc"
                                      className="col-form-label"
                                    >
                                      Customer Address{" "}
                                      <span className="red">*</span>
                                    </label>
                                  </div>
                                  <div className="col-6 col-lg-9 col-sm-9">
                                    <select
                                      name="delivery_id"
                                      id="delivery_id"
                                      className="form-select orgselect"
                                      value={precustomerDeliverData}
                                      onChange={(event) => {
                                        changeCustomerAddress(
                                          event,
                                          setCustomerAddress,
                                          customerDeliveryAddress
                                        );
                                        setPreDeliverData(event.target.value);
                                      }}
                                    >
                                      <option value="">Select Address</option>
                                      {customerDeliveryAddress.map(
                                        (item, index) => (
                                          <option key={index} value={item.id}>
                                            {item.address_line1}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>

                                <div className="row mb-3">
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <label
                                      htmlFor="addressLine1"
                                      className="col-form-label"
                                    >
                                      Address Line 1
                                    </label>
                                  </div>
                                  <div className="col-6 col-lg-9 col-sm-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="addressLine1"
                                      id="addressLine1"
                                      value={customerAddress.address_line1}
                                    />
                                  </div>
                                </div>

                                <div className="row mb-3">
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <label
                                      htmlFor="addressLine2"
                                      className="col-form-label"
                                    >
                                      Address Line 2
                                    </label>
                                  </div>
                                  <div className="col-6 col-lg-9 col-sm-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="addressLine2"
                                      id="addressLine2"
                                      value={customerAddress.address_line2}
                                    />
                                  </div>
                                </div>

                                <div className="row mb-3">
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <label
                                      htmlFor="city"
                                      className="col-form-label"
                                    >
                                      City
                                    </label>
                                  </div>
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="city"
                                      id="city"
                                      value={customerAddress.city}
                                    />
                                  </div>
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <label
                                      htmlFor="state"
                                      className="col-form-label padd-left-50"
                                    >
                                      State
                                    </label>
                                  </div>
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="state"
                                      id="state"
                                      value={customerAddress.state}
                                    />
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <label
                                      htmlFor="country"
                                      className="col-form-label"
                                    >
                                      Country
                                    </label>
                                  </div>
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="country"
                                      id="country"
                                      value={customerAddress.country}
                                    />
                                  </div>
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <label
                                      htmlFor="postCode"
                                      className="col-form-label padd-left-50"
                                    >
                                      Post Code
                                    </label>
                                  </div>
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="postCode"
                                      id="postCode"
                                      value={customerAddress.postcode}
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                            {(isDelivery === "warehouse" ||
                              isDelivery === "delivery") && (
                              <>
                                <div className="row mb-3">
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <label
                                      htmlFor="name"
                                      className="col-form-label"
                                    >
                                      Warehouse Name
                                    </label>
                                  </div>
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="name"
                                      id="warehouse_name"
                                      value={deliveryAddress.name}
                                      disabled
                                    />
                                  </div>
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <label
                                      htmlFor="code"
                                      className="col-form-label padd-left-50"
                                    >
                                      Code
                                    </label>
                                  </div>
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="code"
                                      id="wcode"
                                      value={deliveryAddress.code}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <label
                                      htmlFor="addressLine1"
                                      className="col-form-label"
                                    >
                                      Address Line 1
                                    </label>
                                  </div>
                                  <div className="col-6 col-lg-9 col-sm-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="addressLine1"
                                      id="waddr1"
                                      value={deliveryAddress.address_line1}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <label
                                      htmlFor="desc"
                                      className="col-form-label"
                                    >
                                      Address Line 2
                                    </label>
                                  </div>
                                  <div className="col-6 col-lg-9 col-sm-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="addressLine2"
                                      id="waddr2"
                                      value={deliveryAddress.address_line2}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <label
                                      htmlFor="desc"
                                      className="col-form-label"
                                    >
                                      City
                                    </label>
                                  </div>
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="city"
                                      id="wcity"
                                      value={deliveryAddress.city}
                                      disabled
                                    />
                                  </div>
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <label
                                      htmlFor="desc"
                                      className="col-form-label padd-left-50"
                                    >
                                      State
                                    </label>
                                  </div>
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="state"
                                      id="wstate"
                                      value={deliveryAddress.state}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <label
                                      htmlFor="desc"
                                      className="col-form-label"
                                    >
                                      Country
                                    </label>
                                  </div>
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="country"
                                      id="wcountry"
                                      value={deliveryAddress.country}
                                      disabled
                                    />
                                  </div>
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <label
                                      htmlFor="desc"
                                      className="col-form-label padd-left-50"
                                    >
                                      Post Code
                                    </label>
                                  </div>
                                  <div className="col-6 col-lg-3 col-sm-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="postCode"
                                      id="wpostCode"
                                      value={deliveryAddress.postcode}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                        {isDelivery === "collection" && (
                          <div className="col-6 col-lg-6 col-sm-6">
                            <div className="row mb-3">
                              <div className="col-6 col-lg-3 col-sm-3">
                                <label
                                  htmlFor="desc"
                                  className="col-form-label padd-left-50"
                                >
                                  Collection Address
                                </label>
                              </div>
                              <div className="col-6 col-lg-3 col-sm-3"></div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-6 col-lg-3 col-sm-3">
                                <label
                                  htmlFor="desc"
                                  className="col-form-label"
                                >
                                  Address Line 1
                                </label>
                              </div>
                              <div className="col-6 col-lg-9 col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="addressLine1"
                                  id="caddressLine"
                                  value={collectionAddress.address_line1}
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-6 col-lg-3 col-sm-3">
                                <label
                                  htmlFor="desc"
                                  className="col-form-label"
                                >
                                  Address Line 2
                                </label>
                              </div>
                              <div className="col-6 col-lg-9 col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="addressLine2"
                                  id="caddressLine2"
                                  value={collectionAddress.address_line2}
                                />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-6 col-lg-3 col-sm-3">
                                <label
                                  htmlFor="desc"
                                  className="col-form-label"
                                >
                                  City
                                </label>
                              </div>
                              <div className="col-6 col-lg-3 col-sm-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="city"
                                  id="ccity"
                                  value={collectionAddress.city}
                                />
                              </div>

                              <div className="col-6 col-lg-3 col-sm-3">
                                <label
                                  htmlFor="desc"
                                  className="col-form-label padd-left-50"
                                >
                                  State
                                </label>
                              </div>
                              <div className="col-6 col-lg-3 col-sm-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="state"
                                  id="cstate"
                                  value={collectionAddress.state}
                                />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <div className="col-6 col-lg-3 col-sm-3">
                                <label
                                  htmlFor="desc"
                                  className="col-form-label"
                                >
                                  Country
                                </label>
                              </div>
                              <div className="col-6 col-lg-3 col-sm-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="country"
                                  id="ccountry"
                                  value={collectionAddress.country}
                                />
                              </div>
                              <div className="col-6 col-lg-3 col-sm-3">
                                <label
                                  htmlFor="desc"
                                  className="col-form-label padd-left-50"
                                >
                                  Post Code
                                </label>
                              </div>
                              <div className="col-6 col-lg-3 col-sm-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="postCode"
                                  id="cpostCode"
                                  value={collectionAddress.postcode}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        <input
                          type="hidden"
                          name="std_goods"
                          value={stdGoods}
                        />
                        <input
                          type="hidden"
                          name="zero_goods"
                          value={zeroGoods}
                        />
                        <input type="hidden" name="vat_amount" value={vatAmt} />
                        <input
                          type="hidden"
                          name="grand_total"
                          value={grandTotal}
                        />
                        <div className="col-6 col-lg-12 col-sm-12">
                          <hr />
                        </div>

                        <div className="col-6 col-lg-12 col-sm-12">
                          <Link
                            to="#"
                            className="popup-innerbtn-common right"
                            onClick={submitPurchaseOrderForm}
                            id="submitPurchaseOrderForm"
                          >
                            Update Purchase Order
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
};

export default EditNewPurchaseOrder;
