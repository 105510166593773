import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import config from "../../Common_Files/Config";



const AddCustomerModal = ({ show, onHide, onSubmit }) => {
  const modalRef = useRef(null);
  const [showCreditFields, setShowCreditFields] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  // const [showModal, setShowModal] = useState(false);
  // const handleCloseModal = () => setShowModal(false);
  const accessToken = localStorage.getItem("accessToken");
  const [isSubmitting, setIsSubmitting] = useState(false);


  const handleCreditFacilityChange = (event) => {
    setShowCreditFields(event.target.checked);
  };

  const addNewAddress = () => {
    setDeliveryAddresses([...deliveryAddresses, {}]);
  };


  const removeAddress = (index) => {
    let isChecked = $(".checkDefault:checked").val();
    setDeliveryAddresses(deliveryAddresses.filter((_, i) => i !== index));
    if (isChecked != "1" || deliveryAddresses.length <= 1) {
      $("#defaultDeliveryAdr").prop("checked", true);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    onSubmit(); 
    const bsModal = window.bootstrap.Modal.getInstance(modalRef.current);
    if (bsModal) bsModal.hide();
  };

  const getBankByCurrency = async (e) => {
    const currency = e.target.value;
    const apiUrl = `${config.api_url}/ws-bank-by-currency`;

    var formData = new FormData();
    formData.append("currency", currency);

    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      const data = response.data;
      setBankData(data.bank);
    } catch (error) {
      console.error("Error fetching bank data:", error);
    }
  };




  return (
    <div className={`modal fade ${show ? 'show' : ''}`} id="add-customer" tabIndex="-1" ref={modalRef} style={{ display: show ? 'block' : 'none' }}>
      <form id="add_customer_form" onSubmit={handleFormSubmit}>
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add New Customer</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={() => {
                  onHide(); // Call the onHide function
                  setShowCreditFields(false); // Set show credit fields to false
                  $('#credit_facility').prop('checked', false);
                }}
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              {/* Customer Details */}
              <div className="add-product-popup">
                <h4>Customer Details</h4>
                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="desc" className="col-sm-3 col-form-label">
                    Company Name <span className="red">*</span>
                  </label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="name" id="name" />
                  </div>
                  <label htmlFor="compType" className="col-sm-3 col-form-label">
                    Contact Person Name <span className="red">*</span>
                  </label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="contact_person_name" id="contact_person_name" />
                  </div>
                </div>
                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="displayName" className="col-sm-3 col-form-label">
                    Contact Email
                  </label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="contact_person_email" id="contact_person_email" />
                  </div>
                  <label htmlFor="displayName" className="col-sm-3 col-form-label">
                    Mobile
                  </label>
                  <div className="col-sm-3">
                    <input maxLength={10} type="text" className="form-control" name="contact_person_mobile" id="contact_person_mobile" />
                  </div>
                </div>
                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="displayName" className="col-sm-3 col-form-label">
                    Currency <span className="red">*</span>
                  </label>
                  <div className="col-sm-3">
                    <select
                      onChange={getBankByCurrency}
                      name="currency"
                      className="form-select orgselect"
                      required=""
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Select Currency
                      </option>
                      <option value="GBP">GBP</option>
                      <option value="USD">USD</option>
                      <option value="EUR">EUR</option>
                    </select>
                  </div>
                  <label htmlFor="displayName" className="col-sm-3 col-form-label">
                    Country <span className="red">*</span>
                  </label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="country" id="country" />
                  </div>
                </div>
                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="bank" className="col-sm-3 col-form-label">
                    Bank <span className="red">*</span>
                  </label>
                  <div className="col-sm-3">
                    <select name="bank" id="bankAdd" className="form-select orgselect" required defaultValue="">
                      <option value="" disabled>
                        Select Bank
                      </option>
                      {bankData.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.bank_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <label htmlFor="displayName" className="col-sm-3 col-form-label">
                    VAT
                  </label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="vat" id="vat" />
                  </div>
                </div>
                <div className="row mb-3 padd-l-13px">
                  <label htmlFor="displayName" className="col-sm-3 col-form-label">
                    Status
                  </label>
                  <div className="col-sm-3">
                    <label className="switch">
                      <input className="switch-input" type="checkbox" name="status" defaultChecked />
                      <span className="switch-label" data-on="Active" data-off="Inactive"></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>

                  <div className="col-sm-3">
                    <div className="radio-tbl">
                      <label className="checkbox">
                        <input type="checkbox" name="credit_facility" id="credit_facility" onChange={handleCreditFacilityChange} />
                        <span className="default"></span>
                      </label>
                      <span>Credit Facility</span>
                    </div>
                  </div>

                  <div className="col-sm-3">
                  {showCreditFields && (
                    <div className="col-sm-12">
                      <input type="text" className="form-control" name="credit_limit" placeholder="Credit Limit Amount" id="credit_limit" />
                    </div>
                  )}

                {showCreditFields && (
                  <div className="col-sm-12">
                    <input type="text" className="form-control" name="credit_limit_daywise" id="credit_limit_daywise" placeholder="Credit Limit Day wise" />
                  </div>
                )}

                </div>

                </div>

                
             
              {/* Invoice Address */}
              <h4>Invoice Address</h4>
              <div className="row mb-3 padd-l-13px">
                <label htmlFor="displayName" className="col-sm-3 col-form-label">
                  Address Line 1 <span className="red">*</span>
                </label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" name="b_address1" id="b_address1" />
                </div>
                <label htmlFor="displayName" className="col-sm-3 col-form-label">
                  Address Line 2
                </label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" name="b_address2" id="b_address2" />
                </div>
              </div>
              <div className="row mb-3 padd-l-13px">
                <label htmlFor="displayName" className="col-sm-3 col-form-label">
                  City <span className="red">*</span>
                </label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" name="b_city" id="b_city" />
                </div>
                <label htmlFor="displayName" className="col-sm-3 col-form-label">
                  County
                </label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" name="b_county" id="b_county" />
                </div>
              </div>
              <div className="row mb-3 padd-l-13px">
                <label htmlFor="displayName" className="col-sm-3 col-form-label">
                  Country <span className="red">*</span>
                </label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" name="b_country" id="b_country" />
                </div>
                <label htmlFor="displayName" className="col-sm-3 col-form-label">
                  Post Code <span className="red">*</span>
                </label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" name="b_postcode" id="b_postcode" />
                </div>
              </div>

              {/* Delivery Address Section */}
              <div>
                    <div id="deliveryAddress">
                      <div className="address-block" key="0">
                        <h4>
                          Delivery Address{" "}
                          <div style={{ float: "right" }}>
                            <input
                              type="radio"
                              name="defaultDelivery[]"
                              className="checkDefault"
                              value={"0"}
                              id="defaultDeliveryAdr"
                              defaultChecked
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`gridRadios0`}
                            >
                              {" "}
                              Default{" "}
                            </label>
                          </div>
                        </h4>
                        <div className="row mb-3 padd-l-13px">
                          <label
                            htmlFor={`d_addr1_0`}
                            className="col-sm-3 col-form-label"
                          >
                            Address Line 1 <span className="red">*</span>
                          </label>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              name="d_addr1[]"
                              id={`d_addr1_0`}
                            />
                          </div>
                          <label
                            htmlFor={`d_addr2_0`}
                            className="col-sm-3 col-form-label"
                          >
                            Address Line 2
                          </label>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              name="d_addr2[]"
                              id={`d_addr2_0`}
                            />
                          </div>
                        </div>
                        <div className="row mb-3 padd-l-13px">
                          <label
                            htmlFor={`d_city_0`}
                            className="col-sm-3 col-form-label"
                          >
                            City <span className="red">*</span>
                          </label>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              name="d_city[]"
                              id={`d_city_0`}
                            />
                          </div>
                          <label
                            htmlFor={`d_county_0`}
                            className="col-sm-3 col-form-label"
                          >
                            County
                          </label>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              name="d_county[]"
                              id={`d_county_0`}
                            />
                          </div>
                        </div>
                        <div className="row mb-3 padd-l-13px">
                          <label
                            htmlFor={`d_country_0`}
                            className="col-sm-3 col-form-label"
                          >
                            Country <span className="red">*</span>
                          </label>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              name="d_country[]"
                              id={`d_country_0`}
                            />
                          </div>
                          <label
                            htmlFor={`d_postcode_0`}
                            className="col-sm-3 col-form-label"
                          >
                            Post Code <span className="red">*</span>
                          </label>
                          <div className="col-sm-3">
                            <input
                              type="text"
                              className="form-control"
                              name="d_postcode[]"
                              id={`d_postcode_0`}
                            />
                          </div>
                        </div>
                      </div>

                      {deliveryAddresses.map((address, index) => (
                        <div className="address-block" key={index + 1}>
                          <h4>
                            Delivery Address{" "}
                            <div style={{ float: "right" }}>
                              <input
                                type="radio"
                                className="checkDefault"
                                name="defaultDelivery[]"
                                value={index + 1}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`gridRadios${index + 1}`}
                              >
                                {" "}
                                Default{" "}
                              </label>
                              <Link to="#" onClick={() => removeAddress(index)}>
                                <i className="bi bi-x"></i>
                              </Link>
                            </div>
                          </h4>
                          <div className="row mb-3 padd-l-13px">
                            <label
                              htmlFor={`d_addr1_${index + 1}`}
                              className="col-sm-3 col-form-label"
                            >
                              Address Line 1 <span className="red">*</span>
                            </label>
                            <div className="col-sm-3">
                              <input
                                type="text"
                                className="form-control"
                                name="d_addr1[]"
                                id={`d_addr1_${index + 1}`}
                              />
                            </div>
                            <label
                              htmlFor={`d_addr2_${index + 1}`}
                              className="col-sm-3 col-form-label"
                            >
                              Address Line 2
                            </label>
                            <div className="col-sm-3">
                              <input
                                type="text"
                                className="form-control"
                                name="d_addr2[]"
                                id={`d_addr2_${index + 1}`}
                              />
                            </div>
                          </div>
                          <div className="row mb-3 padd-l-13px">
                            <label
                              htmlFor={`d_city_${index + 1}`}
                              className="col-sm-3 col-form-label"
                            >
                              City <span className="red">*</span>
                            </label>
                            <div className="col-sm-3">
                              <input
                                type="text"
                                className="form-control"
                                name="d_city[]"
                                id={`d_city_${index + 1}`}
                              />
                            </div>
                            <label
                              htmlFor={`d_county_${index + 1}`}
                              className="col-sm-3 col-form-label"
                            >
                              County
                            </label>
                            <div className="col-sm-3">
                              <input
                                type="text"
                                className="form-control"
                                name="d_county[]"
                                id={`d_county_${index + 1}`}
                              />
                            </div>
                          </div>
                          <div className="row mb-3 padd-l-13px">
                            <label
                              htmlFor={`d_country_${index + 1}`}
                              className="col-sm-3 col-form-label"
                            >
                              Country <span className="red">*</span>
                            </label>
                            <div className="col-sm-3">
                              <input
                                type="text"
                                className="form-control"
                                name="d_country[]"
                                id={`d_country_${index + 1}`}
                              />
                            </div>
                            <label
                              htmlFor={`d_postcode_${index + 1}`}
                              className="col-sm-3 col-form-label"
                            >
                              Post Code <span className="red">*</span>

                            </label>
                            <div className="col-sm-3">
                              <input
                                type="text"
                                className="form-control"
                                name="d_postcode[]"
                                id={`d_postcode_${index + 1}`}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-12">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm add-adressbtn"
                          onClick={addNewAddress}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i> Add
                          Delivery Address
                        </button>
                      </div>
                    </div>
                  </div>
                  </div>
              <hr />
              {/* Submit Button */}
              <div className="row padd-l-13px">
                <div className="col-sm-12">
                  <Link to="#" className="popup-innerbtn-common right" id="addCustomerForm" onClick={handleFormSubmit}>
                    Save
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCustomerModal;
