import React, { useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../Common_Files/Config";

const AddCategoryModal = ({ initializeDataTable }) => {
  const modalRef = useRef(null);

  // Validation schema using Yup
  const validationSchema = Yup.object({
    name: Yup.string().required("Category Name is required"),
    status: Yup.boolean(),
  });

  // Function to handle form submission
  const handleAddCategory = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("status", values.status ? 1 : 0);
      const response = await axios.post(`${config.api_url}/ws-add-category`, formData);
      // Hide the modal
      hideModal();
      if (response.data.success == true) 
        {
        Swal.fire({
          title: "Success!",
          text: "Category added successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });

        initializeDataTable(); 
        resetForm(); // Reset the form
      } 
      else 
      {
        showError("An error occurred while adding the category.");
      }
    } catch (error) {
      console.error("Request Error:", error);
      showError("An error occurred while adding the category.");
    }
  };

  const hideModal = () => {
    if (modalRef.current) {
      const bsModal = window.bootstrap.Modal.getInstance(modalRef.current);
      if (bsModal) bsModal.hide();
    }
  };

  const showError = (message) => {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      timer: 1500,
      showConfirmButton: false,
    });
  };

  return (
    <div
      className="modal fade"
      id="add-category"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref={modalRef}
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add New Category</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <Formik
            initialValues={{ name: "", status: true }}
            validationSchema={validationSchema}
            onSubmit={handleAddCategory}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="modal-body">
                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="name" className="col-sm-6 col-form-label">
                      Product Category Name <span className="red">*</span>
                    </label>
                    <div className="col-sm-6">
                      <Field
                        type="text"
                        className="form-control"
                        name="name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="row mb-3 padd-l-13px">
                    <label htmlFor="status" className="col-sm-6 col-form-label">
                      Status
                    </label>
                    <div className="col-sm-6">
                      <label className="switch">
                        <Field
                          type="checkbox"
                          className="switch-input"
                          name="status"
                        />
                        <span
                          className="switch-label"
                          data-on="Active"
                          data-off="Inactive"
                        ></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="row padd-l-13px">
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="popup-innerbtn-common right"
                        disabled={isSubmitting}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddCategoryModal;
