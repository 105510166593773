import axios from 'axios';
import config from '../components/Common_Files/Config';
import $ from "jquery";
import { useEffect } from 'react';
import Swal from 'sweetalert2';

const accessToken = localStorage.getItem('accessToken');

export const fetchSupplierData = async (setSupplierData) => {
  const apiUrl = `${config.api_url}/ws-get-suppliers`;
  
  try {
    const response = await axios.post(apiUrl, null, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    
    const data = response.data;
    setSupplierData(data.supplier); 
  } catch (error) {
    console.error("Error fetching suppliers data:", error);
  }
};

export const fetchWarehouseDeliveryData = async (setWarehouseDeliveryAddress, accessToken) => {
  const apiUrl = `${config.api_url}/ws-get-warehouse`;
  
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    }); 
    
    const data = response.data.warehouse;
    setWarehouseDeliveryAddress(data);
  } catch (error) {
    console.error("Error fetching warehouse data:", error);
  }
};

export const fetchCustomerDeliveryData = async (setCustomerDeliveryAddress, accessToken) => {
  const apiUrl = `${config.api_url}/ws-get-delivery-address`;
  
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    }); 
    
    const data = response.data.customer;
    setCustomerDeliveryAddress(data);
  } catch (error) {
    console.error("Error fetching customer data:", error);
  }
};

export const changeWarehouseAddress = (e, setDeliveryAddress, warehouseDeliveryAddress) => {
  let id = e.target.value;
 
  if(id == ''){
       setDeliveryAddress({
        name : '',
        code : '',
        address_line1 : '',
        address_line2 : '',
        city : '',
        state : '',
        country : '',
        postcode : '',

        });
       
  }else{
   
    const warehouse = warehouseDeliveryAddress.find(w => w.id === id);
      setDeliveryAddress(warehouse);
   
  }
}

export const changeCustomerAddress = (e, setCustomerAddress, customerDeliveryAddress) => {
  let id = e.target.value;
    if(id == ''){
       setCustomerAddress({
        name : '',
        code : '',
        address_line1 : '',
        address_line2 : '',
        city : '',
        state : '',
        country : '',
        postcode : '',

        });
  }else{
  const customer = customerDeliveryAddress.find(w => w.id === id);
  setCustomerAddress(customer);
}
}



export const fetchProductData = async (setProductData) => {
  const apiUrl = `${config.api_url}/ws-get-products`;
  try {
    const response = await axios.post(apiUrl, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      },
    });

    if (Array.isArray(response.data.products)) {
      const formattedOptions = response.data.products.map(item => ({
        label: `${item.code}-${item.product_desc}-${item.pack}-${item.size}-${item.quantity}`,
        value: item.id,
      }));
      setProductData(formattedOptions);
    //  setProductData(data.product);
    }   
  } catch (error) {
    console.error("Error fetching products data:", error);
  }
};


export const handleChange1 = async (selectedOption, tableRows, setTableRows) => 
{
  const id = selectedOption;

  console.log('handleChange called with ID:', id);

    // Check if the product already exists
    const found = tableRows.find(element => element.id === id);
    if (found) {
      Swal.fire({
        title: "Error!",
        text: "Product already exists",
        icon: "error",
        timer: 1500, 
        showConfirmButton: false,
      })
        $('#productList').val(''); // Clear input
        return;
      }

    // Clear input
    $('#productList').val('');

    // Prepare form data
    const formData = new FormData();
    formData.append("id", id);

    // API URL
    const apiUrl = `${config.api_url}/ws-get-products-by-id`;

    try {
        // Fetch product data
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        });

        // Parse response
        const data = await response.json();    
       


        // Create new row
        const newRow = {
          id: data.products[0].id,
          code: data.products[0].code,
          product_desc: data.products[0].product_desc,
          pack: data.products[0].pack,
          size: data.products[0].size,
          price: data.products[0].price,
          cost: data.products[0].cost,
          sys_quantity: data.products[0].quantity,
          free_stock: data.freestock,        
          free_stock_cal: data.freestockcal,          
          //sale_qty:(data.sale && data.sale.length > 0) ? data.sale[0].sale_qty : 0,
          sale_qty:(data.onso !== undefined && data.onso !== null) ? parseInt(data.onso): 0,   
          quantity: 0,
          taxrate: data.products[0].rate,
          vat: parseFloat(data.products[0].vat),
          value: 0, 
          netp: 0,
          retro: 0,
          promptPayment: 0,
          costToServe: 0,
          offInvoice: 0,
          bedsLeds: 0,
          sugroRetro: 0,
          netprice : 0,
          sales_item_id : 0,
          fstk:(data.freestock !== undefined && data.freestock !== null) ? parseInt(data.freestock) + parseInt(data.products[0].quantity) : 0,   
        };


       console.log('gggg'+newRow);

        // Update table rows
        setTableRows(prevRows => [...prevRows, newRow]);

      } catch (error) {
        console.error("Error fetching products data:", error);
      }
    };


    export const edithandleChange1 = async (selectedOption, grnItems, setItems) => 
    {
      const id = selectedOption;

      console.log('handleChange called with ID:', id);

        // Check if the product already exists
        const found = grnItems.find(element => element.id === id);
        if (found) {
          Swal.fire({
            title: "Error!",
            text: "Product already exists",
            icon: "error",
            timer: 1500, 
            showConfirmButton: false,
          })
            $('#productList').val(''); // Clear input
            return;
          }

        // Clear input
        $('#productList').val('');

        // Prepare form data
        const formData = new FormData();
        formData.append("id", id);

        // API URL
        const apiUrl = `${config.api_url}/ws-get-products-by-id`;

        try {
            // Fetch product data
            const response = await fetch(apiUrl, {
              method: "POST",
              headers: {
                authorization: `Bearer ${accessToken}`,
              },
              body: formData,
            });

            // Parse response
            const data = await response.json();    
            console.log(data);


            // Create new row
            const newRow = {
              id: data.products[0].id,
              product_id: data.products[0].id,
              code: data.products[0].code,
              product_desc: data.products[0].product_desc,
              pack: data.products[0].pack,
              size: data.products[0].size,
              price: data.products[0].price,
              cost: data.products[0].cost,
              sys_quantity: data.products[0].quantity,
              free_stock: data.freestock,        
              free_stock_cal: data.freestockcal,          
              sale_qty:(data.sale && data.sale.length > 0) ? data.sale[0].sale_qty : 0,
              quantity: 0,
            // order_qty: 0,
              vat_percentage:parseFloat(data.products[0].vat),
              vat: parseFloat(data.products[0].vat),
              value: 0, 
              netp: data.products[0].cost,
              retro: 0,
              promptPayment: 0,
              costToServe: 0,
              offInvoice: 0,
              bedsLeds: 0,
              sugroRetro: 0,
              netprice : 0,
              fstk : 0,
              sales_item_id : 0,
            };


            console.log(newRow);

            // Update table rows
            setItems(prevRows => [...prevRows, newRow]);

          } catch (error) {
            console.error("Error fetching products data:", error);
          }
        };
 

    export const handleInputChange = (e, id, field, setTableRows) => {
      const value = acceptNumber(e.target.value);
      setTableRows((prevRows) =>
        prevRows.map((row) =>
          row.id === id
            ? {
                ...row,
                [field]: value,
                value: field === 'quantity' ? calculateValue(value, row.cost) : calculateValue(row.quantity, value),
                netprice: (field === 'quantity' 
                  ? row.cost - (row.retro + row.promptPayment + row.costToServe + row.offInvoice + row.bedsLeds + row.sugroRetro)
                  : row.cost - (row.retro + row.promptPayment + row.costToServe + row.offInvoice + row.bedsLeds + row.sugroRetro)),
                    netp: (field === 'quantity' ? calculateValue(value, row.cost)  : calculateValue(row.quantity, value)),
                fstk :(Number(row.sys_quantity) || 0) + (Number(row.free_stock) || 0) +  (Number(value) || 0),
              }
            : row
        )
      );
    };

      export const handleInputChange_cprice = (e, id, field, setTableRows) => {
        const value1 = acceptDecimalNumber(e.target.value); 
        setTableRows((prevRows) =>
          prevRows.map((row) =>
            row.id === id
              ? {
                  ...row,
                  [field]: value1, // Update the specific field with the new value
                  value: field === 'quantity' ? calculateValue(value1, row.quantity) : calculateValue(row.quantity, value1),
                  netprice: (field === 'quantity' 
                        ? value1 - (row.retro + row.promptPayment + row.costToServe + row.offInvoice + row.bedsLeds + row.sugroRetro)
                        : value1 - (row.retro + row.promptPayment + row.costToServe + row.offInvoice + row.bedsLeds + row.sugroRetro)),
                  netp: (field === 'quantity' ? calculateValue(row.quantity, value1)  : calculateValue(row.quantity, value1)),
                  fstk: (Number(row.sys_quantity) || 0) + (Number(row.free_stock) || 0) + (Number(value1) || 0),
                  cost: value1, // Set cost to the input value
                }
              : row
          )
        );
      };


      export const handleInputChangeInSales = (e, id, field, setTableRows) => {
        const value = acceptNumber(e.target.value);
        if(field === 'cost') {
          setTableRows((prevRows) =>
            prevRows.map((row) =>
              row.id === id ? { ...row, 
                [field]: value
              } : row
              )
            );
        }
        else{
          setTableRows((prevRows) =>
            prevRows.map((row) =>
              row.id === id
                ? {
                    ...row,
                    [field]: value,
                    value: field === 'quantity' ? calculateValue(value, row.cost) : calculateValue(row.quantity, value),
                    netp:
                      (field === 'quantity'
                        ? calculateValue(value, row.cost)
                        : calculateValue(row.quantity, value)) -
                      (row.retro + row.promptPayment + row.costToServe + row.offInvoice + row.bedsLeds + row.sugroRetro),
                  }
                : row
              )
            );
          }
        };

    export const handleInputChangeInSalesNew = (e, id, field, setTableRows) => {
      let value = 0;
      if (field == "pdtqty") {
        let v = acceptNumber(e.target.value);     
        value = Math.floor(v); // Making sure it's an integer
      } else if (field == "price" || field == "cost"){
        value = acceptDecimalNumber(e.target.value); 
      }


      setTableRows((prevRows) =>
          prevRows.map((row) => {


              if (row.id === id) {
                  // Update the row with the new value for the changed field
                  const updatedRow = {
                      ...row,
                      [field]: value,


                  };

                  // Calculate value and profit based on the latest inputs
                  const qty = Math.floor(updatedRow.pdtqty || 0); // Default to 0 if undefined
                  const price = updatedRow.price || 0; // Default to 0 if undefined
                  const cost = updatedRow.cost || 0; // Default to 0 if undefined
                  const free_stock = updatedRow.freestock || 0; 
                  // console.log(free_stock);
                
                  updatedRow.freestockcal = free_stock-qty;            
                  updatedRow.value = qty * price; // value = qty * price
                  updatedRow.profit = updatedRow.value - (qty * cost); // profit = value - (qty * cost)

                  return updatedRow; // Return the updated row
              }
              return row; // Return the unmodified row
          })
      );
  }


    export const handleInputChangeIneditSales = (e, id, field, setTableRows) => {
      
      let value = 0;
      if (field == "pdtqty") {
          value =  acceptNumber(e.target.value);
      } else if (field == "price" || field == "cost"){
        value = acceptDecimalNumber(e.target.value);
      }

      setTableRows((prevRows) =>
          prevRows.map((row) => {
              if (row.id === id) {
                  const updatedRow = {
                      ...row,
                      [field]: value,
                  };

                  // Calculate value and profit based on the latest inputs
                  const qty = Math.floor(updatedRow.pdtqty || 0); // Default to 0 if undefined
                  const price = updatedRow.price || 0; // Default to 0 if undefined
                  const cost = updatedRow.cost || 0; // Default to 0 if undefined
                  const free_stock = updatedRow.freestock || 0; 
                  // console.log(free_stock);
                
                  updatedRow.freestockcal = free_stock-qty;               
                  updatedRow.value = qty * price; // value = qty * price
                  updatedRow.profit = updatedRow.value - (qty * cost); // profit = value - (qty * cost)

                  return updatedRow; // Return the updated row
              }
              return row; // Return the unmodified row
          })
      );
  }



  

  export const handleInputChangeInPOSalesNew = (e, id, field, setTableRows) => {
     
      let value = e.target.value;

     
      if (field == "pdtqty") {
          
          value = Math.floor(value); // Making sure it's an integer
      } else if (field == "price" || field == "cost"){
          value = parseFloat(value);


      }


      setTableRows((prevRows) =>
          prevRows.map((row) => {
              if (row.id === id) {
                  // Update the row with the new value for the changed field
                  const updatedRow = {
                      ...row,
                      [field]: value,
                  };

                  // Calculate value and profit based on the latest inputs
                  const qty = Math.floor(updatedRow.pdtqty || 0); // Default to 0 if undefined
                  const price = updatedRow.price || 0; // Default to 0 if undefined
                  const cost = updatedRow.cost || 0; // Default to 0 if undefined
                  const discount = (row.retro || 0) + (row.promptPayment || 0) + (row.costToServe || 0) + (row.offInvoice || 0) + (row.bedsLeds || 0) + (row.sugroRetro || 0);
                  console.log(discount);
                  updatedRow.value = qty * cost; 
                  if (discount == null || discount === '' || discount === 0) {
                      updatedRow.netp = cost; 
                  } else {
                      updatedRow.netp =  cost - discount; 
                  }

                  updatedRow.profit = updatedRow.value - (qty * cost); // profit = value - (qty * cost)

                  return updatedRow; // Return the updated row
              }
              return row; // Return the unmodified row
          })
      );
  }
      

  export const calculateGrandTotal = (rows) => {
    return rows.reduce((total, row) => total + (row.value || 0), 0);
  };


  export const removeRow = (id, setTableRows) => {
    setTableRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };

  export const calculateValue = (quantity, price) => {   
    return quantity && price ? quantity * price : 0;
  };

  export const acceptNumber = (value) => {
    return value.replace(/[^0-9]/g, "");
  };

  export const acceptDecimalNumber = (value) => {
    // First, remove all non-numeric characters and keep only one decimal point
    let result = value.replace(/[^0-9.]/g, ""); 
  
    // Ensure only one decimal point
    if ((result.match(/\./g) || []).length > 1) {
      result = result.replace(/\.(?=.*\.)/g, ''); // Remove extra decimal points
    }
  
    return result;
  };
  

  export const getProductBatchData = async (id, setProductBatch) => {
    try {
      // id = 12; 
      const formData = new FormData();
      formData.set('product_id', id);

      const response = await axios.post(
        `${config.api_url}/ws-batch-info-by-product-id`, 
        formData, 
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data;
      setProductBatch(data.batch); 
    } catch (error) {
      console.error("Error fetching product batch data:", error);
    }
  };


