import React, { useState, useEffect } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import { DatePicker } from "rsuite";
import * as echarts from 'echarts';

const Account_Dashboard = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [statisticsData, setStatisticsData] = useState(null);
    const accessToken = localStorage.getItem('accessToken');



    return (
        <div>
            <Header />

            <nav class="breadcrumbs">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-lg-12 col-sm-12">
                            <a href="/Dashboard"><i class="fa fa-home"></i> Dashboard</a>
                            <span class="last-crumb">Account Dashboard</span> </div>
                            
                    </div>
                </div>
            </nav>
            <div className="main-container">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                            <div className="show-order-area dashboard">
                                <div className="dashform">





                                    <div className='sopobx'>
                                        <div className='row mb-3'>
                                        <div className="col-12 col-lg-6 col-sm-6">
                                            <div class="account-card">
                                                

                                                <div class="card-body">
                                                    <h3 class="card-title">Purchase Bill</h3>

                                                    <div class="booking">
                                                        <div className='row'>
                                                            <div className='col-6 col-lg-6 col-sm-6'>
                                                                <div className='bookingbx'>
                                                                    <h4>Allocated Bill</h4>
                                                                </div>
                                                            </div>

                                                            <div className='col-6 col-lg-6 col-sm-6'>
                                                                <div className='bookingbx'>
                                                                <h4>Unallocated Bill</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            </div>

                                            <div className="col-12 col-lg-6 col-sm-6">
                                            <div class="account-card">
                                                

                                                <div class="card-body">
                                                    <h3 class="card-title">Payments</h3>

                                                    <div class="booking">
                                                        <div className='row'>
                                                            <div className='col-6 col-lg-6 col-sm-6'>
                                                                <div className='bookingbx'>
                                                                    <p>Total Due</p>
                                                                    <hr />
                                                                    <span>Total No of Inv Due</span>
                                                                </div>
                                                            </div>

                                                            <div className='col-6 col-lg-6 col-sm-6'>
                                                                <div className='bookingbx'>
                                                                <p>Total Recived</p>
                                                                <hr />
                                                                <span>Total No of Inv Recived</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            </div>
                                        </div>

                                        <div className='row mb-3'>
                                        <div className="col-12 col-lg-6 col-sm-6">
                                            <div class="account-card">
                                                

                                                <div class="card-body">
                                                    <h3 class="card-title">Sales Bill </h3>

                                                    <div class="booking">
                                                        <div className='row'>
                                                            <div className='col-6 col-lg-6 col-sm-6'>
                                                                <div className='bookingbx'>
                                                                    <h4>Allocated Bill</h4>
                                                                </div>
                                                            </div>

                                                            <div className='col-6 col-lg-6 col-sm-6'>
                                                                <div className='bookingbx'>
                                                                <h4>Unallocated Bill</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            </div>

                                            <div className="col-12 col-lg-6 col-sm-6">
                                            <div class="account-card">
                                                

                                                <div class="card-body">
                                                    <h3 class="card-title">Outstanding</h3>

                                                    <div class="booking">
                                                        <div className='row'>
                                                            <div className='col-6 col-lg-6 col-sm-6'>
                                                                <div className='bookingbx'>
                                                                    <h4>Total Due</h4>
                                                                </div>
                                                            </div>

                                                            <div className='col-6 col-lg-6 col-sm-6'>
                                                                <div className='bookingbx'>
                                                                <h4>Over Due</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Account_Dashboard;
