import React, { useState, useEffect , useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import ViewProduct from '../Master/ViewProduct';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import ProductBatch from './ProductBatch'; 
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { fetchSupplierData, fetchProductData, removeRow, acceptNumber } from '../../services/NewPurchaseOrderService';
import { fetchGRNProducts, handleChange, handleConfirmGRN, handleInputChange } from '../../services/ConfirnGRNService';

const ConfirmGRN = () => {
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [supplierData, setSupplierData] = useState([]);
  const [preSupplierData, setPreSupplierData] = useState();
  const [productData, setProductData] = useState([]);
  const [grnItems, setGRNItems] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const [purchaseData, setPurchaseData] = useState([]);
  const confirmModal = useRef(null);
  const [productBatch, setProductBatch] = useState([]);
  const [stdGoods, setStdGoods] = useState(0);
  const [vatAmt, setVatAmt] = useState(0);
  const [zeroGoods, setZeroGoods] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const { id } = useParams();
  const navigateToPOHistory = useNavigate();
  const [productInputValue, setProductInputValue] = useState('');
  const [picker, setSelectedProduct] = useState('');
  const accessToken = localStorage.getItem('accessToken');
  const productBatchRef = useRef(null);

  const [modalData, setModalData] = useState(null);
  const handleOpenPastPurchaseModal = (data) => {  
    setModalData(data); 
    if (productBatchRef.current) {
      productBatchRef.current(); 
      
    }
  };

  useEffect(() => {
    fetchSupplierData(setSupplierData);
    fetchProductData(setProductData);
    fetchGRNProducts(id, setGRNItems, setPurchaseData, setBatchData,setStdGoods,setPreSupplierData);
    // console.log(batchData); 
  }, []);

    // Calculate grandTotal whenever tableRows changes
    useEffect(() => {
      const newGrandTotal = grnItems.reduce((total, row) => {
        const cost = parseFloat(row.cost) || 0;
        const received_qty = parseFloat(row.received_qty) || 0;
        return total + (cost * received_qty);
      }, 0);
      
      setGrandTotal(newGrandTotal);
      const totalVat = grnItems.reduce((total, row) => {  
      const cost = parseFloat(row.cost) * parseFloat(row.received_qty) || 0;
      const vat = (cost / 100) * parseFloat(row.vat_percentage);
      console.log('VAT for row:', cost);
      return total + vat;  // Accumulate the VAT
    }, 0);

      
      
      setVatAmt(totalVat);
      const stdGoods = grnItems.reduce((total, row) => {
        if (parseFloat(row.vat) > 0) {
          const cost = parseFloat(row.cost) || 0;
          const received_qty = parseFloat(row.received_qty) || 0;
          return total + (cost * received_qty);
        }
        return total;
      }, 0);

      setStdGoods(stdGoods);

      const zeroGoods = grnItems.reduce((total, row) => {
        if (parseFloat(row.vat) === 0) {
          const cost = row.cost;
          const received_qty = parseFloat(row.received_qty) || 0;
          return total + (cost * received_qty);
        }
        return total;
      }, 0);
      setZeroGoods(zeroGoods);

    }, [grnItems]);


    const getProductBatchData = async (id) => {
      try {
        const formData = new FormData();
        formData.set('product_id', id);

        const response = await axios.post(
          `${config.api_url}/ws-batch-info-by-product-id`, 
          formData, 
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'multipart/form-data', 
            },
          }
          );

        const data = response.data;
        setProductBatch(data.batch); 
        handleOpenPastPurchaseModal(data.batch);

      } catch (error) {
        console.error("Error fetching product batch data:", error);
      }
    };

    const addExpiryField = (rowIndex) => {
      const newGRNItems = [...grnItems];
      newGRNItems[rowIndex].expiry_dates.push("");
      setGRNItems(newGRNItems);
    };

    const handleProductChange = (inputValue) => {
      setSelectedProduct(inputValue);
    };

    const handleChange_confirm_grn = (selectedOption) => {

      if (selectedOption && selectedOption.value) 
      {
        setProductInputValue(selectedOption);   
        handleChange(selectedOption.value, grnItems, setGRNItems);

        console.log(grnItems);
      } else 
      {
       setProductInputValue(null);   
       console.warn("Selected option is invalid or missing a value");
     }
   };
   
   const addExpiryData = (itemId) => {
    setGRNItems(prevItems =>
      prevItems.map(item => 
        item.id === itemId 
        ? { 
          ...item, 
          expiryData: [...item.expiryData, { expiryDate: "", batchQty: "" }] 
        } 
        : item
        )
      );
  };

  const handleExpiryChange = (e, rowIndex, expiryIndex) => {
    const newGRNItems = [...grnItems];

    newGRNItems[rowIndex].expiry_dates[expiryIndex] = e.target.value;
    setGRNItems(newGRNItems);
  };

  const handleExpiryDataChange = (e, itemId, index, field) => {
    var newValue = '';
    if(field === 'expiryDate'){
      newValue = e.target.value;
    }
    else{
      newValue = acceptNumber(e.target.value);
    }
    // alert(newValue);     
    setGRNItems(prevItems => {
        const updatedItems = prevItems.map(item => 
          item.id === itemId 
          ? {
            ...item,
            expiryData: item.expiryData.map((data, i) => 
              i === index 
              ? { 
                ...data, 
                [field]: newValue,
              } 
              : data
              ) 
          } 
          : item
          );

        // Step 2: Calculate the total batch quantity after the update
        const totalBatchQty = updatedItems.find(item => item.id === itemId).expiryData.reduce((total, data) => total + (parseFloat(data.batchQty) || 0), 0);


        // Step 3: Update the batchQty for the item
        return updatedItems.map(item => 
          item.id === itemId 
          ? { 
            ...item, 
                batchQty: totalBatchQty, // Set the total batch quantity
                received_qty: totalBatchQty
              } 
              : item
              );
      });
  };

  useEffect(() => {

  }, [grnItems]);



  const removeExpiryData = (itemId, index) => {
    setGRNItems(prevItems => {
        // Step 1: Filter out the expired data entry
        const updatedItems = prevItems.map(item => 
          item.id === itemId 
          ? { 
            ...item, 
            expiryData: item.expiryData.filter((_, i) => i !== index) 
          } 
          : item
          );

        // Step 2: Calculate the total batch quantity after the removal
        const totalBatchQty = updatedItems.find(item => item.id === itemId)?.expiryData.reduce((total, data) => total + (parseFloat(data.batchQty) || 0), 0) || 0;

        // Step 3: Update the batchQty for the item
        return updatedItems.map(item => 
          item.id === itemId 
          ? { 
            ...item, 
                batchQty: totalBatchQty // Set the total batch quantity
              } 
              : item
              );
      });
  };

  useEffect(() => {
    $('#dsTable tbody').off('click', '.applybtn');
    $('#dsTable tbody').on('click', '.applybtn', function () 
    {
      $('#freestock').empty();
      $('#stock').empty();
      $('#on_so').empty();
      $('#allocation').empty();
      $('#onpf').empty();
      $('#onpo').empty();
      const product_id = $(this).data('id');
      const formData = new FormData();
      formData.append('id', product_id);
      document.getElementById('viewProductForm').reset();
      const fetchProductData = async () => {
        const apiUrl = `${config.api_url}/ws-get-products-by-id`;
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
            body: formData,
          });

          const data = await response.json();
          $('#code').val(data.products[0].product_desc);
          $('#packValue').val(data.products[0].pack);
          $('#vat').val(data.products[0].vat);
          $('#weight').val(data.products[0].gross_weight);
          $('#supplier').val(data.products[0].supplier_name);
          $('#description').val(data.products[0].product_desc);
          $('#sizeValue').val(data.products[0].size);
          $('#barcode').val(data.products[0].unit_barcode);
          $('#view_case_barcode').val(data.products[0].case_barcode);
          $('#pallet_qty').val(data.products[0].pallet_qty);
          $('#case_price').val(data.products[0].price);
          $("#product_status").html(data.products[0].status != 0
            ? 'Status : <span style="margin-left: 5px;" class="badge bg-success">Active</span>'
            : 'Status : <span style="margin-left: 5px;" class="badge bg-secondary"> Inactive</span>'
          );
          $('#viewcbm').val(data.products[0].cbm);
          $('#brandname').val(data.products[0].brand);
          $('#categooryName').val(data.products[0].category);
          $('#editnetWeight').val(data.products[0].net_weight);
          $('#editgrossWeight').val(data.products[0].gross_weight);
          $('#freestock').append(data.freestock == null ? 0 : data.freestock);
          $('#stock').append(parseInt(data.products[0].quantity));
          $('#on_so').append(data.onso == null ? 0 : data.onso);
          $('#allocation').append(data.allocation == null ? 0 : data.allocation);
          $('#onpf').append(data.pf == null ? 0 : data.pf);
          $('#onpo').append(data.on_purchase == null ? 0 : data.on_purchase);
        } 
        catch (error) 
        {
          console.error("Error fetching product data:", error);
        }
      };
      fetchProductData();
    });

    return () => {
      $('#dsTable tbody').off('click', '.applybtn');
    };
  }, []);




  return (
    <div>
    <div className="modal fade" id="crn-add-product" tabIndex="-1">
    <div className="modal-dialog modal-dialog-centered modal-lg">
    <div className="modal-content">
    <div className="modal-header">
    <h5 className="modal-title">&nbsp;</h5>
    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div className="modal-body">
    <div className="partialbox">
    <div className="row mb-3">
    <label  htmlFor="inputText" className="col-sm-6 col-form-label">Search (Code/Name/Unit Barcode)</label>
    <div className="col-sm-6">
    <input type="text" className="search-area" placeholder="Search" autoComplete="off" value="" />
    </div>
    </div>
    <table className="table table-bordered dataTable">
    <thead>
    <tr>
    <th>Code</th>
    <th>Name</th>
    <th>PK</th>
    <th>Size</th>
    <th>Unit Barcode</th>
    <th>Stock</th>
    <th>F.STK</th>
    </tr>
    </thead>
    <tbody>
    <tr>
    <td><a href="#" target="_blank">SKI010</a></td>
    <td>Skittles Crazy Sours</td>
    <td>36</td>
    <td>45GM</td>
    <td>&nbsp;</td>
    <td>324</td>
    <td>176</td>
    </tr>
    <tr>
    <td><a href="#" target="_blank">SKI010</a></td>
    <td>Skittles Crazy Sours</td>
    <td>36</td>
    <td>45GM</td>
    <td>&nbsp;</td>
    <td>324</td>
    <td>176</td>
    </tr>
    <tr>
    <td><a href="#" target="_blank">SKI010</a></td>
    <td>Skittles Crazy Sours</td>
    <td>36</td>
    <td>45GM</td>
    <td>&nbsp;</td>
    <td>324</td>
    <td>176</td>
    </tr>
    <tr>
    <td><a href="#" target="_blank">SKI010</a></td>
    <td>Skittles Crazy Sours</td>
    <td>36</td>
    <td>45GM</td>
    <td>&nbsp;</td>
    <td>324</td>
    <td>176</td>
    </tr>
    </tbody>
    </table>
    </div>
    </div>
    </div>
    </div>
    </div>

    <div className="modal fade" id="savepartial" tabIndex="-1" ref={confirmModal} >
    <div className="modal-dialog modal-md">
    <div className="modal-content">
    <div className="modal-header">
    <h5 className="modal-title">&nbsp;</h5>
    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div className="modal-body">
    <div className="add-product-popup">
    <h3>Save Partial GRN?</h3>
    <hr />
    <a href="#" onClick={(e) => handleConfirmGRN(e, 'Partial', confirmModal,navigateToPOHistory)} className="popup-innerbtn-common">Save Partial</a> 
    <a href="#" onClick={(e) => handleConfirmGRN(e, 'Close', confirmModal,navigateToPOHistory)} className="popup-innerbtn-closebtn">Close GRN</a> 
    </div>
    </div>
    </div>
    </div>
    </div>

    <Header />
    <ViewProduct />
    <div className="breadcrumbs">
    <div className="container-fluid">
    <div className="row">
    <div className="col-12 col-lg-12 col-sm-12"> <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="dashboard">Dashboard</a> &gt; <a href="/GRN">GRN</a> &gt; <span className="last-crumb">Confirm GRN</span> </div>
    </div>
    </div>
    </div>
    <div className="main-container">
    <form id='confirm_grn'>
    <div className="container-fluid">
    <div className="row">
    <div className="col-12 col-lg-12 col-sm-12">
    <div className="dashboard">
    <div className="">
    <div className="dashform">
    <div className="topsearch-area">
    <div className="row">
    <div className="col-12 col-lg-3 col-sm-3">
    <div className="row">
    <label htmlFor="inputText" className="col-sm-4 col-form-label" > Supplier: </label>
    <div className="col-sm-8">
    <select name="supplier_id" className="form-select" value={preSupplierData}  onChange={(e)=>{setPreSupplierData(e.target.value) }} required>
    <option value="">Select Supplier</option>
    {supplierData.map((item, index) => (
      <option key={index} value={item.id}>{item.name}</option>
      ))}
      </select>
      </div>
      </div>
      </div>
      <div className="col-12 col-lg-3 col-sm-3">
      <div className="row">
      </div>  
      </div>
      </div>
      </div>
      </div>
      <ProductBatch onViewPastPurchase={(showModal) => { productBatchRef.current = showModal; }} getProductBatchData={getProductBatchData}
      modalData={modalData} />

      <div className="verical-scrl-wrapper common-table confirm-grn-tbl" id="style-3">
      <table className="table table-bordered dataTable" id="dsTable">
      <thead>
      <tr>
      <th>Code</th>
      <th>Expiry</th>
      <th>Description</th>
      <th>Pk</th>
      <th>Size</th>
      <th>Order Qty</th>
      <th>Received Qty</th>
      <th>GRN</th>
      <th>CurStock</th>
      <th>On S.O</th>
      <th>FStk</th>
      <th>Action</th>
      </tr>
      </thead>
      <tbody>
      {grnItems.map((row, rowIndex) => (
        <tr key={row.id}>
        <td>
        <input type="hidden" name="product_id[]" value={row.product_id} />
        <Link
        to="#"
        onClick={(e) => {
          e.preventDefault();         
          getProductBatchData(row.product_id); 
        }}
        >
        {row.code}
        </Link>
        </td>
        <td>

        {batchData.map((item, index) => (
          row.product_id === item.product_id && ( 
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
            <input
            type="date"
            className="form-control expiryDate"
            value={item.expiry_date}
            onChange={(e) => handleChange(e, index, 'expiryDate')}
            style={{ marginRight: '5px' }}
            />
            <input
            type="text"
            className="form-control batchQty qtyinputbox"
            placeholder="Batch Qty"
            value={item.qty}
            onChange={(e) => handleChange(e, index, 'batchQty')}
            style={{ marginRight: '5px' }}
            />
            </div>
            )
            ))}

            {row.expiryData.map((data, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <input
              type="date"
              className="form-control expiryDate"
              name={`expiry_${row.product_id}[]`}
              value={data.expiryDate}
              onChange={(e) => handleExpiryDataChange(e, row.id, index, 'expiryDate')}
              style={{ marginRight: '5px' }}
              />
              <input
              type="text"
              className="form-control batchQty qtyinputbox"
              name={`batch_qty_${row.product_id}[]`}
              placeholder="Batch Qty"
              value={data.batchQty}
              onChange={(e) => handleExpiryDataChange(e, row.id, index, 'batchQty')}
              style={{ marginRight: '5px' }}
              />
              {index != 0 && (
                <button 
                type="button" 
                onClick={() => removeExpiryData(row.id, index)} 
                style={{ marginRight: '5px', color: 'red', cursor: 'pointer' }}
                >
                &#10005;
                </button>
                )}
                <div className="button_for_addmore">
                <button type="button" onClick={() => addExpiryData(row.id)}>+</button>
                </div>
                </div>
                ))}
                </td>
                <td>
                <p className="cut-text">
                <a
                data-bs-toggle="modal"
                className="applybtn"
                data-id={row.product_id}
                data-bs-target="#viewproduct"
                >
                {row.product_desc}
                </a>
                </p>
                </td>
                <td> {row.pack}</td>
                <td> {row.size}</td>
                <td><input type="hidden" name="order_qty[]" value={row.order_qty} /> {row.order_qty} </td>
                <td><input type="text" readOnly id="received_qty" name="received_qty[]" autoComplete='off' value={row.batchQty}
                className="form-control qtyinputbox" onChange={(e) => handleInputChange(e, row.id, row.grn, "received_qty", setGRNItems, grnItems, row.newlyAdded,row.order_qty)} /></td>
                <td>{row.grn}</td>
                <td><input type="hidden" name="currrent_stock[]" value={row.current_stock} />{row.current_stock}</td>
                <td><input type="hidden" name="onSO[]" value="0" />{row.on_so}</td>
                <input type="hidden" name="price[]" value={row.price} />
                <input type="hidden" name="cost[]" value={row.cost} />
                <input type="hidden" name="grn[]" value={row.grn} />
                <input
                type="hidden"
                name="  []"
                value={row.vat_percentage}
                />
                <td><input type="hidden" name="fstk[]" value="0" />{row.free_stock}</td>
                <td>
                <a href="#" value="Remove" onClick={() => removeRow(row.id, setGRNItems)}>
                <i className="fa fa-trash"></i>
                </a>
                </td>
                </tr>
                ))}
                </tbody>
                </table>
                </div>
               
                <div className="purchaseform">
                <input type="hidden" name="stdGoods" value={stdGoods} />
                <input type="hidden" name="vatAmt" value={vatAmt} />
                <input type="hidden" name="zeroGoods" value={zeroGoods} />
                <input type="hidden" name="grandTotal" value={grandTotal} />
                <input type="hidden" name="warehouse_id" id='warehouse_id'  />
                <input type="hidden" name="purchase_id" id='purchase_id'  />


                <div className="row">
                <div className="col-6 col-lg-6 col-sm-6">



                <div className="row mb-3">
                <div className="col-3 col-lg-3 col-sm-3">
                <label  htmlFor="desc" className="col-form-label">Date of Arrival </label>
                </div>
                <div className="col-3 col-lg-3 col-sm-3">
                <input type="date" className="form-control" name="arrival_date" id="arrival_date"  />
                </div>
                <div className="col-3 col-lg-3 col-sm-3">
                <label  htmlFor="desc" className="col-form-label padd-left-50">Job Ref <span className="red">*</span></label>
                </div>
                <div className="col-3 col-lg-3 col-sm-3">
                <input type="text" className="form-control" name="job_ref" id="job_ref" />
                </div>
                </div>

                <div className="row mb-3">
                <div className="col-6 col-lg-3 col-sm-3">
                <label  htmlFor="desc" className="col-form-label">Notes </label>
                </div>
                <div className="col-6 col-lg-9 col-sm-9">
                <textarea className="form-control" name="notes" id="notes"></textarea>
                </div>
                </div>
                </div>
                <div className="col-6 col-lg-6 col-sm-6">
                <div className="row mb-3">
                <div className="col-12 col-lg-12 col-sm-12">
                {(purchaseData.delivery_type !== 'collection' && purchaseData.is_wharehouse_or_customer !== 'customer' ) && (
                  <label  htmlFor="desc" className="col-form-label" style={{ float: 'left'}}>Delivery Address (of Warehouse)</label>
                  )}
                  </div>
                  </div>

                  {(purchaseData.delivery_type !== 'collection' && purchaseData.is_wharehouse_or_customer !== 'customer' ) && (
                  <div className="row mb-3">
                  <div className="col-3 col-lg-3 col-sm-3">
                  <label htmlFor="warehouse_name" className="col-form-label">Warehouse Name</label>
                  </div>
                  <div className="col-6 col-lg-3 col-sm-3">
                  <input 
                  type="text" 
                  className="form-control"  
                  id="warehouse_name" 
                  disabled
                  />
                  </div>
                  <div className="col-6 col-lg-3 col-sm-3">
                  <label htmlFor="wcode" className="col-form-label padd-left-50">Code:</label>
                  </div>
                  <div className="col-6 col-lg-3 col-sm-3">
                  <input 
                  type="text" 
                  className="form-control"  
                  id="wcode" 
                  disabled
                  />
                  </div>
                  </div>
                  )}



                  <div className="row mb-3">
                  <div className="col-6 col-lg-3 col-sm-3">
                  <label  htmlFor="desc" className="col-form-label">Address Line 1 </label>
                  </div>
                  <div className="col-8 col-lg-9 col-sm-9">
                  <input type="text" className="form-control"  id="addr1" disabled />
                  </div>
                  </div>
                  <div div className="row mb-3">
                  <div className="col-6 col-lg-3 col-sm-3">
                  <label  htmlFor="desc" className="col-form-label">Address Line 2 </label>
                  </div>
                  <div className="col-8 col-lg-9 col-sm-9">
                  <input type="text" className="form-control"  id="addr2" disabled />
                  </div>
                  </div>
                  <div className="row mb-3">
                  <div className="col-6 col-lg-3 col-sm-3">
                  <label  htmlFor="desc" className="col-form-label">City </label>
                  </div>
                  <div className="col-6 col-lg-3 col-sm-3">
                  <input type="text" className="form-control"  id="city" disabled />
                  </div>
                  <div className="col-6 col-lg-3 col-sm-3">
                  <label  htmlFor="desc" className="col-form-label padd-left-50">State </label>
                  </div>
                  <div className="col-6 col-lg-3 col-sm-3">
                  <input type="text" className="form-control"  id="state" disabled />
                  </div>
                  </div>
                  <div className="row mb-3">
                  <div className="col-6 col-lg-3 col-sm-3">
                  <label  htmlFor="desc" className="col-form-label">Country </label>
                  </div>
                  <div className="col-6 col-lg-3 col-sm-3">
                  <input type="text" className="form-control"  id="country" disabled />
                  </div>
                  <div className="col-6 col-lg-3 col-sm-3">
                  <label  htmlFor="desc" className="col-form-label padd-left-50">Post Code </label>
                  </div>
                  <div className="col-6 col-lg-3 col-sm-3">
                  <input type="text" className="form-control"  id="postcode" disabled />
                  </div>
                  </div>
                  </div>
                  <div className="col-6 col-lg-12 col-sm-12">
                  <hr />
                  </div>
                  <div className="col-6 col-lg-12 col-sm-12"><a href="#" className="popup-innerbtn-common right" data-bs-toggle="modal" data-bs-target="#savepartial">Confrm GRN</a></div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </form>
                  </div>
                  <Footer />
                  </div>
                  );
                };

                export default ConfirmGRN; 
