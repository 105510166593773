import React, { useState, useEffect, useRef } from "react";
import Header from "../Common_Files/Header";
import Footer from "../Common_Files/Footer";
import config from '../Common_Files/Config';
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';

const ViewSupplier = ({ supplierData, accessToken, onClose }) => {
  const [supplierInfo, setSupplierInfo] = useState({});
// alert('hello');
useEffect(() => {
    $("#supplierTable tbody").off("click", ".viewbtn");
    $("#supplierTable tbody").on("click", ".viewbtn", function () {
      // document.getElementById("viewsupplierForm").reset();
      const supplier_id = $(this).data("id");
      const formData = new FormData();
      formData.append("supplier_id", supplier_id);
      const fetchSupplierData = async () => {
        const apiUrl = `${config.api_url}/ws-supplier-list-by-id`;
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
            body: formData,
          });

          const data = await response.json();
          $("#supplierID").val(data.supplier[0].code);
          $("#contact_person").val(data.supplier[0].contact_person_name);
          $("#vatNum").val(data.supplier[0].vat_id);
          $("#supplier_name").val(data.supplier[0].name);
          $("#emailID").val(data.supplier[0].contact_person_email);
          $("#mobile").val(data.supplier[0].contact_person_mobile);
          $("#addr1").val(data.supplier[0].address_line1);
          $("#addr2").val(data.supplier[0].address_line2);
          $("#view_city").val(data.supplier[0].city);
          // $("#view_state").val(data.supplier[0].price);
          $("#view_country").val(data.supplier[0].country);
          $("#post_code").val(data.supplier[0].postcode);
          $("#view_credit_limit").val(data.supplier[0].credit_limit);
          $("#view_direct_debit").val(data.supplier[0].direct_debit);
          $("#view_currency").val(data.supplier[0].currency_id);
          $("#view_no_of_days_INV").val(data.supplier[0].no_of_days_from_inv);
          $("#view_status").html(data.supplier[0].status 
            ? 'Status : <span style="margin-left: 5px;" class="badge bg-success">Active</span>' 
            : 'Status : <span style="margin-left: 5px;" class="badge bg-secondary"> Inactive</span>'
        );
        } catch (error) {
          console.error("Error fetching supplier data:", error);
        }
      };
      fetchSupplierData();
    });

    return () => {
      $("#supplierTable tbody").off("click", ".viewbtn");
    };
  }, []);


useEffect(() => {
    $("#dsTable tbody").off("click", ".view-supplier");
    $("#dsTable tbody").on("click", ".view-supplier", function () {
      // document.getElementById("viewsupplierForm").reset();
      const supplier_id = $(this).data("id");
      const formData = new FormData();
      formData.append("supplier_id", supplier_id);
      const fetchSupplierData = async () => {
        const apiUrl = `${config.api_url}/ws-supplier-list-by-id`;
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
            body: formData,
          });

          const data = await response.json();
          $("#supplierID").val(data.supplier[0].code);
          $("#contact_person").val(data.supplier[0].contact_person_name);
          $("#vatNum").val(data.supplier[0].vat_id);
          $("#supplier_name").val(data.supplier[0].name);
          $("#emailID").val(data.supplier[0].contact_person_email);
          $("#mobile").val(data.supplier[0].contact_person_mobile);
          $("#addr1").val(data.supplier[0].address_line1);
          $("#addr2").val(data.supplier[0].address_line2);
          $("#view_city").val(data.supplier[0].city);
          $("#view_country").val(data.supplier[0].country);
          $("#post_code").val(data.supplier[0].postcode);
          $("#view_status").html(data.supplier[0].status 
            ? 'Status : <span style="margin-left: 5px;" class="badge bg-success">Active</span>' 
            : 'Status : <span style="margin-left: 5px;" class="badge bg-secondary"> Inactive</span>'
        );

        } catch (error) {
          console.error("Error fetching supplier data:", error);
        }
      };
      fetchSupplierData();
    });

    return () => {
      $("#dsTable tbody").off("click", ".viewbtn");
    };
  }, []);




  return (
     <div className="modal fade" id="viewsupplier" tabIndex="-1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">View Supplier</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="viewcustomer-area">
                <form id="viewSupplierForm">
                <div className="row">
                  <div className="col-6 col-lg-6 col-sm-6">&nbsp;</div>
                  <div className="col-6 col-lg-6 col-sm-6">
                  <div className="row">
                        <label htmlFor="inputText" className="col-sm-6" >&nbsp;</label>
                        <div className="col-sm-6">
                        <h6 id="view_status"></h6>
                        </div>
                      </div>
                    </div>
                    </div>
                    <hr className="devider"/>
                  <div className="row">
                    <div className="col-6 col-lg-6 col-sm-6">
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-6 col-form-label"> Supplier ID </label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" id="supplierID" disabled />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-6 col-form-label" >Contact Person Name</label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" id="contact_person" disabled />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-6 col-form-label" > VAT </label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" name="vatNum" id="vatNum" disabled />
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-sm-6">
                    
                    
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-6 col-form-label" > Supplier Name </label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" id="supplier_name" disabled />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-6 col-form-label" >
                          Email
                        </label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" id="emailID" disabled />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-6 col-form-label"> Mobile </label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" id="mobile" disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-lg-12 col-sm-12">
                      <h3>
                        <span>Supplier's Address </span>
                      </h3>
                    </div>
                    <div className="col-6 col-lg-6 col-sm-6">
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-6 col-form-label" >
                          Address Line 1
                        </label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" id="addr1" disabled />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-6 col-form-label"  >
                          City
                        </label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" id="view_city" disabled />
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-sm-6">
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-6 col-form-label" >
                          Address Line 2
                        </label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" id="addr2" disabled />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          Country
                        </label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" id="view_country" disabled />
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-sm-6">
                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-6 col-form-label"  >
                          Post Code
                        </label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" id="post_code" disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-lg-12 col-sm-12">
                      <h3>
                        <span>Financial</span> <a href="#" className="viewledger">View Ledger</a>
                      </h3>
                    </div>
                    <div className="col-6 col-lg-6 col-sm-6">
                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          Direct Debit
                        </label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" id="view_direct_debit" />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          No. of days from the INV
                        </label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" id="view_no_of_days_INV" />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          Credit Limit
                        </label>
                        <div className="col-sm-6">
                          <input type="text" className="form-control" id="view_credit_limit" />
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 col-sm-6">
                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          Currency
                        </label>
                        <div className="col-sm-6">
                          <input type="text" name="currency" id="view_currency" className="form-control" />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-6 col-form-label"  >
                          New Date
                        </label>
                        <div className="col-sm-6">
                          <input type="date" className="form-control" />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="inputText"
                          className="col-sm-6 col-form-label"
                        >
                          Last Transaction
                        </label>
                        <div className="col-sm-6">
                          <input type="date" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="viewcustomer-tbl-area">
                  <h5>Last 12 Month Turnover</h5>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Aug</th>
                        <th>Sep</th>
                        <th>Oct</th>
                        <th>Nov</th>
                        <th>Dec</th>
                        <th>Jan</th>
                        <th>Feb</th>
                        <th>Mar</th>
                        <th>Apr</th>
                        <th>May</th>
                        <th>Jun</th>
                        <th>Jul</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>T/O</td>
                        <td>13010</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>3244</td>
                        <td>0</td>
                        <td>2343</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


  );
};

export default ViewSupplier;
