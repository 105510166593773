import React, { useState, useEffect } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useParams, useNavigate } from 'react-router-dom';
import Select from 'react-select';
const EditSupplierBill = () => {
  const [options, setOptions] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedPo, setSelectedPo] = useState(null);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [purchaseItem, setPurchaseItem] = useState([]);
  const [discountData, setDiscountData] = useState([]);
  
  const [poDetails, setPoDetails] = useState({

    netAmount: '',
    vatAmount: '',
    totalAmount: ''
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const accessToken = localStorage.getItem('accessToken');

  const [formData, setFormData] = useState({
    deliveryDate: '',
    dueDate: '',
    billNo: '',
    jobRef: '',
    netAmount: '',
    discount: '',
    vat: '',
    total: ''
  });

  useEffect(() => {
    if (!accessToken) {
      window.location.href = '/login';
      return;
    }
    axios.get(`${config.api_url}/ws-delivered-po-supplier`)
    .then(response => {
      if (response.data.supplier && Array.isArray(response.data.supplier)) {
        const data = response.data.supplier;
        setOptions(data.map(item => ({
          value: item.id,
          label: item.name
        })));
      } else {
        console.error('Unexpected data format:', response.data);
        setOptions([]);
      }
    })
    .catch(error => {
      console.error('Error fetching suppliers:', error);
    });
  }, [accessToken]);

  const handleSupplierChange = (selectedOption) => 
  {
    const supplierId = selectedOption ? selectedOption.value : '';
    if (supplierId) 
    {
      axios.get(`${config.api_url}/ws-supplier-po/${supplierId}`)
      .then(response => 
      {
        setPurchaseOrders(response.data);
      })
      .catch(error => console.error(error));
    } else 
    {
      setPurchaseOrders([]);
    }
  };

  const handlePoChange = (poId) => {
    if (poId) {
      axios.get(`${config.api_url}/ws-get-po-details-by-id/${poId}`)
      .then(response => 
      {
        setPurchaseItem(response.data.purchase);
        const purchase = response.data.purchase;
        setSelectedSupplier(purchase[0].supplier_id);
         setSelectedPo(purchase[0].supplier_id);

        setPoDetails({
          netAmount: (parseFloat(purchase[0].net_std_goods || 0.00) + parseFloat(purchase[0].net_zero_goods || 0.00)).toFixed(2),
          vatAmount: parseFloat(purchase[0].net_vat_amount || 0.00).toFixed(2),
          totalAmount: parseFloat(purchase[0].net_grand_total || 0.00).toFixed(2),
        });
      })
      .catch(error => console.error(error));
    } else {
      setPurchaseItem([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e, action) => {
    e.preventDefault();

    const formElement = document.getElementById("new_sales_order");
    const formData = new FormData(formElement);
    formData.append('type', 'INV');

    purchaseItem.forEach(item => {
      formData.append(`purchaseItems[${item.id}][received_qty]`, item.received_qty);
    });

    axios.post(`${config.api_url}/submit-supplier-bill`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      Swal.fire({
        title: "Success",
        text: "Supplier bill submitted successfully",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });

      if (action === 'saveAndClose') {
        navigate('/PurchaseLedger');
      } else {
        setFormData({
          deliveryDate: '',
          dueDate: '',
          billNo: '',
          jobRef: '',
          netAmount: '',
          discount: '',
          vat: '',
          total: ''
        });
        setPurchaseItem([]);
        setSelectedSupplier(null);
      }
    })
    .catch(error => {
      console.error('Error submitting form:', error);
      Swal.fire('Error', 'There was an issue submitting the form.', 'error');
    });
  };

  const handleDiscClick = (id) => {
    let data = purchaseItem.find(item => item.id === id);
    if (data && (data.bedsLeds || data.costToServe || data.offInvoice || data.retro || data.sugroRetro || data.promptPayment)) {
      setDiscountData({
        retro: data.retro || 0,
        promptPayment: data.promptPayment || 0,
        costToServe: data.costToServe || 0,
        offInvoice: data.offInvoice || 0,
        bedsLeds: data.bedsLeds || 0,
        sugroRetro: data.sugroRetro || 0,
      });
    } else {
      setDiscountData({
        retro: "",
        promptPayment: "",
        costToServe: "",
        offInvoice: "",
        bedsLeds: "",
        sugroRetro: "",
      });
    }
  };

  useEffect(() => {
    if (id) {
      handlePoChange(id);

    }
    }, [id]); 

  useEffect(() => {
    if (selectedSupplier) {
      handleSupplierChange({ value: selectedSupplier }); 
    }
  }, [selectedSupplier]); 
  return (

    <div>
    <div class="modal fade" id="view-discount" tabindex="-1">
    <div class="modal-dialog modal-sm">
    <div class="modal-content">
    <div className="modal-header">
    <h6 className="modal-title">Discount per Case</h6>
    <button
    type="button"
    className="btn-close"
    data-bs-dismiss="modal"
    aria-label="Close"
    ></button>
    </div>
    <div className="modal-body">
    <div className="adddiscount-popup">
    <form>
    <table className="table table-bordered dataTable">
    <tbody>
    <tr>
    <td>Retro</td>
    <td>
    <input
    type="text"
    className="form-control"
    name="retro"
    value={discountData.retro}
    />
    </td>
    </tr>
    <tr>
    <td>Prompt Payment</td>
    <td>
    <input
    type="text"
    className="form-control"
    name="promptPayment"   
    value={discountData.promptPayment}
    />
    </td>
    </tr>
    <tr>
    <td>Cost to Serve</td>
    <td>
    <input
    type="text"
    className="form-control"
    value={discountData.costToServe}

    />
    </td>
    </tr>
    <tr>
    <td>Off Invoice</td>
    <td>
    <input
    type="text"
    className="form-control"
    value={discountData.offInvoice}
    />
    </td>
    </tr>
    <tr>
    <td>Beds & Leds</td>
    <td>
    <input
    type="text"
    className="form-control"
    value={discountData.offInvoice}
    />
    </td>
    </tr>
    <tr>
    <td>Sugro Retro</td>
    <td>
    <input
    type="text"
    className="form-control"

    />
    </td>
    </tr>
    </tbody>
    </table>

    </form>
    </div>
    </div>

    </div>
    </div>
    </div>
    <Header />
    <div className="breadcrumbs">
    <div className="container-fluid">
    <div className="row">
    <div className="col-12 col-lg-12 col-sm-12"> <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="/Dashboard">Dashboard</a> &gt; <span className="last-crumb">Supplier Bill</span> </div>
    </div>
    </div>
    </div>
    <div className="main-container">
    <form id='new_sales_order' onSubmit={handleSubmit}>
    <div className="container-fluid">
    <div className="row">
    <div className="col-12 col-lg-12 col-sm-12">
    <div className="show-order-area dashboard">
    <div style={{ marginBottom: '20px', float: 'left', width: '100%' }}>

    <div className="newsalesorderform">
    <div className="row mb-3 padd-l-13px">
    <div className="col-6 col-lg-6 col-sm-6">

    <div className="row mb-3">
    <div className="col-4 col-lg-3 col-sm-3">
    <label htmlFor="desc" className="col-form-label">Select Supplier <span className='red'>*</span> </label>
    </div>
    <div className="col-8 col-lg-9 col-sm-9">    

    <Select name="supplier_id"
    options={options}
    onChange={handleSupplierChange}
    placeholder="Select Supplier"
    isSearchable 
    value={options.find(option => option.value === selectedSupplier)}
    />

    </div>
    </div>
    <div className="row mb-3">
    <div className="col-4 col-lg-3 col-sm-3">
    <label htmlFor="desc" className="col-form-label">Bill Date </label>
    </div>
    <div className="col-8 col-lg-3 col-sm-3">
    <input type="date" className="form-control" name="bill_date" id="bill_date" />
    </div>

    <div className="col-4 col-lg-3 col-sm-3">
    <label htmlFor="desc" className="col-form-label">Bill Due Date </label>
    </div>
    <div className="col-8 col-lg-3 col-sm-3">
    <input type="date" className="form-control" name="bill_due_date" id="bill_due_date" />
    </div>
    </div>
    <div className="row mb-3">
    <div className="col-4 col-lg-3 col-sm-3">
    <label htmlFor="desc" className="col-form-label">Bill No <span className='red'>*</span> </label>
    </div>
    <div className="col-8 col-lg-9 col-sm-9">
    <input type="text" name="bill_no" className="form-control" required />
    </div>
    </div>
    </div>
    <div className="col-6 col-lg-6 col-sm-6">
    <div>
    <div className="row mb-3">
    <div className="col-4 col-lg-3 col-sm-3">
    <label htmlFor="desc" className="col-form-label">Select P.O. <span className='red'>*</span> </label>
    </div>
    <div className="col-8 col-lg-9 col-sm-9">

<select
    className="form-control"    
    id="purchase_id"
    name="purchase_id"
    value={selectedPo} // Use selectedPo directly
    onChange={(e) => handlePoChange(e.target.value)} 
>
    <option value="">Select P.O.</option>
    {purchaseOrders.map(purchase => (
        <option key={purchase.id} value={purchase.id}>
            {purchase.reference_no}
        </option>
    ))}
</select>       
      </div>
      </div>
      <div className="row mb-3">
      <div className="col-4 col-lg-3 col-sm-3">
      <label htmlFor="desc" className="col-form-label">Job Ref <span className='red'>*</span></label>
      </div>
      <div className="col-8 col-lg-9 col-sm-9">
      <input type="text" name="job_ref" className="form-control" required />
      </div>
      </div>  
      </div>
      </div>
      </div>
      </div>
      <h5>Confirm Quantity</h5>
      <div className="verical-scrl-wrapper common-table supplierbill-table" id="style-3">
      <table className="table table-bordered dataTable" id="dsTable">
      <thead>
      <tr>
      <th>Code</th>           
      <th>Description</th>
      <th>PK</th>
      <th>Size</th>
      <th>Discount</th>
      <th>Order Qty</th>
      <th>Received Qty (GRN)</th>
      <th>Bill Qty</th>
      <th>Pending</th>
      </tr>
      </thead>
      <tbody>


      {purchaseItem.map((item, index) => (
        <tr>
        <td><a href='#'>{item.code}</a></td>           
        <td>{item.product_desc}</td>
        <td>{item.pack}</td>
        <td>{item.size}</td>
        <td>
        <a
        href="#"
        data-bs-toggle="modal" data-bs-target="#view-discount" onClick={() => handleDiscClick(item.id)}
        >
        {item.discount_amount}
        </a>
        </td>
        <td> {item.quantity}</td>
        <td>{item.received_qty}</td>
        <td><input type="text" class="form-control" value={item.received_qty}/></td>
        <td>{item.quantity-item.received_qty}</td>
        </tr> 
        ))}
        </tbody>
        </table>
        </div>
        <h5>Accounting Code</h5>
        <div className="verical-scrl-wrapper common-table accountcode-table" id="style-3">
        <table className="table table-bordered dataTable" id="dsTable">
        <thead>
        <tr>
        <th>Code</th>
        <th>Name</th>
        <th>Category</th>
        <th>Description</th>
        <th>Amount</th>
        <th>Pending</th>

        </tr>
        </thead>
        <tbody>
        <tr>
        <td><a href='#'>SKI010</a></td>
        <td>Bank HSBC Bank 31513621</td>
        <td>Cash and Bank</td>
        <td><input type="text" class="form-control" placeholder="ABC" /></td>
        <td><input type="text" class="form-control" placeholder="90" /></td>
        <td><a href="#"><i class="fa fa-trash"></i></a></td>

        </tr>    
        </tbody>
        </table>
        </div>

        <div className="purchaseform">
        <div className="row">
        <div className="col-6 col-lg-10 col-sm-10">
        <div className="row mb-3">
        <div className="col-6 col-lg-1 col-sm-1">
        <label htmlFor="desc" className="col-form-label">Net Amount </label>
        </div>
        <div className="col-6 col-lg-2 col-sm-2">
        <input type="text" className="form-control" id="net_amount" name="net_amount" value={poDetails.netAmount} readOnly />
        </div>

        <div className="col-6 col-lg-1 col-sm-1">
        <label htmlFor="desc" className="col-form-label">Discount </label>
        </div>
        <div className="col-6 col-lg-2 col-sm-2">
        <input type="text" className="form-control" id="addr2" />
        </div>

        <div className="col-6 col-lg-1 col-sm-1">
        <label htmlFor="desc" className="col-form-label">VAT </label>
        </div>
        <div className="col-6 col-lg-2 col-sm-2">
        <input type="text" className="form-control" id="vat_amount" value={poDetails.vatAmount} readOnly />
        </div>

        <div className="col-6 col-lg-1 col-sm-1">
        <label htmlFor="desc" className="col-form-label">Total </label>
        </div>
        <div className="col-6 col-lg-2 col-sm-2">
        <input type="text" className="form-control" id="total_amount" value={poDetails.totalAmount} readOnly/>
        </div>
        </div>
        </div>

        <div className="col-6 col-lg-2 col-sm-2">
        <div style={{ float: "right", width: "100%" }}>
        <a href="#" className="create-catalogue-btn"><i className='fa fa-plus'></i>&nbsp;Accounting Code</a>
        </div>
        </div>
        </div>
        </div>

        <a href="#" className="common-closebtn">Cancel</a> &nbsp;
        <div class="btn-group bouble-btn" role="group" aria-label="Basic example">
        <button type="button" class="saveclose" onClick={(e) => handleSubmit(e, 'saveAndClose')}>Save & Close</button>

        <button type="button" class="savenew" onClick={(e) => handleSubmit(e, 'saveAndNew')}>Save & New</button>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </form>
        </div>

        <Footer />
        </div>
        );
      };

      export default EditSupplierBill; 
