import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import config from "../Common_Files/Config";

const ExportSupplierList = ({ totalActiveProduct, urlLink, pdtStatus }) => {
  const accessToken = localStorage.getItem('accessToken');
  const exportActiveProduct = async () => {
    const formData = new FormData();
    formData.append('draw', '1');
    formData.append('start', '0');
    formData.append("length", totalActiveProduct);

    const apiActivePdtUrl = `${config.api_url}/${urlLink}`; 
    
    try {
      const response = await fetch(apiActivePdtUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch Products');
      }

      const data = await response.json();

      let exportData = [];  // Use `let` instead of `const` to allow reassignment

      if (pdtStatus === 'Supplier') {
        exportData = data.data.map(product => ({
          "Supplier ID": product.code || '',
          "Name": product.name || '',
          "Postcode": product.postcode || '',
          "Contact Person Name": product.contact_person_name || '',
          "Mobile": product.contact_person_mobile || '',
          "Email": product.contact_person_email || '',
          "Sort Code": product.sort_code || '',
          Status: product.status === '1' ? 'Active' : 'Inactive',
        }));
      } else if (pdtStatus === 'Customer') {
        exportData = data.data.map(product => ({
          "Customer ID": product.code || '',
          "Company Name": product.company_name || '',
          "Contact Person Name": product.contact_person_name || '', // Corrected here
          "Credit Status": product.credit_status || '', // Corrected here (assuming you meant to refer to something like credit status)
          "Credit Due": product.credit_due || 0, // Assuming you meant to refer to a credit due value
          "Credit Overdue": product.credit_overdue || 0, // Assuming you meant to refer to a credit overdue value
          Status: product.status === '1' ? 'Active' : 'Inactive',
        }));
      }
      

    

      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Active Products');
      const now = new Date();

      // Get UK time (Europe/London timezone)
      const options = {
        timeZone: 'Europe/London',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // Use 24-hour format
      };
      
      const ukTime = new Intl.DateTimeFormat('en-GB', options).format(now);
      const [date, time] = ukTime.split(', ');
      const formattedDate = date.split('/').reverse().join('-');  
      const formattedTime = time.replace(/:/g, '-'); 
      const filename = `${pdtStatus}-list-${formattedDate}-${formattedTime}.xlsx`; 
      XLSX.writeFile(wb, filename);

    } catch (error) {
      console.error('Error exporting Active Product:', error);
    }
  };

  return (
    <div>
      <button className="create-catalogue-btn" onClick={exportActiveProduct} style={{textTransform : 'capitalize'}}>
        Export {pdtStatus}
      </button>
    </div>
  );
};

export default ExportSupplierList;
