import React, { useRef } from 'react';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
// import 'select2/dist/css/select2.min.css';
// import 'select2';
import 'datatables.net-bs5';
import 'rsuite/dist/rsuite.min.css';
import $ from "jquery";

const AddRole = ({ showModal, setShowModal, onUserAdded }) => {
  const modalRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("User added");

    if (onUserAdded) onUserAdded();
    setShowModal(false); // Hide the modal after submission
  };

  return (
    <>
      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }} ref={modalRef}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Role</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="addroll-popup">
                  <form id="addrollform">
				  <div class="row mb-3 padd-l-13px">

                  <div class="col-sm-12"><p>Please fill in the information below. The field labels marked with <span className='red'>*</span> are required input fields.</p></div>
	                    
	                    <div class="col-sm-12">
	                    	<div className="input-group">
                          		<div className="col-sm-12">
	                      			<label htmlFor="lastName" class="col-form-label">Role Name <span className='red'>*</span></label>
	                      			<input type="text" class="form-control" name="last_name" id="last_name" />
	                      		</div>
	                      		
	                      	</div>
	                    </div>
	                    <div class="col-sm-12">
	                    	<div className="input-group">
                          <div className="col-sm-12">
	                      		<label htmlFor="phone" class="col-form-label">Description <span className='red'>*</span></label>
	                      		
								<textarea class="form-control" style={{ height: '100px'}}></textarea>
	                      	</div>
	                      	
	                      </div>
	                    </div>
	                  </div>



                    <hr />
                    <div className="row padd-l-13px">
                      <div className="col-sm-12">
                        <button
                          type="submit"
                          id="submit"
                          className="popup-innerbtn-common right"
                        >
                          Add Role
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default AddRole;
