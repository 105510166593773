import React, { useState, useEffect, useRef } from "react";
import Header from "../Common_Files/Header";
import Footer from "../Common_Files/Footer";
import config from "../Common_Files/Config";
import ViewSupplier from "../Master/ViewSupplier";
import SupplierSearch from "../Purchase/SupplierSearch";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5";
import { DateRangePicker } from "rsuite";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import $ from "jquery";
// import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';

const PurchaseLedger = () => {
  const [reportrange, setReportrange] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchValue, setSeachValue] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [searchSupplier, setSearchSupplier] = useState("");
  const [purchaseLedgerList, setPurchaseLedgerList] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const tableRef = useRef(null);
  const accessToken = localStorage.getItem("accessToken");

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setFromDate(format(start, "yyyy-MM-dd"));
      setToDate(format(end, "yyyy-MM-dd"));
      setReportrange(
        `${format(start, "MM/dd/yyyy")} - ${format(end, "MM/dd/yyyy")}`
      );
    }
  };

  const fetchData = async () => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      window.location.href = "/login";
      return;
    }

    const formData = new FormData();
    formData.append("searchByFromdate", fromDate);
    formData.append("searchByTodate", toDate);
    formData.append("draw", draw);
    formData.append("start", start);
    formData.append("length", length);
    formData.append("searchValue", searchValue);
    formData.append("selectedType", selectedType);
    formData.append("supplier", searchSupplier);

    const apiUrl = `${config.api_url}/ws-purchase-ledger`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch purchase data");
      }

      const data = await response.json();
      if (data.success) {
        setPurchaseLedgerList(data.purchase_ledger);
      }
    } catch (error) {
      console.error("Error fetching purchase data:", error);
    }
  };

  const initializeDataTable = () => {
    if (!accessToken) {
      window.location.href = "/login";
      return;
    }
    const $table = $(tableRef.current);
    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }

    let i = 0;
    // Initialize DataTable with server-side processing
    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${config.api_url}/ws-purchase-ledger`,
        type: "POST",
        data: function (d) {
          return $.extend({}, d, {
            draw: d.draw,
            start: d.start,
            length: d.length,
          });
        },
        dataSrc: function (json) {
          return json.purchase_ledger;
        },
      },
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      columns: [
        {
          data: "purchase_id",
          render: function (data, type, row) {
            return `<a href="EditSupplierBill/${row.purchase_id}">${row.id}</a>`;
          }
        },
        {
          data: "raised_on",
        },
        {
          data: "type",
        },
        {
          data: "bill_no",
        },
        {
          data: "job_ref",
        },
        {
          data: "reference_no",
          render: function (data, type, row) {
            // Check if the reference_no is null or 'null' string and return an empty string if so
            return `<a href="PurchaseHistoryView/${row.purchase_id}">${(row.reference_no === null || row.reference_no === 'null') ? '' : row.reference_no}</a>`;
          }
        },
        {
          data: "name",
          render: function (data, type, row) {
            return `<a href="#" class="viewbtn" data-id="${row.supplier_id}" data-bs-toggle="modal" data-bs-target="#viewsupplier">${row.name}</a>`;
          }
        },
        {
          data: "value",
          render: function (data) {
            return `<div style="text-align: right;">${data}</div>`;
          }
        },
        {
          data: "balance",
          render: function (data) {
            return `<div style="text-align: right;">${data}</div>`;
          }
        },
        {
          data: "due_date",
        },
        {
          data: "created_by",
        },
        {
          data: "status",
        },
        {
          data: "purchase_id",
          render: function (data, type, row) {
            return row.status === "Pending" ? `<a href="/PayBills/${row.purchase_id}">Pay</a>` : "";
          }
        }
      ],
      pageLength: 50,
    });
  };

  useEffect(() => {
    initializeDataTable();
    // fetchData();
  }, [
    fromDate,
    toDate,
    searchValue,
    selectedType,
    searchSupplier,
    reportrange,
  ]);

  return (
    <div>
      <Header />
      <ViewSupplier />
      {/* <SupplierSearch /> */}
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              {" "}
              <a href="#">
                <i className="fa fa-home"></i>
              </a>{" "}
              &gt; <a href="/Dashboard">Dashboard</a> &gt;{" "}
              <span className="last-crumb">Purchase Ledger</span>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <form id="new_sales_order">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-12 col-sm-12">
                <div className="show-order-area dashboard">
                  <div className="dashform">
                    <div className="topsearch-area">
                      <div className="row">
                        <div className="col-12 col-lg-3 col-sm-3">
                          <div className="row">
                            <label
                              htmlFor="inputText"
                              className="col-sm-6 col-form-label"
                            >
                              P.O ID/Supplier/Bill No./Job Ref
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="search-area form-control"
                                placeholder="Search"
                                name="product_name"
                                id="product_name"
                                onChange={(e) => setSeachValue(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-1 col-sm-1">
                          <select
                            name="warehouse_id"
                            id="warehouse_id"
                            className="form-select"
                            defaultValue=""
                            onChange={(e) => setSelectedType(e.target.value)}
                          >
                            <option value="" disabled>
                              Type
                            </option>
                            <option value="all">All</option>
                            <option value="inv">INV</option>
                            <option value="credit">Credit</option>
                          </select>
                        </div>
                        <div className="col-sm-2">
                          <SupplierSearch
                            setSearchSupplier={setSearchSupplier}
                          />
                        </div>
                        <div className="col-12 col-lg-6 col-sm-6">
                          <div className="row">
                            <label
                              htmlFor="inputText"
                              className="col-sm-3 col-form-label"
                            >
                              Date Range
                            </label>
                            <div className="col-sm-7">
                              <DateRangePicker
                                value={[
                                  fromDate ? new Date(fromDate) : undefined,
                                  toDate ? new Date(toDate) : undefined,
                                ]}
                                onChange={handleDateChange}
                                format="MM/dd/yyyy"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="verical-scrl-wrapper common-table"
                      id="style-3"
                    >
                      <table
                        className="table table-bordered dataTable"
                        id="supplierTable"
                        ref={tableRef}
                      >
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Raised On</th>
                            <th>Type</th>
                            <th>Bill No</th>
                            <th>Job Ref</th>
                            <th>P.O ID</th>
                            <th>Supplier (ID)</th>
                            <th>Value</th>
                            <th>Balance</th>
                            <th>Due Date</th>
                            <th>Created By</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <Footer />
    </div>
  );
};

export default PurchaseLedger;
