import React, { useState, useEffect } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import AddSupplierModal from "../Master/Add/AddSupplierModal";
import axios from 'axios';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import PurchaseSupplierList from '../CommonComp/PurchaseSupplierList';
import BankSuggestionBox from '../CommonComp/BankSuggestionBox';
import { Link, useParams, useNavigate } from 'react-router-dom';
const PayBills = () => {
    const [suppliers, setSuppliers] = useState([]); 
    const [selectedSupplier, setSelectedSupplier] = useState('');
    const accessToken = localStorage.getItem('accessToken');
    const user_id = localStorage.getItem('user_id');


    //const [bills, setBills] = useState([]);
    const [supplierInfo, setSupplierInfo] = useState(null); 
    const [bills, setBills] = useState(0);
    const [payingAmount, setPayingAmount] = useState(0);
    const [bankInfo, setBankInfo] = useState(null); 
    const [tableRow, setTableRow] = useState([]);
    const [checkedRows, setCheckedRows] = useState([]);
    const [deliveryDate, setDeliveryDate] = useState('');
    const [details, setDetails] = useState('');
    const [transactionRef, setTransactionRef] = useState('');

    const navigateToPurchaseList = useNavigate();

    const handleDateChange = (e) => {
        setDeliveryDate(e.target.value);
    };
    const handleDetailsChange = (e) => {
        setDetails(e.target.value);
    };

    const handleTransactionChange = (e) => {
        setTransactionRef(e.target.value);
    };

    const handleBankChange = (selectedBank) => {
        setBankInfo(selectedBank); 
    };

    const handleSupplierChange = (selectedSupplier) => {
        setSupplierInfo(selectedSupplier); 
        if(selectedSupplier){
            getRequiresSupplierList(selectedSupplier.value);
        }else{
            setTableRow([]);
        }
    };

    const getRequiresSupplierList = (supplierId) => {
        if (supplierId) {
            axios.get(`${config.api_url}/ws-supplier-bills/${supplierId}`) 
            .then(response => {

                if (Array.isArray(response.data)) {


                    setTableRow(response.data);
                } else {
                    console.error('Unexpected data format for bills:', response.data);

                    setTableRow([]);
                }
            })
            .catch(error => {
                console.error('Error fetching bills:', error);
                setTableRow([]);
            });
        } else {
         setTableRow([]);
     }
 };


 const handlePayChange = (index, value ,type) => {
    const newTableRow = [...tableRow];
    newTableRow[index].pay = parseFloat(value) || 0;
    setTableRow(newTableRow);

    calculateTotals(newTableRow);
};

const handleCheckboxChange = (index) => {
    setCheckedRows((prevCheckedRows) => 
    {
        const newCheckedRows = prevCheckedRows.includes(index)
        ? prevCheckedRows.filter((rowIndex) => rowIndex !== index) 
        : [...prevCheckedRows, index]; 

        calculateTotals(tableRow, newCheckedRows);
        return newCheckedRows;
    });
};

const calculateTotals = (rows, selectedRows = checkedRows) => {


    // Calculate totalPay by filtering selected rows and applying reduce
    const totalPay = rows
        .filter((_, idx) => selectedRows.includes(idx))  // Filter rows based on selected indices
        .reduce((total, row) => {


          //  if (row.type === 'INV') {
                return total + (row.pay || 0);  // Add pay for 'INV' type
          //  } else {
            //    return total - (row.pay || 0);  // Subtract pay for non-'INV' types
           // }
        }, 0);  // Initial value for total is 0

        console.log(totalPay);

    setPayingAmount(totalPay);  // Set the calculated paying amount

    // Calculate totalBill by filtering selected rows and applying reduce
    const totalBill = rows
        .filter((_, idx) => selectedRows.includes(idx))  // Filter rows based on selected indices
        .reduce((sum, row) => {


            if (row.type === 'INV') 
            {
                return sum + (parseFloat(row.value) || 0);  // Add value for 'INV' type
            } else 
            {
                return sum - (parseFloat(0) || 0);  // Subtract value for non-'INV' types
            }
        }, 0);  // Initial value for sum is 0

    setBills(totalBill);  // Set the calculated total bill amount
};




const fetchData = async () => {};


const handleSubmit = async (event,action) =>
{
    event.preventDefault();

    const formData = new FormData();
    const supplier = supplierInfo ? supplierInfo.value : '';
    const dDate = deliveryDate ? deliveryDate : '';
    const bank = bankInfo ? bankInfo.value : '';
    const detailInfo = details ? details : '';
    const transactionInfo = transactionRef ? transactionRef : '';

    formData.append('supplier_id', supplier);
    formData.append('date', dDate);
    formData.append('bank_id', bank);
    formData.append('details', detailInfo);
    formData.append('transaction_ref', transactionInfo);
    formData.append('paying_amount', payingAmount);
    formData.append('balance', payingAmount - bills);

        // Gather data for the checked rows
        const selectedRowsData = tableRow
        .filter((_, idx) => checkedRows.includes(idx))
        .map((row) => ({
            purchase_id: row.purchase_id,
            pay: row.pay || 0,
            bill_balance: row.balance - parseFloat(row.pay || 0),
            purchase_ledger_id:row.id
        }));

        formData.append('selected_rows', JSON.stringify(selectedRowsData));

        formData.append('user_id', user_id);

        // Send the form data to the backend
        const apiUrl = `${config.api_url}/ws-submit-purchase-payment`;

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to submit payment data');
            }

            const data = await response.json();
            console.log('Submission successful:', data);


             if(action === 'close')
                {
                    navigateToPurchaseList('/PurchaseLedger');
                } else if(action === 'new')
                {
                   
                 
                   
                }

            // Reset the form if submission is successful (optional)
            /*setCheckedRows([]);
            setPayingAmount(0);
            setBills(0);
            setTableRow([]);*/
        } catch (error) {
            console.error('Error submitting payment data:', error);
        }
    };

    return (
        <div>
        <Header />

        <AddSupplierModal fetchData={fetchData} />
        <div className="breadcrumbs">
        <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-lg-12 col-sm-12">
        <a href="#"><i className="fa fa-home"></i></a> &gt;
        <a href="/Dashboard">Dashboard</a> &gt;
        <span className="last-crumb">Pay Bills (Supplier Payment)</span>
        </div>
        </div>
        </div>
        </div>
        <div className="main-container">
        <form id='new_sales_order'>
        <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-lg-12 col-sm-12">
        <div className="show-order-area dashboard">
        <div style={{ marginBottom: '20px', float: 'left' }}>
        <div className="newsalesorderform">
        <div style={{ float: "right", width: "100%" }}>
        <a href="#" className="create-catalogue-btn" data-bs-toggle="modal"
        data-bs-target="#add-supplier" ><i className='fa fa-plus'></i>&nbsp;New Supplier</a>
        </div>
        <hr />
        <div className="row mb-3 padd-l-13px">
        <div className="col-6 col-lg-6 col-sm-6">
        <div className="row mb-3">
        <div className="col-4 col-lg-3 col-sm-3">
        <label htmlFor="supplier" className="col-form-label">Select Supplier <span className='red'>*</span> </label>
        </div>
        <div className="col-8 col-lg-9 col-sm-9">



        <PurchaseSupplierList onSupplierChange={handleSupplierChange} />


        </div>
        </div>
        <div className="row mb-3">
        <div className="col-4 col-lg-3 col-sm-3">
        <label htmlFor="delivery_date" className="col-form-label">Date </label>
        </div>
        <div className="col-8 col-lg-3 col-sm-3">
        <input type="date" className="form-control" name="delivery_date" id="delivery_date" onChange={handleDateChange}  value={deliveryDate}/>
        </div>
        <div className="col-4 col-lg-3 col-sm-3">
        <label htmlFor="bank" className="col-form-label">Bank </label>
        </div>
        <div className="col-8 col-lg-3 col-sm-3">
        <BankSuggestionBox onBankChange={handleBankChange} />
        </div>
        </div>
        <div className="row mb-3">
        <div className="col-4 col-lg-3 col-sm-3">
        <label htmlFor="details" className="col-form-label">Details </label>
        </div>
        <div className="col-8 col-lg-9 col-sm-9">
        <input type="text" className="form-control" name="details"  onChange={handleDetailsChange} value={details} />
        </div>
        </div>
        </div>
        <div className="col-6 col-lg-6 col-sm-6">
        <div className="row mb-3">
        <div className="col-4 col-lg-3 col-sm-3">
        <label htmlFor="transaction_ref" className="col-form-label">Transaction Ref </label>
        </div>
        <div className="col-8 col-lg-9 col-sm-9">
        <input type="text" className="form-control" name="transaction_ref"  onChange={handleTransactionChange} value={transactionRef} />
        </div>
        </div>
        <div className="row mb-3">
        <div className="col-4 col-lg-3 col-sm-3">
        <label htmlFor="paying_amount" className="col-form-label">Paying Amount</label>
        </div>
        <div className="col-8 col-lg-9 col-sm-9">
        <input type="text" className="form-control" disabled value={payingAmount} name="paying_amount" />
        </div>
        </div>
        <div className="row mb-3">
        <div className="col-4 col-lg-3 col-sm-3">
        <label htmlFor="balance" className="col-form-label">Balance</label>
        </div>
        <div className="col-8 col-lg-9 col-sm-9">
        <input type="text" className="form-control" disabled value={bills-payingAmount} name="balance" />
        </div>
        </div>
        </div>
        </div>
        </div>

        <div className="verical-scrl-wrapper common-table paybill-table" id="style-3">
        <table className="table table-bordered dataTable" id="dsTable">
        <thead>
        <tr>
        <th>Select</th>
        <th>ID</th>
        <th>Raised On</th>
        <th>Type</th>
        <th>Bill No</th>
        <th>Job Ref</th>
        <th>P.O ID</th>
        <th>Supplier (ID)</th>
        <th>Value</th>
        <th>Balance</th>
        <th>Pay</th>
        <th>Due Date</th>
        <th>Created By</th>
        </tr>
        </thead>
        <tbody>
        {tableRow.map((bill, index) => (
            <tr key={bill.id}>
            <td>


            <label className="checkbox">
            <input
            type="checkbox"
            onChange={() => handleCheckboxChange(index)}
            />
            <span className="default"></span>
            </label>

            </td>
            <td><a href='#'>{bill.id}</a></td>
            <td>{bill.created_date}</td>
            <td>{bill.type}</td>
            <td>{bill.bill_no}</td>
            <td>{bill.job_ref}</td>
            <td><a href='#'>{bill.reference_no}</a></td>
            <td><a href='#'>{bill.name}</a></td>
            <td style={{ textAlign: 'right' }}>{bill.value}</td>
            <td style={{ textAlign: 'right' }}>{bill.balance-parseFloat(bill.pay || 0) }</td>
            <td>


            <input
            type="number"
            name="pay[]"
            className="form-control qtyinputbox no-arrows"
            value={bill.pay || ''}
            style={{ textAlign: 'right' }}
            onChange={(e) => handlePayChange(index, e.target.value,bill.type)}
            placeholder="Enter Amount"
            />
            </td>
            <td>{bill.due_date}</td>
            <td>{bill.createdBy}</td>
            </tr>
            ))}
        </tbody>
        </table>
        </div>
        <hr />
        <a href="#" className="common-closebtn">Cancel</a> &nbsp;
        <div className="btn-group bouble-btn" role="group" aria-label="Basic example">
        <button type="button" onClick={(e)=>handleSubmit(e, 'close')}   className="saveclose">Save & Close</button>
        <button type="button" onClick={(e)=>handleSubmit(e, 'new')} className="savenew">Save & New</button>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </form>
        </div>
        <Footer />
        </div>
        );
    };

    export default PayBills;
