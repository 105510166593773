import React, { useState, useEffect, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import AddCategoryModal from '../Master/Add/AddCategoryModal';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import axios from 'axios';
import Swal from 'sweetalert2';

const ProductCategoryMaster = () => {
    const [categoryName, setCategoryName] = useState('');
    const [status, setStatus] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const modalRef = useRef(null);
    const editModalRef = useRef(null);
    const tableRef = useRef(null);
    const viewModalRef = useRef(null);
    const accessToken = localStorage.getItem('accessToken');

    const initializeDataTable = () => {
        if (!accessToken) {
            window.location.href = '/login';
            return;
          }
        const $table = $(tableRef.current);
        if ($.fn.DataTable.isDataTable($table)) {
            $table.DataTable().destroy();
        }

        $table.DataTable({
            processing: true,
            serverSide: true,
            ajax: {
                url: `${config.api_url}/ws-get-category-list`,
                type: 'POST',
                data: function (d) {
                    return $.extend({}, d, {
                        draw: d.draw,
                        start: d.start,
                        length: d.length,
                        searchValue: searchValue,
                    });
                },
                dataSrc: function (json) {
                    return json.data;
                }
            },
            language: {
                paginate: {
                    first: 'First',
                    last: 'Last',
                    next: 'Next',
                    previous: 'Previous',
                },
                search: 'Search:',
            },
            columnDefs: [
                { orderable: false, targets: '_all' }
            ],

            columns: [
                { data: 'id', width:"7%" },
                { data: 'name' },
                { data: 'status', width:"7%" },
                {
                    data: null,
                    title: 'Action',
                    width:"7%",
                    render: (data, type, row) => `
                        <a href="#" class="editbtn" data-id="${row.id}" data-bs-toggle="modal" data-bs-target="#edit-category">
                            <i class="fa fa-edit"></i> 
                        </a>`
                }
            ],

            columnDefs: [
                {
                    targets: 2,
                    render: function (data) {
                        return data === '1' ? 'Active' : 'Inactive';
                    }
                }
            ],
            pageLength: 50,
        });

        // Event delegation for edit and view actions
        $table.on('click', '.editbtn', function () {
            document.getElementById("editForm").reset();
            const id = $(this).data('id');
            handleEditClick(id);
        });

        return () => {
            if (tableRef.current && $.fn.DataTable.isDataTable(tableRef.current)) {
                $(tableRef.current).DataTable().destroy();
            }
        };
    };


    useEffect(() => {
        initializeDataTable();  
        return () => {
            if (tableRef.current && $.fn.DataTable.isDataTable(tableRef.current)) {
                $(tableRef.current).DataTable().destroy();
            }
        };
    }, [searchValue]);

    const handleEditCategory = async (event) => {
        event.preventDefault();
        const formElement = event.target;
        const formData = new FormData(formElement);
        try {
            const response = await axios.post(`${config.api_url}/ws-update-category`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (editModalRef.current) {
                const bsModal = window.bootstrap.Modal.getInstance(editModalRef.current);
                if (bsModal) bsModal.hide();
            }
            
            Swal.fire({
                title: 'Success!',
                text: 'Category updated successfully.',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false
            }).then(() => {
                
                formElement.reset();
                $(tableRef.current).DataTable().ajax.reload();
            });

        } catch (error) {
            console.error('Error updating category:', error);
        }
    };

    const handleEditClick = async (id) => {
        try {
            const response = await axios.get(`${config.api_url}/ws-get-category-by-id/${id}`);

            if (response.data.success) {
                const category = response.data.category;
                $('#catname').val(category[0].name);
                $('#category_id').val(category[0].id);
                if (category[0].status == "1") {
                    $('#status').prop('checked', true);
                }
            }
        } catch (error) {
            console.error('Request Error:', error);

            Swal.fire({
                title: 'Error!',
                text: 'An error occurred while fetching category details.',
                icon: 'error',
                timer: 1500,
                showConfirmButton: false
            });
        }
    };

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    }

    return (
        <div>
            <Header />

            <AddCategoryModal initializeDataTable={initializeDataTable } />
            <div className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="dashboard.html">Dashboard</a> &gt; <span className="last-crumb">Product Category</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className='add-categy-tbl'>
                            <div class="topsearch-area">
                                <div class="row">
                              <div class="col-12 col-lg-4 col-sm-4">
                                <div class="row">
                                  <label for="inputText" class="col-sm-3 col-form-label">Category :</label>
                                  <div class="col-sm-6">
                                  <input type="text" className="search-area form-control" autoComplete='off' name="searchValue" id="searchValue" onChange={handleSearchChange} placeholder="Search Category" />
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 col-lg-8 col-sm-8">
                                <div style={{ float: "right" }}>
                                <a href="#" className="create-catalogue-btn" data-bs-toggle="modal" data-bs-target="#add-category">Add New Category</a>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div style={{ width: '100%', float: 'left' }}>
                            <div className="verical-scrl-wrapper" id="style-3">
                                    <table className="table table-bordered dataTable" ref={tableRef}>
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Name</th>
                                                    <th>Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="edit-category" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={editModalRef}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Category</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form id='editForm' onSubmit={handleEditCategory}>
                                <div className="row mb-3 padd-l-13px">
                                    <label htmlFor="edit-name" className="col-sm-6 col-form-label">Product Category Name <span className='red'>*</span></label>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="catname"
                                            id="catname"


                                        />
                                    </div>
                                </div>
                                <div className="row mb-3 padd-l-13px">
                                    <label className="col-sm-6 col-form-label">Status</label>
                                    <div className="col-sm-6">
                                        <label className="switch">
                                            <input
                                                className="switch-input"
                                                type="checkbox"
                                                name="status"
                                                id="status"


                                            />
                                            <span className="switch-label" data-on="Active" data-off="Inactive"></span>
                                            <span className="switch-handle"></span>
                                        </label>
                                    </div>
                                </div>
                                <hr />
                                <div className="row padd-l-13px">
                                    <div className="col-sm-12">
                                        <input
                                            type="text" hidden
                                            className="form-control"
                                            name="category_id"
                                            id="category_id"
                                        ></input>
                                        <button type="submit" id="submit" className="popup-innerbtn-common right">Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default ProductCategoryMaster;
