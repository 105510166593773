import React, { useState, useEffect, useRef } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import ViewProduct from '../Accounts/SupplierBill';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import { acceptNumber } from '../../services/NewPurchaseOrderService';

const SupplierBill = () => {
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [purchaseItem, setPurchaseItem] = useState([]);
    const [selectedPO, setSelectedPO] = useState(null);
    const [discountData, setDiscountData] = useState([]);
    const navigateToPurchaseLedger = useNavigate();
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [totalVat, setTotalVat] = useState(0);
    const [netAmt, setNetAmt] = useState(0.00);
    const [totalDisc, setTotalDisc] = useState(0);
    const [totalBalance, setTotalBalance] = useState(0);
    const [totalAmt, setTotalAmt] = useState(0.00);
    
    const discModal = useRef(null);
    const user_id = localStorage.getItem('user_id');
    const [formData, setFormData] = useState({
        bill_date: '',
        bill_due_date: '',
        bill_no: '',
        job_ref: '',
        netAmount: '',
        discount: '',
        vat: '',
        total: '',
        supplier_id: '',
        discount:'',
        vat:'',
        total:''
    });

    useEffect(() => {
        axios.get(`${config.api_url}/ws-purchase-order-supplier`)
            .then(response => {
                console.log('Suppliers Response:', response.data); 
                if (response.data.supplier && Array.isArray(response.data.supplier)) {
                    setSuppliers(response.data.supplier);
                } else {
                    console.error('Unexpected data format:', response.data);
                    setSuppliers([]);
                }
            })
            .catch(error => {
                console.error('Error fetching suppliers:', error);
            });
    }, []); 

    useEffect(() => {
        SetAllTotalStats();
    }, [purchaseItem]);     

    const SetAllTotalStats = () => {
        let total = 0;
        let totalDisc = 0;
        let totalBalance = 0;
        let totalVat = 0;    
        // Iterate over each item in purchaseItem
        purchaseItem.forEach(i => {
            // Only consider items where bill_qty is valid (greater than 0)
            let billQty = (i.bill_qty && !isNaN(i.bill_qty) && i.bill_qty > 0) ? parseInt(i.bill_qty, 10) : 0;
            
            // Proceed only if billQty is greater than 0 (i.e., bill_qty is provided)
            if (billQty > 0) {
                // Calculate the amount for this row
                let amt = (parseFloat(i.cost) || 0) * billQty;
                total += amt;

                // Calculate total discount, ensuring each discount is safe and valid
                let disc = ((isNaN(Number(i.retro)) ? 0 : Number(i.retro)) +
                            (isNaN(Number(i.promptPayment)) ? 0 : Number(i.promptPayment)) +
                            (isNaN(Number(i.costToServe)) ? 0 : Number(i.costToServe)) +
                            (isNaN(Number(i.offInvoice)) ? 0 : Number(i.offInvoice)) +
                            (isNaN(Number(i.bedsLeds)) ? 0 : Number(i.bedsLeds)) +
                            (isNaN(Number(i.sugroRetro)) ? 0 : Number(i.sugroRetro))) ;
                totalDisc += disc * billQty; 

                // For vat applied products only
                if (parseFloat(i.vat_percentage) > 0) {
                    let vat = 0;
                    let amount = ((parseFloat(i.cost)) - disc) * billQty; // Total after discount and multiplied by billQty
                    let amt_vat_percentage = amount * (parseFloat(i.vat_percentage) / 100); // Calculating VAT
                    totalVat += amt_vat_percentage; // Accumulate VAT
                }
                
    
                // Calculate balance, ensuring pending_qty is valid
                let balance = (isNaN(i.pending_qty) ? 0 : i.pending_qty) * (parseFloat(i.cost) || 0);
                totalBalance += balance; // Add to total balance
            }
        });
    
        // Set the calculated totals
        setNetAmt(total); // Net amount for valid bill_qty
        setTotalDisc(totalDisc); // Total discount
        setTotalBalance(totalBalance); // Total balance
        setTotalVat(totalVat); // Total VAT

    
        // Ensure totalVat is valid (assuming it's defined elsewhere in the state)
        let totalVat1 = parseFloat(totalVat) || 0;
    
        // Calculate the final total amount: (total - totalDisc + totalVat)
        let totalAmt = (total - totalDisc) + totalVat1;
        setTotalAmt(totalAmt); // Set the final total amount
        console.log(purchaseItem); // Optional logging for debugging
    };

    const handleBillQty = (e, id) => {
        const value = parseInt(e.target.value, 10);
        setPurchaseItem((previousItems) => {
            return previousItems.map(item => {
                if (item.product_id === id) {
                    let totalQty = parseInt(item.receivedQtyInLedger) + value;
                    if (isNaN(value)) {
                        const updatedItem = {
                            ...item,
                            bill_qty: '',
                            dummy_bill_qty: '', 
                            pending_qty: parseInt(item.received_qty) - parseInt(item.receivedQtyInLedger) // Reset pending_qty to the received_qty
                        };

                        // SetAllTotalStats();
                        return updatedItem;
                    }
                
                    if (totalQty > item.received_qty) {
                        Swal.fire({
                            title: "Error!",
                            text: 'Quantity is greater than required',
                            icon: "error",
                            timer: 1500,
                            showConfirmButton: false,
                        });
                
                        const updatedItem = {
                            ...item,
                            bill_qty: '',
                            dummy_bill_qty: '', 
                            pending_qty: parseInt(item.received_qty) - parseInt(item.receivedQtyInLedger) // Reset pending_qty to the received_qty
                        };
                
                        // SetAllTotalStats();
                        return updatedItem;
                    }

                    const updatedItem = {
                        ...item,
                        bill_qty: value,
                        dummy_bill_qty: value,
                        pending_qty: parseInt(item.received_qty) - parseInt(item.receivedQtyInLedger) - value
                    };
                
                    // SetAllTotalStats();
                    return updatedItem;
                }
                return item;
            });
        });
    };

    useEffect(() => {        
        // console.log();
        let totalAmt = (netAmt - totalDisc) + parseFloat(totalVat);
        setTotalAmt(totalAmt);
    }, [netAmt, totalDisc,totalVat]);

    
    const handleSupplierChange = (e) => {
        const supplierId = e.target.value;
        setSelectedSupplier(supplierId);

        if (supplierId) {
            axios.get(`${config.api_url}/ws-supplier-po/${supplierId}`) // Adjust this endpoint as needed
                .then(response => {
                    setPurchaseOrders(response.data); // Assume the response is an array of POs
                })
                .catch(error => console.error(error));
        } else {
            setPurchaseOrders([]);
        }
    };

    const handlePoChange = (e) => {
        const supplierId = e.target.value;
        setSelectedSupplier(supplierId);
        if (supplierId) {
            axios.get(`${config.api_url}/ws-get-po-details/${supplierId}`)
                .then(response => {
                    setPurchaseItem(response.data.purchase);
                    // let totalVat = 0;
                    // let totalDisc = 0;
                    // let totalPendingBalance = 0;
                    // response.data.purchase.forEach(i => {
                    //     //For Total Discount calculation 
                    //     let disc = 0;
                    //     totalVat = (isNaN(Number(i.net_vat_amount)) ? 0 : Number(i.net_vat_amount));
                    //     disc =  (isNaN(Number(i.retro)) ? 0 : Number(i.retro)) + 
                    //             (isNaN(Number(i.promptPayment)) ? 0 : Number(i.promptPayment)) + 
                    //             (isNaN(Number(i.costToServe)) ? 0 : Number(i.costToServe)) + 
                    //             (isNaN(Number(i.offInvoice)) ? 0 : Number(i.offInvoice)) + 
                    //             (isNaN(Number(i.bedsLeds)) ? 0 : Number(i.bedsLeds)) + 
                    //             (isNaN(Number(i.sugroRetro)) ? 0 : Number(i.sugroRetro));
                    //     totalDisc += disc;
                    // });
                    // setTotalVat(totalVat);
                    // setTotalDisc(totalDisc);
                })
                .catch(error => console.error(error));
        } else {
            setPurchaseItem([]);
        }
    };

    // Change in bill qty
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e, action) => {
        e.preventDefault();
        const formElement = document.getElementById("new_sales_order");
        const formData = new FormData(formElement);
        let purchase_id = $('#purchase_id').val();
        let supplier_id = $('#supplier_id').val();
        let job_ref = $('#job_ref').val();
        let bill_no = $('#bill_no').val();
      
        let message = '';
        if (!purchase_id) {
            message = 'Please select the Purchase Order.';
        }
        
        if (!supplier_id) {
            message = 'Please select the Supplier.';
        }
        
        if (!job_ref) {
            message = 'Please enter the Job Reference.';
        }
        
        if (!bill_no) {
            message = 'Please enter the Bill Number.';
        }

        if(message !== '')
        {
            Swal.fire({
                title: "Error!",
                text: message,
                icon: "error",
                timer: 1500, 
                showConfirmButton: false,
              })
              return;
        }

        // purchaseItem.forEach(item => {
        //     formData.append(`purchaseItems[${item.id}][received_qty]`, item.received_qty);
        //     formData.append(`purchaseItems[${item.id}][bill_qty]`, item.bill_qty);
        //     formData.append(`purchaseItems[${item.id}][retro]`, item.retro);
        //     formData.append(`purchaseItems[${item.id}][promptPayment]`, item.promptPayment);
        //     formData.append(`purchaseItems[${item.id}][costToServe]`, item.costToServe);
        //     formData.append(`purchaseItems[${item.id}][offInvoice]`, item.offInvoice);
        //     formData.append(`purchaseItems[${item.id}][bedsLeds]`, item.bedsLeds);
        //     formData.append(`purchaseItems[${item.id}][sugroRetro]`, item.sugroRetro);
        // });

            axios.post(`${config.api_url}/submit-supplier-bill`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                Swal.fire({
                    title: "Success",
                    text: "Supplier bill submitted successfully",
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false,
                });

                if (action === 'close') 
                {
                    navigateToPurchaseLedger('/PurchaseLedger');
                } else if (action === 'new') {
                    setFormData({
                        bill_date: '',
                        bill_due_date: '',
                        bill_no: '',
                        job_ref: '',
                        netAmount: '',
                        discount: '',
                        vat: '',
                        total: '',
                        supplier_id:'',
                        discount:'',
                        vat:'',
                        total:''
                    });

                    setPurchaseItem([]);
                    setSelectedSupplier(null);
                    setSelectedPO(null);
                    setPurchaseOrders([]);
                    
                    setTotalAmt(0.00);
                    setTotalDisc(0);
                    setNetAmt(0.00);
                    setTotalVat(0);
                }

            })
            .catch(error => {
                console.error('Error submitting form:', error);
                Swal.fire('Error', 'There was an issue submitting the form.', 'error');
            });
    };

    const handleDiscClick = (id) => {
        setSelectedItemId(id);
        let data = purchaseItem.find(item => item.id === id);
        console.log(data);
        if (data.bedsLeds || data.costToServe || data.offInvoice || data.retro || data.sugroRetro || data.promptPayment) {

            $('#retro').val(data.retro || 0);
            $('#promptPayment').val(data.promptPayment || 0);
            $('#costToServe').val(data.costToServe || 0);
            $('#offInvoice').val(data.offInvoice || 0);
            $('#bedsnleds').val(data.bedsLeds || 0);
            $('#sugroRetro').val(data.sugroRetro || 0);
            
            setDiscountData({
                retro: data.retro !== null && data.retro !== undefined ? data.retro : 0,
                promptPayment: data.promptPayment !== null && data.promptPayment !== undefined ? data.promptPayment : 0,
                costToServe: data.costToServe !== null && data.costToServe !== undefined ? data.costToServe : 0,
                offInvoice: data.offInvoice !== null && data.offInvoice !== undefined ? data.offInvoice : 0,
                bedsLeds: data.bedsLeds !== null && data.bedsLeds !== undefined ? data.bedsLeds : 0,
                sugroRetro: data.sugroRetro !== null && data.sugroRetro !== undefined ? data.sugroRetro : 0
            });
        }
        else {
            setDiscountData({
                retro: 0,
                promptPayment: 0,
                costToServe: 0,
                offInvoice: 0,
                bedsLeds: 0,
                sugroRetro: 0,
            });
        }
    };

 
    const handleSubmitDiscForm = async (e) => {
        e.preventDefault();
        
        // Access form data using FormData or e.target
        const formData = new FormData(e.target);
        
        // Get values from the form and parse them as floats, defaulting to 0 if not valid
        let retro = parseFloat(formData.get('retro')) || 0;
        let promptPayment = parseFloat(formData.get('promptPayment')) || 0;
        let costToServe = parseFloat(formData.get('costToServe')) || 0;
        let offInvoice = parseFloat(formData.get('offInvoice')) || 0;
        let bedsLeds = parseFloat(formData.get('bedsnleds')) || 0;
        let sugroRetro = parseFloat(formData.get('sugroRetro')) || 0;
        
        // Calculate discount_amount by summing the individual discounts
        let discount_amount = retro + promptPayment + costToServe + offInvoice + bedsLeds + sugroRetro;
    
        // Update the purchaseItem state with the new discount values
        setPurchaseItem((previousItems) => {
            return previousItems.map(item => {
                if (item.id === selectedItemId) {
                    return {
                        ...item, 
                        retro, 
                        promptPayment, 
                        costToServe, 
                        offInvoice, 
                        bedsLeds, 
                        sugroRetro,
                        discount_amount
                    };
                }
                return item;
            });
        });
        
        // Hide the modal by using React state or Bootstrap
        const bsModal = window.bootstrap.Modal.getInstance(discModal.current);
        if (bsModal) {
            bsModal.hide(); // Close modal if it exists
        }
    
        // Update totals after changes
        SetAllTotalStats();
    };
    
    const handleVatChange = (e) => {
        setTotalVat(e.target.value);
    }

    const handleNetAmountChange = (e) => {
        const value = e.target.value;
    
        // Ensure that the value is a valid number or empty string
        if (value === '' || !isNaN(value)) {
            setNetAmt(value);
        } 
    };
    
    const handleDiscChange = (e) => {
        setTotalDisc(e.target.value);
    }

    const handleTotalChange = (e) => {
        setTotalAmt(e.target.value);
    }

    return (
        <div>
        <div className="modal fade" id="view-discount"tabIndex="-1" ref={discModal}>
            <div className="modal-dialog modal-sm">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title">Discount per Case</h6>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="adddiscount-popup">
                            <form id="discountForm" onSubmit={handleSubmitDiscForm}>
                                <table className="table table-bordered dataTable">
                                    <tbody>
                                        <tr>
                                            <td>Retro</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="retro"
                                                    id='retro'
                                                    defaultValue={discountData.retro}
                                                    autoComplete='off'
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Prompt Payment</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="promptPayment"
                                                    id='promptPayment'
                                                    defaultValue={discountData.promptPayment}
                                                    autoComplete='off'
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Cost to Serve</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="costToServe"
                                                    id='costToServe'
                                                    className="form-control"
                                                    defaultValue={discountData.costToServe}
                                                    autoComplete='off'
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Off Invoice</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="offInvoice"
                                                    id='offInvoice'
                                                    className="form-control"
                                                    defaultValue={discountData.offInvoice}
                                                    autoComplete='off'
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Beds & Leds</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="bedsnleds"
                                                    id='bedsnleds'
                                                    className="form-control"
                                                    defaultValue={discountData.bedsLeds}
                                                    autoComplete='off'
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Sugro Retro</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="sugroRetro"
                                                    id='sugroRetro'
                                                    className="form-control"
                                                    defaultValue={discountData.sugroRetro}
                                                    autoComplete='off'
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button className="popup-innerbtn-common" type="submit" style={{ marginTop: "10px" }}>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <Header />
            <div className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12"> <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="/Dashboard">Dashboard</a> &gt; <span className="last-crumb">Supplier Bill</span> </div>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <form id='new_sales_order'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-sm-12">
                                <div className="show-order-area dashboard">
                                    <div style={{ marginBottom: '20px', float: 'left', width: '100%' }}>
                                        <div className="newsalesorderform">
                                            <div className="row mb-3 padd-l-13px">
                                                <div className="col-6 col-lg-6 col-sm-6">
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="desc" className="col-form-label">Select Supplier <span className='red'>*</span> </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <select className="form-control" id="supplier_id" name="supplier_id" value={formData.supplier_id}  onChange={(e) => { handleSupplierChange(e); 
                                                                    handleChange(e); }} >
                                                                <option value="">Select Supplier</option>
                                                                {suppliers.map(supplier => (
                                                                    <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="desc" className="col-form-label">Bill Date </label>
                                                        </div>
                                                        <div className="col-8 col-lg-3 col-sm-3">
                                                            <input type="hidden" name="type" value={'INV'} />
                                                            <input type="hidden" name="user_id" value={user_id} />
                                                            <input type="date" className="form-control" name="bill_date" id="bill_date" value={formData.bill_date} onChange={handleChange} />
                                                        </div>

                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="desc" className="col-form-label">Bill Due Date </label>
                                                        </div>
                                                        <div className="col-8 col-lg-3 col-sm-3">
                                                            <input type="date" className="form-control" name="bill_due_date" value={formData.bill_due_date} id="bill_due_date" onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="desc" className="col-form-label">Bill No <span className='red'>*</span> </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" name="bill_no" id='bill_no' autoComplete='off' value={formData.bill_no}  className="form-control" onChange={(e) =>handleChange(e, )} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-lg-6 col-sm-6">
                                                    <div>
                                                        <div className="row mb-3">
                                                            <div className="col-4 col-lg-3 col-sm-3">
                                                                <label htmlFor="desc" className="col-form-label">Select P.O. <span className='red'>*</span> </label>
                                                            </div>
                                                            <div className="col-8 col-lg-9 col-sm-9">
                                                                <select className="form-control" id="purchase_id" name="purchase_id" onChange={ handlePoChange }>
                                                                    <option value="">Select  P.O.</option>
                                                                    {purchaseOrders.map(purchase => (
                                                                        <option key={purchase.id} value={purchase.id}>{purchase.reference_no}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-4 col-lg-3 col-sm-3">
                                                                <label htmlFor="desc" className="col-form-label">Job Ref <span className='red'>*</span></label>
                                                            </div>
                                                            <div className="col-8 col-lg-9 col-sm-9">
                                                                <input type="text" name="job_ref" id='job_ref' autoComplete='off' className="form-control" value={formData.job_ref} onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h5>Confirm Quantity</h5>
                                        <div className="verical-scrl-wrapper common-table supplierbill-table" id="style-3">
                                            <table className="table table-bordered dataTable" id="dsTable">
                                                <thead>
                                                    <tr>
                                                        <th>Code</th>
                                                        <th>Description</th>
                                                        <th>PK</th>
                                                        <th>Size</th>
                                                        <th>Price</th>
                                                        <th>Cost</th>
                                                        <th>Discount</th>
                                                        <th>Order Qty</th>
                                                        <th>Received Qty (GRN)</th>
                                                        <th>Bill Qty</th>
                                                        <th>Qty</th>
                                                        <th>Pending</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {purchaseItem.map((item, index) => (
                                                        <tr key={item.product_id} >
                                                            <td>{item.code}</td>
                                                            <td>{item.product_desc}</td>
                                                            <td>{item.pack}</td>
                                                            <td className="text-center">{item.size}</td>
                                                            <td style={{ textAlign: 'right' }}>{item.price}</td>
                                                            <td style={{ textAlign: 'right' }}>{item.cost}</td>
                                                            <td  style={{ textAlign: 'right' }}>
                                                                <a href="#" data-bs-toggle="modal" data-bs-target="#view-discount" onClick={() => handleDiscClick(item.id)} >
                                                                    {item.discount_amount}
                                                                </a>
                                                            </td>
                                                            <td className="text-center">{item.quantity}</td>
                                                            <td className="text-center">{item.received_qty}</td>
                                                            <td className="text-center">{item.receivedQtyInLedger}</td>
                                                            <td className="text-center">
                                                                <input type="hidden" name="product_id[]" value={item.product_id} />
                                                                <input type="hidden" name="cost[]" value={item.cost} />
                                                                <input type="hidden" name="orderQty[]" value={item.quantity} />
                                                                <input type="hidden" name="discAmt[]" value={item.discount_amount} />
                                                                <input type="hidden" name="purchase_item_id[]" value={item.purchase_item_id} />
                                                                <input type="text" name='bill_qty[]' className="form-control text-center"  autoComplete='off' value={item.dummy_bill_qty} onChange={(e)=>handleBillQty(e, item.product_id)} />
                                                            </td>
                                                            <td className="text-center">{item.pending_qty}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    <div className="purchaseform">
                                        <div className="row">
                                            <div className="col-6 col-lg-10 col-sm-10">
                                                <div className="row mb-3">
                                                    <input type="hidden" name='totalPendingBalnce' value={totalBalance} />
                                                    <div className="col-6 col-lg-1 col-sm-1">
                                                        <label htmlFor="desc" className="col-form-label">Net Amount </label>
                                                    </div>
                                                    <div className="col-6 col-lg-2 col-sm-2">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="netAmount"
                                                        style={{ textAlign: 'right' }}
                                                        name="netAmount"
                                                        value={netAmt === 0 ? '' : netAmt} // Display empty string when netAmt is 0
                                                        onChange={(e) => {
                                                            const value = e.target.value;

                                                            // Allow empty input or valid number
                                                            if (value === '' || !isNaN(value)) {
                                                                let parsedValue = value === '' ? 0 : parseFloat(value);

                                                                // Round the value to 2 decimal places using Math.round
                                                                let roundedValue = Math.round(parsedValue * 100) / 100;

                                                                // Update the state with the rounded value
                                                                setNetAmt(roundedValue);
                                                            }
                                                        }}
                                                    />

                                                    </div>
                                                    <div className="col-6 col-lg-1 col-sm-1">
                                                        <label htmlFor="desc" className="col-form-label">Discount </label>
                                                    </div>
                                                    <div className="col-6 col-lg-2 col-sm-2">
                                                        <input type="text" className="form-control" id="discount" style={{ textAlign: 'right' }} name='discount'  value={totalDisc} onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (value === '' || !isNaN(value)) {
                                                                setTotalDisc(value === '' ? 0 : parseFloat(value));
                                                            }
                                                        }} />
                                                    </div>
                                                    <div className="col-6 col-lg-1 col-sm-1">
                                                        <label htmlFor="desc" className="col-form-label">VAT </label>
                                                    </div>
                                                    <div className="col-6 col-lg-2 col-sm-2">
                                                        <input type="text" className="form-control" id="vat" style={{ textAlign: 'right' }} name="vat"  value={totalVat} onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (value === '' || !isNaN(value)) {
                                                                setTotalVat(value === '' ? 0 : parseFloat(value));
                                                            } }}  />
                                                    </div>
                                                    <div className="col-6 col-lg-1 col-sm-1">
                                                        <label htmlFor="desc" className="col-form-label">Total </label>
                                                    </div>
                                                    <div className="col-6 col-lg-2 col-sm-2">
                                                        <input type="text" className="form-control" style={{ textAlign: 'right' }} id="total" name="total"  value={totalAmt} onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (value === '' || !isNaN(value)) {
                                                                setTotalAmt(value === '' ? 0 : parseFloat(value));
                                                            } }} />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-6 col-lg-2 col-sm-2">
                                                <div style={{ float: "right", width: "100%" }}>
                                                    <a href="#" className="create-catalogue-btn"><i className='fa fa-plus'></i>&nbsp;Accounting Code</a>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <a href="#" className="common-closebtn">Cancel</a> &nbsp;
                                    <div className="btn-group bouble-btn" role="group" aria-label="Basic example">
                                    <button type="submit" className="saveclose" onClick={(e) => handleSubmit(e, 'close')}>Save & Close</button>
                                    <button type="submit" className="savenew" onClick={(e) => handleSubmit(e, 'new')}>Save & New</button>
                                    </div>
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            <Footer />
        </div>
    );
};

export default SupplierBill; 
