import React, { useState, useEffect } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import AddSupplierModal from "../Master/Add/AddSupplierModal";
import BankSuggestionBox from "../CommonComp/BankSuggestionBox";
import axios from 'axios';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import { Link, useParams, useNavigate } from 'react-router-dom';

const PayBillsEdit = () => {
    const [suppliers, setSuppliers] = useState([]);  
    const [tableRow, setTableRow] = useState([]);
    const [bills, setBills] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState('');
    const [payingAmount, setPayingAmount] = useState(0);
    const [balanceAmount, setBalanceAmount] = useState(0);
    
    const [checkedRows, setCheckedRows] = useState([]);
    const accessToken = localStorage.getItem('accessToken');
    const [bankInfo, setBankInfo] = useState(null); 
    const [supplierInfo, setSupplierInfo] = useState(null); 
    const [ledgerId, setLedgerId] = useState(null); 
    const id = useParams();
    const [formData, setFormData] = useState({
        supplier: '',
        delivery_date: '',
        delivery_id: '',
        details:'',
        transactionRef:''
    });
    const navigateToPurchaseList = useNavigate();

    useEffect(() => {
        if (!accessToken) {
            window.location.href = '/login';
            return;
        }

        setSelectedSupplier(id.id);
        setSupplierInfo(id.id);
        axios.get(`${config.api_url}/ws-get-suppliers`)
            .then(response => {
                console.log('Suppliers Response:', response.data);
              
                if (response.data.supplier && Array.isArray(response.data.supplier)) {
                    setSuppliers(response.data.supplier);
                } else {
                    console.error('Unexpected data format:', response.data);
                    setSuppliers([]);
                }
            })
            .catch(error => {
                console.error('Error fetching suppliers:', error);
            });


            axios.get(`${config.api_url}/ws-edit-po-details/${id.id}`)
            .then(response => {
                var inputData = response.data.purchase[0];
                if (response.data.success) {
                    setTableRow(response.data.purchase);
                    setFormData({
                        supplier: id.id,
                        delivery_date: inputData.date ,
                        delivery_id: inputData.bank_id ,
                        details:inputData.details,
                        transactionRef:inputData.transaction_ref
                    });
                    setLedgerId(response.data.purchase[0].ledger_id);
                } else {
                    console.error('Unexpected data format:', response.data);
                    setTableRow([]); 
                }
            })
            .catch(error => {
                console.error('Error fetching suppliers:', error);
            });
    }, []);
    

    const handleSupplierChange = (e) => {
        const supplierId = e.target.value;
        setSelectedSupplier(supplierId);
        setSupplierInfo(supplierId);
        // Fetch bills for the selected supplier
        if (supplierId) {
            setBills([]); 
            axios.get(`${config.api_url}/ws-supplier-bills/${supplierId}`) // Adjust the endpoint as necessary
                .then(response => {
                    if (Array.isArray(response.data)) {
                        setBills(response.data);
                    } else {
                        console.error('Unexpected data format for bills:', response.data);
                        setBills([]); // Reset if not an array
                    }
                })
                .catch(error => {
                    console.error('Error fetching bills:', error);
                    setBills([]); // Reset on error
                });
        } else {
            setBills([]); // Clear bills if no supplier is selected
        }
    };
    
    const handlePayingAmountChange = (index, value) => {
        const updatedBills = [...bills];
        updatedBills[index].payingAmount = value;
        setBills(updatedBills);
    };

    const fetchData = async () => {};

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleBankChange = (selectedBank) => {
        setBankInfo(selectedBank); 
    };

    // const handlePayChange = (index, value,balance) => {
    //     const newTableRow = [...tableRow];
    //     newTableRow[index].pay = parseFloat(value) || 0;
    //     newTableRow[index].balance = parseFloat(balance) || 0;
    //     setTableRow(newTableRow);

    //     calculateTotals(newTableRow);
    // };


const handlePayChange = (index, value,balance) => {
    const newTableRow = [...tableRow];

    // Update the pay value for the specific row
    newTableRow[index].pay = parseFloat(value) || 0;

    // Always calculate the balance based on the original value minus the current pay
   // newTableRow[index].balance = parseFloat(newTableRow[index].value) - newTableRow[index].pay;

    // Update the table rows state
    setTableRow(newTableRow);

    // Recalculate totals (payingAmount and bills)
    calculateTotals(newTableRow);
};




    const calculateTotals = (rows, selectedRows = checkedRows) => 
    {


        const totalPay = rows
            .filter((_, idx) => selectedRows.includes(idx))
            .reduce((total, row) => total + (row.pay || 0), 0);


        setPayingAmount(totalPay);

        //   const totalbalance = rows
        //     .filter((_, idx) => selectedRows.includes(idx))
        //     .reduce((total, row) => total + (row.balance || 0), 0);
        // setBalanceAmount(totalbalance);


     const totalBill = rows
    .filter((_, idx) => selectedRows.includes(idx)) // Filter rows based on selected rows
    .reduce((sum, row) => {

        
        if (row.type === 'INV') {
            // Add value for rows with type 'INV', ensure it's a valid number
            return sum + (parseFloat(row.value) || 0);
        } else {

           
            // Add 0 for rows with other types
            return sum - (parseFloat(0) || 0);
        }
    }, 0); // Initial sum is 0

     setBills(totalBill); // Set the total bill value

    };

    const handleCheckboxChange = (index) => {
        setCheckedRows((prevCheckedRows) => {
            const newCheckedRows = prevCheckedRows.includes(index)
                ? prevCheckedRows.filter((rowIndex) => rowIndex !== index) 
                : [...prevCheckedRows, index]; 

            calculateTotals(tableRow, newCheckedRows);
            return newCheckedRows;
        });
    };

    const handleSubmit = async (event , action) =>
        {    
           event.preventDefault();
           const formData1 = new FormData();
           const supplier = supplierInfo ? supplierInfo : '';
           const bank = bankInfo ? bankInfo.value : '';
           formData1.append('ledgerId', ledgerId);
           formData1.append('supplier_id', supplier);
           formData1.append('date', formData.delivery_date);
           formData1.append('bank_id', bank);
           formData1.append('details', formData.details);
           formData1.append('transaction_ref', formData.transactionRef);
           formData1.append('paying_amount', payingAmount);
           formData1.append('balance', (bills-payingAmount));
           // Gather data for the checked rows
           const selectedRowsData = tableRow
               .filter((_, idx) => checkedRows.includes(idx))
               .map((row) => ({
                   purchase_id: row.purchase_id,
                   pay: row.pay || 0,
                   bill_balance: row.balance - parseFloat(row.pay || 0),
                   purchase_ledger_id:row.ledger_id
               }));
   
            formData1.append('selected_rows', JSON.stringify(selectedRowsData));


           // Send the form data to the backend
           const apiUrl = `${config.api_url}/ws-update-received-payment`;
   
           try {
               const response = await fetch(apiUrl, {
                   method: 'POST',
                   headers: {
                       authorization: `Bearer ${accessToken}`,
                   },
                   body: formData1,
               });
   
               if (!response.ok) {
                   throw new Error('Failed to submit payment data');
               }
   
               const data = await response.json();


               if(action === 'close')
                {
                    navigateToPurchaseList('/PurchaseLedger');
                }
                else if(action === 'new'){
                    setTableRow([]);
                    setBankInfo(null);
                    setFormData({
                        supplier: '',
                        delivery_date: '',
                        delivery_id: '',
                        details: '',
                        transactionRef: ''
                    });
                    // setDetails('');
                    // setTransactionRef('');
                    // setCustomerInfo(null);
                    // setDeliveryDate('');
                    // setPayingAmount(0);
                    // setBills(0);
                    // setBankInfo('');
                }
               console.log('Submission successful:', data);
           } catch (error) {
               console.error('Error submitting payment data:', error);
           }
       };

    return (
        <div>
            <Header />
            <AddSupplierModal fetchData={fetchData} />
            <div className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                            <a href="#"><i className="fa fa-home"></i></a> &gt;
                            <a href="/Dashboard">Dashboard</a> &gt;
                            <span className="last-crumb">Pay Bills Edit</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <form id='new_sales_order'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-lg-12 col-sm-12">
                                <div className="show-order-area dashboard">
                                    <div style={{ marginBottom: '20px', float: 'left' }}>
                                        <div className="newsalesorderform">
                                            <div style={{ float: "right", width: "100%" }}>
                                                <a href="#" className="create-catalogue-btn" data-bs-toggle="modal"
                                                data-bs-target="#add-supplier" ><i className='fa fa-plus'></i>&nbsp;New Supplier
                                                </a>
                                            </div>
                                            <hr />
                                            <div className="row mb-3 padd-l-13px">
                                                <div className="col-6 col-lg-6 col-sm-6">
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="supplier" className="col-form-label">Select Supplier <span className='red'>*</span> </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                     <select 
    className="form-control" 
    id="supplier" 
    name="supplier" 
    value={formData.supplier} 
    onChange={(e) => {
        handleSupplierChange(e);
        handleChange(e);
    }} 
    disabled
>
    <option value="">Select Supplier</option>
    {suppliers.map(supplier => (
        <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
    ))}
</select>

                                                    </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="delivery_date" className="col-form-label">Date </label>
                                                        </div>
                                                        <div className="col-8 col-lg-3 col-sm-3">
                                                            <input type="date" className="form-control" name="delivery_date" id="delivery_date" value={formData.delivery_date} onChange={handleChange} />
                                                        </div>
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="bank" className="col-form-label">Bank </label>
                                                        </div>
                                                        <div className="col-8 col-lg-3 col-sm-3">
                                                            <BankSuggestionBox onBankChange={handleBankChange} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="details" className="col-form-label">Details </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" name='details' value={formData.details} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-lg-6 col-sm-6">
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="transaction_ref" className="col-form-label">Transaction Ref </label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" name='transactionRef' value={formData.transactionRef} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="paying_amount" className="col-form-label">Paying Amount</label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" name='payingAmt' disabled value={payingAmount} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-4 col-lg-3 col-sm-3">
                                                            <label htmlFor="balance" className="col-form-label">Balance</label>
                                                        </div>
                                                        <div className="col-8 col-lg-9 col-sm-9">
                                                            <input type="text" className="form-control" name='balance' disabled value={bills-payingAmount} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="verical-scrl-wrapper common-table paybill-table" id="style-3">
                                            <table className="table table-bordered dataTable" id="dsTable">
                                                <thead>
                                                    <tr>
                                                        <th>Select</th>
                                                        <th>ID</th>
                                                        <th>Raised On</th>
                                                        <th>Type</th>
                                                        <th>Bill No</th>
                                                        <th>Job Ref</th>
                                                        <th>P.O ID</th>
                                                        <th>Supplier (ID)</th>
                                                        <th>Value</th>
                                                        <th>Balance</th>
                                                        <th>Pay</th>
                                                        <th>Due Date</th>
                                                        <th>Created By</th>
                                                    </tr>
                                                </thead>
                                             <tbody>
                                                {tableRow.map((bill, index) => (
                                                    <tr key={bill.id}>
                                                        <td style={{ width: '5%' }}>
                                                            <label className="checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={() => handleCheckboxChange(index)}
                                                                />
                                                                <span className="default"></span>
                                                            </label>
                                                        </td>
                                                        <td style={{ width: '5%' }}>
                                                            <a href="#">{bill.id}</a>
                                                        </td>
                                                        <td style={{ width: '8%' }}>
                                                            {bill.created_date}
                                                        </td>
                                                        <td style={{ width: '5%' }}>
                                                            {bill.type}
                                                        </td>
                                                        <td style={{ width: '10%' }}>
                                                            {bill.bill_no}
                                                        </td>
                                                        <td style={{ width: '10%' }}>
                                                            {bill.job_ref}
                                                        </td>
                                                        <td style={{ width: '8%' }}>
                                                            <a href="#">{bill.reference_no}</a>
                                                        </td>
                                                        <td style={{ width: '15%' }}>
                                                            <a href="#">{bill.name}</a>
                                                        </td>
                                                        <td style={{ textAlign: 'right', width: '5%' }}>
                                                            {bill.value}
                                                        </td>
                                                        <td style={{ textAlign: 'right', width: '5%' }}>
                                                        
                                                          {  bill.balance-parseFloat(bill.pay || 0) }                                                 

                                                        </td>
                                                        <td style={{ textAlign: 'right',width: '8%' }}>
                                                            <input
                                                                type="number"
                                                                name="pay[]"
                                                                className="form-control qtyinputbox no-arrows"
                                                                defaultValue={bill.paying_amount || ''}
                                                                style={{ textAlign: 'right' }}
                                                                onChange={(e) => handlePayChange(index, e.target.value,bill.balance)}
                                                                placeholder="Enter Amount"
                                                            />
                                                        </td>
                                                        <td style={{ width: '8%' }}>
                                                            {bill.due_date}
                                                        </td>
                                                        <td style={{ width: '10%' }}>
                                                            {bill.created_by}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            </table>
                                        </div>
                                        <hr />
                                        <a href="#" className="common-closebtn">Cancel</a> &nbsp;
                                        <div className="btn-group bouble-btn" role="group" aria-label="Basic example">
                                            <button onClick={(e)=>handleSubmit(e, 'close')} type="button" className="saveclose">Save & Close</button>
                                            <button onClick={(e)=>handleSubmit(e, 'new')} type="button" className="savenew">Save & New</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    );
};

export default PayBillsEdit;
