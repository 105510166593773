import React, { useState, useEffect } from 'react';
import Header from '../Common_Files/Header';
import Footer from '../Common_Files/Footer';
import config from '../Common_Files/Config';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import $ from 'jquery';

const SupplierCredit = () => {
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState('');
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [purchaseItem, setPurchaseItem] = useState({});
    const [poDetails, setPoDetails] = useState({
        netAmount: '',
        vatAmount: '',
        totalAmount: ''
    });
    const [formData, setFormData] = useState({
        bill_date: '',
        bill_due_date: '',
        bill_no: '',
        job_ref: '',
        supplier_id:'',
        purchase_id:'',
        vat:''
    });
    const accessToken = localStorage.getItem('accessToken');
    const user_id = localStorage.getItem('user_id');
    const navigateToPurchaseLedger = useNavigate();

    useEffect(() => {
        if (!accessToken) {
            window.location.href = '/login';
            return;
        }
        // Fetch suppliers when the component mounts
        axios.get(`${config.api_url}/ws-purchase-order-supplier`)
        .then(response => {
                setSuppliers(response.data.supplier); // Adjust according to your API response structure
            })
        .catch(error => console.error(error));
    }, []);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Update netAmount or vatAmount dynamically
        if (name === 'net_amount') {
            const newNetAmount = parseFloat(value) || 0.00;
            const newTotalAmount = newNetAmount + parseFloat(poDetails.vatAmount || 0.00);
            setPoDetails({
                ...poDetails,
                netAmount: newNetAmount.toFixed(2),
                totalAmount: newTotalAmount.toFixed(2)
            });
        }

        if (name === 'vat_amount') {
            const newVatAmount = parseFloat(value) || 0.00;
            const newTotalAmount = newVatAmount + parseFloat(poDetails.netAmount || 0.00);
            setPoDetails({
                ...poDetails,
                vatAmount: newVatAmount.toFixed(2),
                totalAmount: newTotalAmount.toFixed(2)
            });
        }
    };


    const handleSupplierChange = (e) => {
        const supplierId = e.target.value;
        setSelectedSupplier(supplierId);

        // Fetch Purchase Orders for the selected supplier
        if (supplierId) {
            axios.get(`${config.api_url}/ws-credit-supplier-po/${supplierId}`)
            .then(response => {
                    setPurchaseOrders(response.data); // Assume the response is an array of POs
                })
            .catch(error => console.error(error));
        } else {
            setPurchaseOrders([]);
        }
    };

    const handlePoChange = (e) => {
        const poId = e.target.value;
        if (poId) {
            axios.get(`${config.api_url}/ws-get-po-details-for-credit/${poId}`)
            .then(response => {
                const purchase = response.data.purchase;
                setPurchaseItem(purchase); 
                setPoDetails({
                    netAmount: (parseFloat(purchase[0].net_std_goods || 0.00) + parseFloat(purchase[0].net_zero_goods || 0.00)).toFixed(2),
                    vatAmount: parseFloat(purchase[0].net_vat_amount || 0.00).toFixed(2),
                    totalAmount: parseFloat(purchase[0].net_grand_total || 0.00).toFixed(2),
                });

                setFormData({
                    'job_ref': purchase[0].job_ref
                });

            })
            .catch(error => console.error(error));
        } else {
            setPurchaseItem({});
            setPoDetails({
                netAmount: '',
                vatAmount: '',
                totalAmount: ''
            });
        }
    };

    const handleSubmit = (e , action) => {
      e.preventDefault();
      const formElement = document.getElementById("new_sales_order");
      const formData = new FormData(formElement);

      let purchase_id = $('#po').val();
      let supplier_id = $('#supplier').val();
      let bill_no = $('#bill_no').val();
      
        let message = '';
        // if (!purchase_id) {
        //     message = 'Please select the Purchase Order.';
        // }
        
        if (!supplier_id) {
            message = 'Please select the Supplier.';
        }
        
        if (!bill_no) {
            message = 'Please enter the Bill Number.';
        }

        if(message !== '')
        {
            Swal.fire({
                title: "Error!",
                text: message,
                icon: "error",
                timer: 1500, 
                showConfirmButton: false,
              })
              return;
        }
        
        formData.append('type', 'Credit');
        formData.append('user_id', user_id);
        
        axios.post(`${config.api_url}/submit-supplier-credit`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => {
            Swal.fire({
                title: "Success",
                text: "Supplier bill submitted successfully",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
            });

            if (action === 'close'){
                navigateToPurchaseLedger('/PurchaseLedger');
            } else if (action === 'new') {
                setFormData({
                    bill_date: '',
                    bill_due_date: '',
                    bill_no: '',
                    job_ref: '',
                    supplier_id:'',
                    purchase_id:'',
                    vat:''

                });

                setPurchaseItem([]);
                setSelectedSupplier(null);
                setPurchaseOrders([]);
                setPoDetails({
                    netAmount: '',
                    vatAmount: '',
                    totalAmount: ''
                });
            }
        })
        .catch(error => {
            console.error('There was an error submitting the form:', error);
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div>
        <Header />
        <div className="breadcrumbs">
        <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-lg-12 col-sm-12">
        <a href="#"><i className="fa fa-home"></i></a> &gt;
        <a href="/Dashboard">Dashboard</a> &gt;
        <span className="last-crumb">Supplier Credit</span>
        </div>
        </div>
        </div>
        </div>
        <div className="main-container">
        <form id='new_sales_order'>
        <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-lg-12 col-sm-12">
        <div className="show-order-area dashboard">
        <div style={{ marginBottom: '20px', float: 'left', width: '100%' }}>
        <div className="newsalesorderform">
        <div className="row mb-3 padd-l-13px">
        <div className="col-6 col-lg-6 col-sm-6">
        <div className="row mb-3">
        <div className="col-4 col-lg-3 col-sm-3">
        <label htmlFor="supplier" className="col-form-label">Select Supplier <span className='red'>*</span> </label>
        </div>
        <div className="col-8 col-lg-9 col-sm-9">
        <select className="form-control" name='supplier_id' id="supplier" value={formData.supplier_id} onChange={(e) => {
            handleSupplierChange(e);
            handleChange(e);
        }}>
        <option value="">Select Supplier</option>
        {suppliers.map(supplier => (
            <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
            ))}
            </select>
            </div>
            </div>

            <div className="row mb-3">
            <div className="col-4 col-lg-3 col-sm-3">
            <label htmlFor="bill_date" className="col-form-label">Bill Date </label>
            </div>
            <div className="col-8 col-lg-3 col-sm-3">
            <input type="date" className="form-control" name="bill_date" id="bill_date" onChange={handleChange} value={formData.bill_date} />
            </div>

            <div className="col-4 col-lg-3 col-sm-3">
            <label htmlFor="bill_due_date" className="col-form-label">Bill Due Date </label>
            </div>
            <div className="col-8 col-lg-3 col-sm-3">
            <input type="date" className="form-control" name="bill_due_date" id="bill_due_date" onChange={handleChange} value={formData.bill_due_date} />
            </div>
            </div>

            <div className="row mb-3">
            <div className="col-4 col-lg-3 col-sm-3">
            <label htmlFor="bill_no" className="col-form-label">Bill No <span className='red'>*</span> </label>
            </div>
            <div className="col-8 col-lg-9 col-sm-9">
            <input type="text" className="form-control" name='bill_no' id="bill_no" autoComplete='off' onChange={handleChange} value={formData.bill_no}  />
            </div>
            </div>
            </div>

            <div className="col-6 col-lg-6 col-sm-6">
            <div className="row mb-3">
            <div className="col-4 col-lg-3 col-sm-3">
            <label htmlFor="po" className="col-form-label">Select P.O.</label>
            </div>
            <div className="col-8 col-lg-9 col-sm-9">
            <select className="form-control" id="po" name="purchase_id" value={formData.purchase_id} onChange={(e) => {
                handleChange(e);
                handlePoChange(e);
            }}>
            <option value="">Select Purchase Order</option>
            {purchaseOrders.map(po => (
                <option key={po.id} value={po.id}>{po.reference_no}</option>
                ))}
                </select>
                </div>
                </div>

                <div className="row mb-3">
                <div className="col-4 col-lg-3 col-sm-3">
                <label htmlFor="job_ref" className="col-form-label">Job Ref <span className='red'>*</span></label>
                </div>
                <div className="col-8 col-lg-9 col-sm-9">
                <input type="text" name="job_ref" className="form-control" id="job_ref"   autoComplete='off' />
                </div>
                </div>

                <div className="row mb-3">
                <div className="col-4 col-lg-3 col-sm-3">
                <label htmlFor="vat" className="col-form-label">VAT %</label>
                </div>
                <div className="col-8 col-lg-9 col-sm-9">
                <select name="vat" className="form-select orgselect" value={formData.vat} id="vat" onChange={(e)=>{
                    handleChange(e);
                    handlePoChange(e);
                }} >
                <option>Select VAT</option>
                <option>20 %</option>
                <option>0 %</option>
                </select>
                </div>
                </div>
                </div>
                </div>

                <div className="purchaseform">
                <div className="row">
                <div className="col-12 col-lg-12 col-sm-12">
                <div className="row mb-3">
                <div className="col-6 col-lg-1 col-sm-1">
                <label htmlFor="net_amount" className="col-form-label">Net Amount </label>
                </div>
                <div className="col-6 col-lg-3 col-sm-3">
                <input type="text" className="form-control" name='net_amount' id="net_amount" onChange={handleInputChange} defaultValue={poDetails.netAmount}  />
                </div>

                <div className="col-6 col-lg-1 col-sm-1">
                <label htmlFor="vat_amount" className="col-form-label">VAT </label>
                </div>
                <div className="col-6 col-lg-3 col-sm-3">
                <input type="text" className="form-control" name='vat_amount' id="vat_amount" onChange={handleInputChange} defaultValue={poDetails.vatAmount}  />
                </div>

                <div className="col-6 col-lg-1 col-sm-1">
                <label htmlFor="total_amount" className="col-form-label">Total </label>
                </div>
                <div className="col-6 col-lg-3 col-sm-3">
                <input type="text" className="form-control" name='total_amount' id="total_amount" defaultValue={poDetails.totalAmount}  />
                </div>
                </div>
                </div>
                </div>
                </div>
                <a href="#" className="common-closebtn">Cancel</a> &nbsp;
                <div className="btn-group bouble-btn" role="group" aria-label="Basic example">
                <button type="submit" className="saveclose" onClick={(e) => handleSubmit(e, 'close')}>Save & Close</button>
                <button type="submit" className="savenew" onClick={(e) => handleSubmit(e, 'new')}>Save & New</button>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                </form>
                </div>
                <Footer />
                </div>
                );
            };

            export default SupplierCredit;
